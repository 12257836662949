import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CryptoJS from 'crypto-js';
import {
  getLocalStorageJSON,
  setLocalStorageJSON,
} from "../../../Utils/localStorageFunc";
import {
  FlightsResultPageErrorMain,
  FlightsResultPageErrorMainDescription,
  SearchBarErrorMessages,
  TravellerClasses,
  Travellers,
  TravellersFareMessage,
  fareOptions,
} from "../../../Constants/ConstantsFlights";
import {
  fetchPopularAirports,
  fetchTheAirline,
  fetchTheFlights,
  HoldTheFlight,
} from "../../Apis/Flight-Apis";
import {
  formatDateRecentSearch,
  formatDateToSeatchParams,
} from "../../../Utils/Date_Time_Calendar_Functions";
import {
  filterFlights,
  gerateFiltersParams,
  gerateFiltersParamsCombined,
  getAllAirlinesWithindex1,
  getTotalMinutes,
  sortByPrice,
} from "../../../Utils/Flight_results_func";

let defaultsearchParams = [
  {
    OriginCity: "",
    DestinationCity: "",
    OriginCode: "",
    DestinyCode: "",
    Origin: "", // airport name
    Destiny: "", // airport name
    OriginCountryCode: "",
    DestinationCountryCode: "",
    OriginCountry: "",
    DestinationCountry: "",

    OriginDate: null, // convert date to this format,
    ReturnDate: null, // convert date to this format,
  },
  {
    OriginCity: "",
    DestinationCity: "",
    OriginCode: "",
    DestinyCode: "",
    Origin: "",
    Destiny: "",
    OriginCountryCode: "",
    DestinationCountryCode: "",
    OriginCountry: "",
    DestinationCountry: "",
    OriginDate: null, // convert date to this format,
    ReturnDate: null, // convert date to this format,
  },
  {
    OriginCity: "",
    DestinationCity: "",
    OriginCode: "",
    DestinyCode: "",
    Origin: "",
    Destiny: "",
    OriginCountryCode: "",
    DestinationCountryCode: "",
    OriginCountry: "",
    DestinationCountry: "",
    OriginDate: null, // convert date to this format,
    ReturnDate: null, // convert date to this format,
  },
  {
    OriginCity: "",
    DestinationCity: "",
    OriginCode: "",
    DestinyCode: "",
    Origin: "",
    Destiny: "",
    OriginCountryCode: "",
    DestinationCountryCode: "",
    OriginCountry: "",
    DestinationCountry: "",
    OriginDate: null, // convert date to this format,
    ReturnDate: null, // convert date to this format,
  },
];

let defaultsearchParamsTravellers = {
  Adults: 1,
  Childs: 0,
  Infrants: 0,
  TravellersAndClassesErrorOut: false,
  TravellersAndClassesErrorOutMessage: "",
  TravellersAndClassesErrorIn: false,
  TravellersAndClassesErrorInMessage: "",
};

let defaultsearchParamsFare = {
  class: "Economy",
  type: "Regular",
};

const initialState = {
  isOffline: false,

  // Search bar Inital States
  searchBarMode: 1,

  searchParams: defaultsearchParams,

  searchParamsTravellers: defaultsearchParamsTravellers,
  searchParamsFare: defaultsearchParamsFare,

  popularCitiesLoading: true,
  popularAirports: [],

  disableSearch: true,

  AirportsToError1: [false, ""],
  AirportsToError2: [false, ""],
  AirportsToError3: [false, ""],
  AirportsToError4: [false, ""],

  AirportsFromError1: [false, ""],
  AirportsFromError2: [false, ""],
  AirportsFromError3: [false, ""],
  AirportsFromError4: [false, ""],

  DateError1: [false, ""],
  DateError11: [false, ""],
  DateError2: [false, ""],
  DateError3: [false, ""],
  DateError4: [false, ""],

  multiRowNumber: 1,

  searchClicked: false,

  // Result Page Initial States
  PageTheme: 1,
  ErrorOnresultPage: false,
  ErrorOnresultPageMessageType: "",
  ErrorOnresultPageMessageDetailed: "",

  SearchParameters: [],
  StartTheSearch: false,
  SearchResultsStatus: "Loading",
  // SearchResultsStatus: "idle",
  SearchResults: null,

  SearchResultType: 1,

  searchkey: "",

  //  1 for combined view  ,  2 for split view
  ResultView: 1,

  filtersLoading: true,
  filtersGenerated: [],
  FilteredData: [],
  FullDataInitial: [],
  FiltersApplied: [[], [], [], [], [], [], [], []],

  barModeSelected: 0,
  barModeData: [[], [], [], []],

  openMainPopup: false,
  dataMainPopup: false,
  ResultTypeMainPopup: false,
  FFSelected: [0, 0, 0, 0],

  bookLoading : false,
  bookLoadingError : false,

  itrCode : ""
};

export const SearchTheFlights = createAsyncThunk(
  "HomePageSearchBarFlight/SearchTheFlights",
  async (params, { rejectWithValue }) => {
    try {
      const response = await fetchTheFlights(params);
      console.log("response got is  : ", response);
      return response?.data === undefined ? null : response?.data;
    } catch (error) {
      console.log("reached here for erorro the erorr is  : ", error);
      throw rejectWithValue({
        dd:
          error?.response?.data === undefined
            ? FlightsResultPageErrorMain.SRD
            : error?.response?.data,
        errorcode:
          error?.response?.data === undefined
            ? FlightsResultPageErrorMain.SRD
            : error?.code,
      });

    }
  }
);

export const setFiltersApplied = (payload) => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ResultPageFlightsFlight } = statesData;

    let state = ResultPageFlightsFlight;
    let AppliedFilters = [...state.FiltersApplied.map((filter) => [...filter])];

    const { index, key, value, mode } = payload;

    let DatatoFilter = state.FullDataInitial;
    let FilteredData = state.FilteredData;

    if (
      mode === "Price" ||
      mode === "Duration" ||
      mode === "DurationLayover" ||
      mode === "SortBy" ||
      mode === "SearchBy"
    ) {
      // Find the index of the element with the matching key

      const existingIndex = AppliedFilters[index].findIndex(
        (item) => item.key === key
      );

      if (existingIndex !== -1) {
        // Remove the element with the matching key
        AppliedFilters[index] = [
          ...AppliedFilters[index].slice(0, existingIndex),
          ...AppliedFilters[index].slice(existingIndex + 1),
        ];
      }

      // Add the new element
      AppliedFilters[index] = [
        ...AppliedFilters[index],
        { Mode: mode, key: key, Value: value, Index: index },
      ];

      dispatch(setFiltersAppliedManual(AppliedFilters));

      if (state.SearchResultType === 1 || state.SearchResultType === 2) {
        let filteredData = filterFlights(
          DatatoFilter,
          AppliedFilters,
          index,
          state.SearchResultType
        );
        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 3) {
        let filteredData = {
          Going:
            index === 0
              ? filterFlights(
                  DatatoFilter.Going,
                  AppliedFilters,
                  index,
                  state.SearchResultType
                )
              : FilteredData.Going,
          Returning:
            index === 1
              ? filterFlights(
                  DatatoFilter.Returning,
                  AppliedFilters,
                  index,
                  state.SearchResultType
                )
              : FilteredData.Returning,
        };

        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 4) {
        let filteredData = [];

        if (index < 2) {
          filteredData = {
            ...FilteredData,
            Combined: filterFlights(
              DatatoFilter.Combined,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 2) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Going: filterFlights(
                DatatoFilter.Split.Going,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        } else if (index === 3) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Returning: filterFlights(
                DatatoFilter.Split.Returning,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        }

        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 5) {
        let filteredData = [];

        if (index === 0) {
          filteredData = {
            ...FilteredData,
            Row1: filterFlights(
              DatatoFilter.Row1,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 1) {
          filteredData = {
            ...FilteredData,
            Row2: filterFlights(
              DatatoFilter.Row2,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        }

        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 6) {
        let filteredData = [];

        if (index < 2) {
          filteredData = {
            ...FilteredData,
            Combined: filterFlights(
              DatatoFilter.Combined,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 2) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row1: filterFlights(
                DatatoFilter.Split.Row1,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        } else if (index === 3) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row2: filterFlights(
                DatatoFilter.Split.Row2,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        }

        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 7) {
        let filteredData = [];

        if (index === 0) {
          filteredData = {
            ...FilteredData,
            Row1: filterFlights(
              DatatoFilter.Row1,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 1) {
          filteredData = {
            ...FilteredData,
            Row2: filterFlights(
              DatatoFilter.Row2,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 2) {
          filteredData = {
            ...FilteredData,
            Row3: filterFlights(
              DatatoFilter.Row3,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        }

        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 8) {
        let filteredData = [];

        if (index < 3) {
          filteredData = {
            ...FilteredData,
            Combined: filterFlights(
              DatatoFilter.Combined,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 3) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row1: filterFlights(
                DatatoFilter.Split.Row1,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        } else if (index === 4) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row2: filterFlights(
                DatatoFilter.Split.Row2,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        } else if (index === 5) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row3: filterFlights(
                DatatoFilter.Split.Row3,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        }

        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 9) {
        let filteredData = [];

        if (index === 0) {
          filteredData = {
            ...FilteredData,
            Row1: filterFlights(
              DatatoFilter.Row1,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 1) {
          filteredData = {
            ...FilteredData,
            Row2: filterFlights(
              DatatoFilter.Row2,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 2) {
          filteredData = {
            ...FilteredData,
            Row3: filterFlights(
              DatatoFilter.Row3,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 3) {
          filteredData = {
            ...FilteredData,
            Row4: filterFlights(
              DatatoFilter.Row4,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        }

        dispatch(setFilteredData(filteredData));
      } else if (state.SearchResultType === 10) {
        let filteredData = [];

        if (index < 4) {
          filteredData = {
            ...FilteredData,
            Combined: filterFlights(
              DatatoFilter.Combined,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          };
        } else if (index === 4) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row1: filterFlights(
                DatatoFilter.Split.Row1,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        } else if (index === 5) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row2: filterFlights(
                DatatoFilter.Split.Row2,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        } else if (index === 6) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row3: filterFlights(
                DatatoFilter.Split.Row3,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        } else if (index === 7) {
          filteredData = {
            ...FilteredData,
            Split: {
              ...FilteredData.Split,
              Row4: filterFlights(
                DatatoFilter.Split.Row4,
                AppliedFilters,
                index,
                state.SearchResultType
              ),
            },
          };
        }

        dispatch(setFilteredData(filteredData));
      }
      return;
    }

    // Find the filter index that matches the mode and key
    const existingFilterIndex = AppliedFilters[index].findIndex(
      (filter) => filter.Mode === mode && filter.key === key
    );

    // If the filter exists, remove it
    if (existingFilterIndex !== -1) {
      AppliedFilters[index] = [
        ...AppliedFilters[index].slice(0, existingFilterIndex),
        ...AppliedFilters[index].slice(existingFilterIndex + 1),
      ];
    } else {
      AppliedFilters[index] = [
        ...AppliedFilters[index],
        { Mode: mode, key: key, Value: value, Index: index },
      ];
    }

    // Set the Data Acc To The Filters Applied
    if (state.SearchResultType === 1 || state.SearchResultType === 2) {
      let filteredData = filterFlights(
        DatatoFilter,
        AppliedFilters,
        index,
        state.SearchResultType
      );

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 3) {
      let filteredData = {
        Going:
          index === 0
            ? filterFlights(
                DatatoFilter.Going,
                AppliedFilters,
                index,
                state.SearchResultType
              )
            : FilteredData.Going,
        Returning:
          index === 1
            ? filterFlights(
                DatatoFilter.Returning,
                AppliedFilters,
                index,
                state.SearchResultType
              )
            : FilteredData.Returning,
      };

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 4) {
      let filteredData = {};

      if (index < 2) {
        filteredData = {
          ...FilteredData,
          Combined: filterFlights(
            DatatoFilter.Combined,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 2) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Going: filterFlights(
              DatatoFilter.Split.Going,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      } else if (index === 3) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Returning: filterFlights(
              DatatoFilter.Split.Returning,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      }

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 5) {
      let filteredData = [];

      if (index === 0) {
        filteredData = {
          ...FilteredData,
          Row1: filterFlights(
            DatatoFilter.Row1,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 1) {
        filteredData = {
          ...FilteredData,
          Row2: filterFlights(
            DatatoFilter.Row2,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      }

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 6) {
      let filteredData = [];

      if (index < 2) {
        filteredData = {
          ...FilteredData,
          Combined: filterFlights(
            DatatoFilter.Combined,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 2) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row1: filterFlights(
              DatatoFilter.Split.Row1,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      } else if (index === 3) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row2: filterFlights(
              DatatoFilter.Split.Row2,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      }

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 7) {
      let filteredData = [];

      if (index === 0) {
        filteredData = {
          ...FilteredData,
          Row1: filterFlights(
            DatatoFilter.Row1,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 1) {
        filteredData = {
          ...FilteredData,
          Row2: filterFlights(
            DatatoFilter.Row2,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 2) {
        filteredData = {
          ...FilteredData,
          Row3: filterFlights(
            DatatoFilter.Row3,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      }

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 8) {
      let filteredData = [];

      if (index < 3) {
        filteredData = {
          ...FilteredData,
          Combined: filterFlights(
            DatatoFilter.Combined,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 3) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row1: filterFlights(
              DatatoFilter.Split.Row1,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      } else if (index === 4) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row2: filterFlights(
              DatatoFilter.Split.Row2,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      } else if (index === 5) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row3: filterFlights(
              DatatoFilter.Split.Row3,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      }

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 9) {
      let filteredData = [];

      if (index === 0) {
        filteredData = {
          ...FilteredData,
          Row1: filterFlights(
            DatatoFilter.Row1,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 1) {
        filteredData = {
          ...FilteredData,
          Row2: filterFlights(
            DatatoFilter.Row2,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 2) {
        filteredData = {
          ...FilteredData,
          Row3: filterFlights(
            DatatoFilter.Row3,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 3) {
        filteredData = {
          ...FilteredData,
          Row4: filterFlights(
            DatatoFilter.Row4,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      }

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    } else if (state.SearchResultType === 10) {
      let filteredData = [];

      if (index < 4) {
        filteredData = {
          ...FilteredData,
          Combined: filterFlights(
            DatatoFilter.Combined,
            AppliedFilters,
            index,
            state.SearchResultType
          ),
        };
      } else if (index === 4) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row1: filterFlights(
              DatatoFilter.Split.Row1,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      } else if (index === 5) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row2: filterFlights(
              DatatoFilter.Split.Row2,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      } else if (index === 6) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row3: filterFlights(
              DatatoFilter.Split.Row3,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      } else if (index === 7) {
        filteredData = {
          ...FilteredData,
          Split: {
            ...FilteredData.Split,
            Row4: filterFlights(
              DatatoFilter.Split.Row4,
              AppliedFilters,
              index,
              state.SearchResultType
            ),
          },
        };
      }

      dispatch(setFiltersAppliedManual(AppliedFilters));
      dispatch(setFilteredData(filteredData));
    }
  };
};

export const generateFiltersAsync = () => {
  return async (dispatch, getState) => {
    const statesData = getState();
    console.log("statesData is : ", statesData);
    let { ResultPageFlightsFlight } = statesData;

    let state = ResultPageFlightsFlight;
    console.log("the states are  : ", state);

    if (state.SearchResultType === 1 || state.SearchResultType === 2) {
      let AllFilters = [
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: [],
        },
      ];

      let Journey = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Fulldata = state.SearchResults.Data;
      let Logos = state.SearchResults.AirlinesLogos;

      await gerateFiltersParams(AllFilters, Fulldata, Logos, 0, Journey);

      dispatch(setFiltersFinalUpdate(AllFilters));
    } else if (state.SearchResultType === 3 || state.SearchResultType === 5) {
      let AllFilters = [
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
      ];

      let Logos = state.SearchResults.AirlinesLogos;

      let Journey1 = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Row1 =
        state.SearchResultType === 3
          ? state.SearchResults.Data.Going
          : state.SearchResults.Data.Row1;

      await gerateFiltersParams(AllFilters, Row1, Logos, 0, Journey1);

      let Journey2 = [
        state.SearchResultType === 3
          ? state.SearchParameters.Segments[0].Destination
          : state.SearchParameters.Segments[1].Origin,
        state.SearchResultType === 3
          ? state.SearchParameters.Segments[0].Origin
          : state.SearchParameters.Segments[1].Destination,
      ];

      let Row2 =
        state.SearchResultType === 3
          ? state.SearchResults.Data.Returning
          : state.SearchResults.Data.Row2;

      await gerateFiltersParams(AllFilters, Row2, Logos, 1, Journey2);

      dispatch(setFiltersFinalUpdate(AllFilters));
    } else if (state.SearchResultType === 7) {
      let AllFilters = [
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
      ];

      let Logos = state.SearchResults.AirlinesLogos;

      let Journey1 = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Row1 = state.SearchResults.Data.Row1;

      gerateFiltersParams(AllFilters, Row1, Logos, 0, Journey1);

      let Journey2 = [
        state.SearchParameters.Segments[1].Origin,
        state.SearchParameters.Segments[1].Destination,
      ];

      let Row2 = state.SearchResults.Data.Row2;

      gerateFiltersParams(AllFilters, Row2, Logos, 1, Journey2);

      let Journey3 = [
        state.SearchParameters.Segments[2].Origin,
        state.SearchParameters.Segments[2].Destination,
      ];

      let Row3 = state.SearchResults.Data.Row3;

      gerateFiltersParams(AllFilters, Row3, Logos, 2, Journey3);

      dispatch(setFiltersFinalUpdate(AllFilters));
    } else if (state.SearchResultType === 9) {
      let AllFilters = [
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
      ];

      let Logos = state.SearchResults.AirlinesLogos;

      let Journey1 = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Row1 = state.SearchResults.Data.Row1;

      await gerateFiltersParams(AllFilters, Row1, Logos, 0, Journey1);

      let Journey2 = [
        state.SearchParameters.Segments[1].Origin,
        state.SearchParameters.Segments[1].Destination,
      ];

      let Row2 = state.SearchResults.Data.Row2;

      await gerateFiltersParams(AllFilters, Row2, Logos, 1, Journey2);

      let Journey3 = [
        state.SearchParameters.Segments[2].Origin,
        state.SearchParameters.Segments[2].Destination,
      ];

      let Row3 = state.SearchResults.Data.Row3;

      await gerateFiltersParams(AllFilters, Row3, Logos, 2, Journey3);

      let Journey4 = [
        state.SearchParameters.Segments[3].Origin,
        state.SearchParameters.Segments[3].Destination,
      ];

      let Row4 = state.SearchResults.Data.Row4;

      await gerateFiltersParams(AllFilters, Row4, Logos, 3, Journey4);

      dispatch(setFiltersFinalUpdate(AllFilters));
    } else if (state.SearchResultType === 4) {
      let AllFilters = [
        {
          index: 0,
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          index: 0,
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          index: 0,
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          index: 0,
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
      ];

      let Logos = state.SearchResults.AirlinesLogos;

      // Generate for Split View
      let Journey1 = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Row1 = state.SearchResults.Data.Split.Going;

      await gerateFiltersParams(AllFilters, Row1, Logos, 2, Journey1);

      let Journey2 = [
        state.SearchParameters.Segments[0].Destination,
        state.SearchParameters.Segments[0].Origin,
      ];

      let Row2 = state.SearchResults.Data.Split.Returning;

      await gerateFiltersParams(AllFilters, Row2, Logos, 3, Journey2);

      // Generate for Combined View
      let CombinedData = state.SearchResults.Data.Combined;

      let Journey3 = [
        {
          SeqNo: 0,
          Origin: state.SearchParameters.Segments[0].Origin,
          Destination: state.SearchParameters.Segments[0].Destination,
        },
        {
          SeqNo: 1,
          Origin: state.SearchParameters.Segments[0].Destinationm,
          Destination: state.SearchParameters.Segments[0].Origin,
        },
      ];

      await gerateFiltersParamsCombined(
        AllFilters,
        CombinedData,
        Logos,
        Journey3,
        2
      );

      dispatch(setFiltersFinalUpdate(AllFilters));
    } else if (state.SearchResultType === 6) {
      let AllFilters = [
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
      ];

      let Logos = state.SearchResults.AirlinesLogos;

      // Generate for Combined View
      let CombinedData = state.SearchResults.Data.Combined;

      let Journey3 = [
        {
          SeqNo: 0,
          Origin: state.SearchParameters.Segments[0].Origin,
          Destination: state.SearchParameters.Segments[0].Destination,
        },
        {
          SeqNo: 1,
          Destination: state.SearchParameters.Segments[1].Origin,
          Origin: state.SearchParameters.Segments[1].Destinationm,
        },
      ];

      await gerateFiltersParamsCombined(
        AllFilters,
        CombinedData,
        Logos,
        Journey3,
        2
      );

      // Generate for Split View
      let Journey1 = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Row1 = state.SearchResults.Data.Split.Row1;

      await gerateFiltersParams(AllFilters, Row1, Logos, 2, Journey1);

      let Journey2 = [
        state.SearchParameters.Segments[1].Origin,
        state.SearchParameters.Segments[1].Destination,
      ];

      let Row2 = state.SearchResults.Data.Split.Row2;

      await gerateFiltersParams(AllFilters, Row2, Logos, 3, Journey2);

      dispatch(setFiltersFinalUpdate(AllFilters));
    } else if (state.SearchResultType === 8) {
      let AllFilters = [
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
      ];

      let Logos = state.SearchResults.AirlinesLogos;

      // Generate for Combined View
      let CombinedData = state.SearchResults.Data.Combined;

      let Journey4 = [
        {
          SeqNo: 0,
          Origin: state.SearchParameters.Segments[0].Origin,
          Destination: state.SearchParameters.Segments[0].Destination,
        },
        {
          SeqNo: 1,
          Destination: state.SearchParameters.Segments[1].Origin,
          Origin: state.SearchParameters.Segments[1].Destinationm,
        },
        {
          SeqNo: 2,
          Destination: state.SearchParameters.Segments[2].Origin,
          Origin: state.SearchParameters.Segments[2].Destinationm,
        },
      ];

      await gerateFiltersParamsCombined(
        AllFilters,
        CombinedData,
        Logos,
        Journey4,
        3
      );

      // Generate for Split View
      let Journey1 = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Row1 = state.SearchResults.Data.Split.Row1;

      await gerateFiltersParams(AllFilters, Row1, Logos, 3, Journey1);

      let Journey2 = [
        state.SearchParameters.Segments[1].Origin,
        state.SearchParameters.Segments[1].Destination,
      ];

      let Row2 = state.SearchResults.Data.Split.Row2;

      await gerateFiltersParams(AllFilters, Row2, Logos, 4, Journey2);

      let Journey3 = [
        state.SearchParameters.Segments[2].Origin,
        state.SearchParameters.Segments[2].Destination,
      ];

      let Row3 = state.SearchResults.Data.Split.Row3;

      await gerateFiltersParams(AllFilters, Row3, Logos, 5, Journey3);

      dispatch(setFiltersFinalUpdate(AllFilters));
    } else if (state.SearchResultType === 10) {
      let AllFilters = [
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
        {
          PopularFilters: [], // Initialize PopularFilters as an empty object
          PriceDragger: {},
          DurationDragger: {},
          LayoverDurationDragger: {},
          LayoverAirports: {},
          nearbyArrivalAirports: {},
          Airlines: {},
        },
      ];

      let Logos = state.SearchResults.AirlinesLogos;

      // Generate for Combined View
      let CombinedData = state.SearchResults.Data.Combined;

      let Journey5 = [
        {
          SeqNo: 0,
          Origin: state.SearchParameters.Segments[0].Origin,
          Destination: state.SearchParameters.Segments[0].Destination,
        },
        {
          SeqNo: 1,
          Destination: state.SearchParameters.Segments[1].Origin,
          Origin: state.SearchParameters.Segments[1].Destinationm,
        },
        {
          SeqNo: 2,
          Destination: state.SearchParameters.Segments[2].Origin,
          Origin: state.SearchParameters.Segments[2].Destinationm,
        },
        {
          SeqNo: 3,
          Destination: state.SearchParameters.Segments[3].Origin,
          Origin: state.SearchParameters.Segments[3].Destinationm,
        },
      ];

      await gerateFiltersParamsCombined(
        AllFilters,
        CombinedData,
        Logos,
        Journey5,
        4
      );

      // Generate for Split View
      let Journey1 = [
        state.SearchParameters.Segments[0].Origin,
        state.SearchParameters.Segments[0].Destination,
      ];

      let Row1 = state.SearchResults.Data.Split.Row1;

      await gerateFiltersParams(AllFilters, Row1, Logos, 4, Journey1);

      let Journey2 = [
        state.SearchParameters.Segments[1].Origin,
        state.SearchParameters.Segments[1].Destination,
      ];

      let Row2 = state.SearchResults.Data.Split.Row2;

      await gerateFiltersParams(AllFilters, Row2, Logos, 5, Journey2);

      let Journey3 = [
        state.SearchParameters.Segments[2].Origin,
        state.SearchParameters.Segments[2].Destination,
      ];

      let Row3 = state.SearchResults.Data.Split.Row3;

      await gerateFiltersParams(AllFilters, Row3, Logos, 6, Journey3);

      let Journey4 = [
        state.SearchParameters.Segments[3].Origin,
        state.SearchParameters.Segments[3].Destination,
      ];

      let Row4 = state.SearchResults.Data.Split.Row4;

      await gerateFiltersParams(AllFilters, Row4, Logos, 7, Journey4);

      dispatch(setFiltersFinalUpdate(AllFilters));
    }
  };
};

export const setInitialSettings = () => {
  return async (dispatch, getState) => {
    const statesData = getState();
    console.log("statesData is : ", statesData);
    let { ResultPageFlightsFlight } = statesData;

    let state = { ...ResultPageFlightsFlight };
    console.log("the states are  : ", state);

    if (state.SearchResultType === 1 || state.SearchResultType === 2) {
      let dd = await sortByPrice(state.FilteredData, 1);
      console.log("price sorted by price ios : ", dd);
      dispatch(setFilteredData(dd));
    } else if (state.SearchResultType === 3) {
      let Going = await sortByPrice(state.FilteredData.Going, 2);
      let Returning = await sortByPrice(state.FilteredData.Returning, 2);

      let DatatoPass = {
        Going: Going,
        Returning: Returning,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Going[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Returning[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = { ...Going[0], AirlinesDetails: allAirlines1 };
      let Data2 = { ...Returning[0], AirlinesDetails: allAirlines2 };

      let DatToInert = [Data1, Data2, [], []];

      dispatch(setbarModeDataInitial(DatToInert));
    } else if (state.SearchResultType === 4 && state.ResultView === 1) {
      let Combined = await sortByPrice(state.FilteredData.Combined, 2);

      let DatatoPass = {
        Split: {
          Going: state.FilteredData.Split.Going,
          Returning: state.FilteredData.Split.Returning,
        },
        Combined: Combined,
      };

      dispatch(setFilteredData(DatatoPass));
    } else if (state.SearchResultType === 4 && state.ResultView === 2) {
      let Going = await sortByPrice(state.FilteredData.Split.Going, 2);
      let Returning = await sortByPrice(state.FilteredData.Split.Returning, 2);

      console.log("Going the data is is : ", Going);
      console.log("Returning the data is is : ", Returning);
      let DatatoPass = {
        Split: { Going: Going, Returning: Returning },
        Combined: state.FilteredData.Combined,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Going[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Returning[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = { ...Going[0], AirlinesDetails: allAirlines1 };
      let Data2 = { ...Returning[0], AirlinesDetails: allAirlines2 };

      let DatToInert = [Data1, Data2, [], []];

      dispatch(setbarModeDataInitial(DatToInert));
    } else if (state.SearchResultType === 5) {
      let Row1 = await sortByPrice(state.FilteredData.Row1, 2);
      let Row2 = await sortByPrice(state.FilteredData.Row2, 2);

      let DatatoPass = {
        Row1: Row1,
        Row2: Row2,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Row1,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Row2,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = {
        ...state.FilteredData.Row1[0],
        AirlinesDetails: allAirlines1,
      };
      let Data2 = {
        ...state.FilteredData.Row2[0],
        AirlinesDetails: allAirlines2,
      };

      let DatToInert = [Data1, Data2, [], []];

      dispatch(setbarModeDataInitial(DatToInert));
    } else if (state.SearchResultType === 7) {
      let Row1 = await sortByPrice(state.FilteredData.Row1, 2);
      let Row2 = await sortByPrice(state.FilteredData.Row2, 2);
      let Row3 = await sortByPrice(state.FilteredData.Row3, 2);

      let DatatoPass = {
        Row1: Row1,
        Row2: Row2,
        Row3: Row3,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Row1,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Row2,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines3 = getAllAirlinesWithindex1(
        Row3,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = { ...Row1[0], AirlinesDetails: allAirlines1 };
      let Data2 = { ...Row2[0], AirlinesDetails: allAirlines2 };
      let Data3 = { ...Row3[0], AirlinesDetails: allAirlines3 };

      let DatToInert = [Data1, Data2, Data3, []];

      dispatch(setbarModeDataInitial(DatToInert));
    } else if (state.SearchResultType === 9) {
      let Row1 = await sortByPrice(state.FilteredData.Row1, 2);
      let Row2 = await sortByPrice(state.FilteredData.Row2, 2);
      let Row3 = await sortByPrice(state.FilteredData.Row3, 2);
      let Row4 = await sortByPrice(state.FilteredData.Row4, 2);

      let DatatoPass = {
        Row1: Row1,
        Row2: Row2,
        Row3: Row3,
        Row4: Row4,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Row1,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Row2,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines3 = getAllAirlinesWithindex1(
        Row3,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines4 = getAllAirlinesWithindex1(
        Row4,
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = { ...Row1[0], AirlinesDetails: allAirlines1 };
      let Data2 = { ...Row2[0], AirlinesDetails: allAirlines2 };
      let Data3 = { ...Row3[0], AirlinesDetails: allAirlines3 };
      let Data4 = { ...Row4[0], AirlinesDetails: allAirlines4 };

      let DatToInert = [Data1, Data2, Data3, Data4];

      dispatch(setbarModeDataInitial(DatToInert));
    } else if (state.SearchResultType === 6 && state.ResultView === 1) {
      let Combined = await sortByPrice(state.FilteredData.Combined, 1);

      let DatatoPass = {
        Split: {
          Row1: state.FilteredData.Split.Row1,
          Row2: state.FilteredData.Split.Row2,
        },
        Combined: Combined,
      };

      dispatch(setFilteredData(DatatoPass));
    } else if (state.SearchResultType === 6 && state.ResultView === 2) {
      let Row1 = await sortByPrice(state.FilteredData.Split.Row1, 2);
      let Row2 = await sortByPrice(state.FilteredData.Split.Row2, 2);

      let DatatoPass = {
        Split: { Row1: Row1, Row2: Row2 },
        Combined: state.FilteredData.Combined,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Row1[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Row2[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = { ...Row1[0], AirlinesDetails: allAirlines1 };
      let Data2 = { ...Row2[0], AirlinesDetails: allAirlines2 };

      let DatToInert = [Data1, Data2, [], []];

      dispatch(setbarModeDataInitial(DatToInert));
    } else if (state.SearchResultType === 8 && state.ResultView === 1) {
      let Combined = await sortByPrice(state.FilteredData.Combined, 1);

      let DatatoPass = {
        Split: {
          Row1: state.FilteredData.Split.Row1,
          Row2: state.FilteredData.Split.Row2,
          Row3: state.FilteredData.Split.Row3,
        },
        Combined: Combined,
      };

      dispatch(setFilteredData(DatatoPass));
    } else if (state.SearchResultType === 8 && state.ResultView === 2) {
      let Row1 = await sortByPrice(state.FilteredData.Split.Row1, 2);
      let Row2 = await sortByPrice(state.FilteredData.Split.Row2, 2);
      let Row3 = await sortByPrice(state.FilteredData.Split.Row3, 2);

      let DatatoPass = {
        Split: { Row1: Row1, Row2: Row2, Row3: Row3 },
        Combined: state.FilteredData.Combined,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Row1[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Row2[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines3 = getAllAirlinesWithindex1(
        Row3[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = { ...Row1[0], AirlinesDetails: allAirlines1 };
      let Data2 = { ...Row2[0], AirlinesDetails: allAirlines2 };
      let Data3 = { ...Row3[0], AirlinesDetails: allAirlines3 };

      let DatToInert = [Data1, Data2, Data3, []];

      dispatch(setbarModeDataInitial(DatToInert));
    } else if (state.SearchResultType === 10 && state.ResultView === 1) {
      let Combined = await sortByPrice(state.FilteredData.Combined, 1);

      let DatatoPass = {
        Split: {
          Row1: state.FilteredData.Split.Row1,
          Row2: state.FilteredData.Split.Row2,
          Row3: state.FilteredData.Split.Row3,
          Row4: state.FilteredData.Split.Row4,
        },
        Combined: Combined,
      };

      dispatch(setFilteredData(DatatoPass));
    } else if (state.SearchResultType === 10 && state.ResultView === 2) {
      let Row1 = await sortByPrice(state.FilteredData.Split.Row1, 2);
      let Row2 = await sortByPrice(state.FilteredData.Split.Row2, 2);
      let Row3 = await sortByPrice(state.FilteredData.Split.Row3, 2);
      let Row4 = await sortByPrice(state.FilteredData.Split.Row4, 2);

      let DatatoPass = {
        Split: { Row1: Row1, Row2: Row2, Row3: Row3, Row4: Row4 },
        Combined: state.FilteredData.Combined,
      };

      dispatch(setFilteredData(DatatoPass));

      let allAirlines1 = getAllAirlinesWithindex1(
        Row1[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines2 = getAllAirlinesWithindex1(
        Row2[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines3 = getAllAirlinesWithindex1(
        Row3[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );
      let allAirlines4 = getAllAirlinesWithindex1(
        Row4[0],
        1,
        state.SearchResults.AirlinesLogos,
        1
      );

      let Data1 = { ...Row1[0], AirlinesDetails: allAirlines1 };
      let Data2 = { ...Row2[0], AirlinesDetails: allAirlines2 };
      let Data3 = { ...Row3[0], AirlinesDetails: allAirlines3 };
      let Data4 = { ...Row4[0], AirlinesDetails: allAirlines4 };

      let DatToInert = [Data1, Data2, Data3, Data4];

      dispatch(setbarModeDataInitial(DatToInert));
    }
  };
};

export const ReviewPageRedirector = (Data , navigate , Theme) => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { ResultPageFlightsFlight } = statesData;

    let state = { ...ResultPageFlightsFlight };

    let apiParams = {};

    console.log("The selected Data for the revioew is  : ", Data);

    if (state.SearchResultType === 1 || state.SearchResultType === 2) {
      console.log("the dtaa ot book is : ", Data);

      switch (Data.DataFormat) {
        case "DOM-OW":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Oneway Domestic",
            Params: [
              {
                DataFormat: "DOM-OW",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                FlightNumbers: Data?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };

          break;
        case "DOM-OW-NR":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Oneway Domestic",
            Params: [
              {
                DataFormat: "DOM-OW-NR",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                NearestAirport: Data.NearestAirportData.nearestCode,
                NearestAirportData: Data.NearestAirportData,

                FlightNumbers: Data?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };

          break;
        case "DOM-OW-CA":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Oneway Domestic",
            Params: [
              {
                DataFormat: "DOM-OW-CA",
                Company: Data.Connections[0].Source.Company,
                CompanyType: Data.Connections[0].Source.Identifier,
                ConnectingAirport:
                  Data.Connections[0].Segments[
                    Data.Connections[0].Segments.length - 1
                  ].Destination.Airport.AirportCode,
                DepartureDate: Data.Connections[0].Segments[0].Origin.DepTime,
                FlightNumbers: Data?.Connections[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },

              {
                DataFormat: "DOM-OW-CA",
                Company: Data.Connections[1].Source.Company,
                CompanyType: Data.Connections[1].Source.Identifier,
                DepartureDate: Data.Connections[1].Segments[0].Origin.DepTime,
                FlightNumbers: Data?.Connections[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[state.FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[state.FFSelected[1]],
            ],
          };

          break;
        case "INT-OW":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Oneway International",
            Params: [
              {
                DataFormat: "INT-OW",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                FlightNumbers: Data?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };

          break;
        case "INT-OW-NR":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Oneway International",
            Params: [
              {
                DataFormat: "INT-OW-NR",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                NearestAirport: Data.NearestAirportData.nearestCode,
                NearestAirportData: Data.NearestAirportData,

                FlightNumbers: Data?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };

          break;
        case "INT-OW-VIA":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Oneway International",
            Params: [
              {
                DataFormat: "INT-OW-VIA",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                ViaAirports: Data.ViaAirports,
                FlightNumbers: Data?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };

          break;
        case "INT-OW-CA":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Oneway International",
            Params: [
              {
                DataFormat: "INT-OW-CA",
                Company: Data.Connections[0].Source.Company,
                CompanyType: Data.Connections[0].Source.Identifier,
                ConnectingAirport:
                  Data.Connections[0].Segments[
                    Data.Connections[0].Segments.length - 1
                  ].Destination.Airport.AirportCode,
                DepartureDate: Data.Connections[0].Segments[0].Origin.DepTime,
                FlightNumbers: Data?.Connections[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },

              {
                DataFormat: "INT-OW-CA",
                Company: Data.Connections[1].Source.Company,
                CompanyType: Data.Connections[1].Source.Identifier,
                DepartureDate: Data.Connections[1].Segments[0].Origin.DepTime,
                FlightNumbers: Data?.Connections[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[state.FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[state.FFSelected[1]],
            ],
          };

          break;

        default:
          break;
      }
    } else if (state.SearchResultType === 3) {
      apiParams = {
        SearchId: state.SearchResults.SearchId,
        Mode: "fr",
        FunctionType: "Round Domestic",
        Params: [
          Data[0].DataFormat == "DOM-RD"
            ? {
                DataFormat: "DOM-RD",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-RD-NR",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                NearestAirport: Data[0].NearestAirportData.nearestCode,
                NearestAirportData: Data[0].NearestAirportData,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[1].DataFormat == "DOM-RD"
            ? {
                DataFormat: "DOM-RD",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-RD-NR",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                NearestAirport: Data[1].NearestAirportData.nearestCode,
                NearestAirportData: Data[1].NearestAirportData,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
        ],
        FareFamilyChoosed: [
          Data[0].FareFamilies.FFList[state.FFSelected[0]],
          Data[1].FareFamilies.FFList[state.FFSelected[1]],
        ],
      };
    } else if (state.SearchResultType === 4 && state.ResultView === 1) {
      switch (Data.DataFormat) {
        case "INT-RD":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Round International",
            Params: [
              {
                DataFormat: "INT-RD",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                FlightNumbers: [
                  Data?.Segments[0]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[1]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                ],
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };
break;
        case "INT-RD-NR":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Round International",
            Params: [
              {
                DataFormat: "INT-RD-NR",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                NearestAirport: Data.NearestAirportData.nearestCode,
                NearestAirportData: Data.NearestAirportData,
                FlightNumbers: [
                  Data?.Segments[0]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[1]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                ],
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };
        // isNearest
        break;
        case "INT-RD-OWOW":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: "Round International",
            Params: [
              Data.Connections[0]?.isNearest == false ||
              Data.Connections[0].isNearest == undefined
                ? {
                    DataFormat: "INT-RD-OWOW",
                    Company: Data.Connections[0].Source.Company,
                    CompanyType: Data.Connections[0].Source.Identifier,
                    FlightNumbers: Data.Connections[0]?.Segments?.map(
                      (item) => {
                        return {
                          Code: item.Airline.AirlineCode,
                          Number: item.Airline.FlightNumber,
                        };
                      }
                    ),
                  }
                : {
                    DataFormat: "INT-RD-OWOW",
                    Company: Data.Connections[0].Source.Company,
                    CompanyType: Data.Connections[0].Source.Identifier,
                    NearestAirport:
                      Data.Connections[0].NearestAirportData.nearestCode,
                    NearestAirportData: Data.Connections[0].NearestAirportData,
                    FlightNumbers: Data.Connections[0]?.Segments?.map(
                      (item) => {
                        return {
                          Code: item.Airline.AirlineCode,
                          Number: item.Airline.FlightNumber,
                        };
                      }
                    ),
                  },
              Data.Connections[1]?.isNearest == false ||
              Data.Connections[1].isNearest == undefined
                ? {
                    DataFormat: "INT-RD-OWOW",
                    Company: Data.Connections[1].Source.Company,
                    CompanyType: Data.Connections[1].Source.Identifier,
                    FlightNumbers: Data.Connections[1]?.Segments?.map(
                      (item) => {
                        return {
                          Code: item.Airline.AirlineCode,
                          Number: item.Airline.FlightNumber,
                        };
                      }
                    ),
                  }
                : {
                    DataFormat: "INT-RD-OWOW",
                    Company: Data.Connections[1].Source.Company,
                    CompanyType: Data.Connections[1].Source.Identifier,
                    NearestAirport:
                      Data.Connections[1].NearestAirportData.nearestCode,
                    NearestAirportData: Data.Connections[1].NearestAirportData,
                    FlightNumbers: Data.Connections[1]?.Segments?.map(
                      (item) => {
                        return {
                          Code: item.Airline.AirlineCode,
                          Number: item.Airline.FlightNumber,
                        };
                      }
                    ),
                  },
            ],
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[state.FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[state.FFSelected[1]],
            ],
          };
          break;
        default:
          break;
      }
    } else if (state.SearchResultType === 4 && state.ResultView === 2) {
      apiParams = {
        SearchId: state.SearchResults.SearchId,
        Mode: "fr",
        FunctionType: "Round International",
        Params: [
          Data[0]?.isNearest == false || Data[0]?.isNearest == undefined
            ? {
                DataFormat: "INT-RD-OWOW",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "INT-RD-OWOW",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                NearestAirport: Data[0].NearestAirportData.nearestCode,
                NearestAirportData: Data[0].NearestAirportData,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[1]?.isNearest == false || Data[1]?.isNearest == undefined
            ? {
                DataFormat: "INT-RD-OWOW",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "INT-RD-OWOW",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                NearestAirport: Data[1].NearestAirportData.nearestCode,
                NearestAirportData: Data[1].NearestAirportData,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
        ],
        FareFamilyChoosed: [
          Data[0].FareFamilies.FFList[state.FFSelected[0]],
          Data[1].FareFamilies.FFList[state.FFSelected[1]],
        ],
      };
    } else if (state.SearchResultType === 5) {
      apiParams = {
        SearchId: state.SearchResults.SearchId,
        Mode: "fr",
        FunctionType: "Multiway Domestic 2",
        Params: [
          Data[0]?.DataFormat == "DOM-ML-2"
            ? {
                DataFormat: "DOM-ML-2",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-2",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                NearestAirport: Data[0].NearestAirportData.nearestCode,
                NearestAirportData: Data[0].NearestAirportData,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[1]?.DataFormat == "DOM-ML-2"
            ? {
                DataFormat: "DOM-ML-2",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-2",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                NearestAirport: Data[1].NearestAirportData.nearestCode,
                NearestAirportData: Data[1].NearestAirportData,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
        ],
        FareFamilyChoosed: [
          Data[0].FareFamilies.FFList[state.FFSelected[0]],
          Data[1].FareFamilies.FFList[state.FFSelected[1]],
        ],
      };
    } else if (state.SearchResultType === 7) {
      apiParams = {
        SearchId: state.SearchResults.SearchId,
        Mode: "fr",
        FunctionType: "Multiway Domestic 3",
        Params: [
          Data[0]?.DataFormat == "DOM-ML-3"
            ? {
                DataFormat: "DOM-ML-3",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-3",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                NearestAirport: Data[0].NearestAirportData.nearestCode,
                NearestAirportData: Data[0].NearestAirportData,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[1]?.DataFormat == "DOM-ML-3"
            ? {
                DataFormat: "DOM-ML-3",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-3",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                NearestAirport: Data[1].NearestAirportData.nearestCode,
                NearestAirportData: Data[1].NearestAirportData,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[2]?.DataFormat == "DOM-ML-3"
            ? {
                DataFormat: "DOM-ML-3",
                Company: Data[2].Source.Company,
                CompanyType: Data[2].Source.Identifier,
                FlightNumbers: Data[2]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-3",
                Company: Data[2].Source.Company,
                CompanyType: Data[2].Source.Identifier,
                NearestAirport: Data[2].NearestAirportData.nearestCode,
                NearestAirportData: Data[2].NearestAirportData,
                FlightNumbers: Data[2]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
        ],
        FareFamilyChoosed: [
          Data[0].FareFamilies.FFList[state.FFSelected[0]],
          Data[1].FareFamilies.FFList[state.FFSelected[1]],
          Data[2].FareFamilies.FFList[state.FFSelected[2]],
        ],
      };
    } else if (state.SearchResultType === 9) {
      apiParams = {
        SearchId: state.SearchResults.SearchId,
        Mode: "fr",
        FunctionType: "Multiway Domestic 4",
        Params: [
          Data[0]?.DataFormat == "DOM-ML-4"
            ? {
                DataFormat: "DOM-ML-4",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-4",
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                NearestAirport: Data[0].NearestAirportData.nearestCode,
                NearestAirportData: Data[0].NearestAirportData,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[1]?.DataFormat == "DOM-ML-4"
            ? {
                DataFormat: "DOM-ML-4",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-4",
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                NearestAirport: Data[1].NearestAirportData.nearestCode,
                NearestAirportData: Data[1].NearestAirportData,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[2]?.DataFormat == "DOM-ML-4"
            ? {
                DataFormat: "DOM-ML-4",
                Company: Data[2].Source.Company,
                CompanyType: Data[2].Source.Identifier,
                FlightNumbers: Data[2]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-4",
                Company: Data[2].Source.Company,
                CompanyType: Data[2].Source.Identifier,
                NearestAirport: Data[2].NearestAirportData.nearestCode,
                NearestAirportData: Data[2].NearestAirportData,
                FlightNumbers: Data[2]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
          Data[3]?.DataFormat == "DOM-ML-4"
            ? {
                DataFormat: "DOM-ML-4",
                Company: Data[3].Source.Company,
                CompanyType: Data[3].Source.Identifier,
                FlightNumbers: Data[3]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              }
            : {
                DataFormat: "DOM-ML-NR-4",
                Company: Data[3].Source.Company,
                CompanyType: Data[3].Source.Identifier,
                NearestAirport: Data[3].NearestAirportData.nearestCode,
                NearestAirportData: Data[3].NearestAirportData,
                FlightNumbers: Data[3]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
        ],
        FareFamilyChoosed: [
          Data[0].FareFamilies.FFList[state.FFSelected[0]],
          Data[1].FareFamilies.FFList[state.FFSelected[1]],
          Data[2].FareFamilies.FFList[state.FFSelected[2]],
          Data[3].FareFamilies.FFList[state.FFSelected[3]],
        ],
      };
    } else if (state.SearchResultType === 6 && state.ResultView === 1) {
      switch (Data.DataFormat) {
        case "INT-ML-2" || "HYB-ML-2":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType:
              Data.DataFormat == "INT-ML-2"
                ? "Multiway International 2"
                : "Multiway Hybgrid 2",
            Params: [
              {
                DataFormat: "INT-ML-2",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                FlightNumbers: [
                  Data?.Segments[0]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[1]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                ],
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };
          break;
        case "INT-ML-OWOW-2" || "HYB-ML-OWOW-2":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType:
              Data.DataFormat == "INT-ML-OWOW-2"
                ? "Multiway International Combined 2"
                : "Multiway Hybgrid Combined 2",
            Params: [
              {
                DataFormat: Data.Connections[0].DataFormat,
                Company: Data.Connections[0].Source.Company,
                CompanyType: Data.Connections[0].Source.Identifier,
                FlightNumbers: Data.Connections[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
              {
                DataFormat: Data.Connections[1].DataFormat,
                Company: Data.Connections[1].Source.Company,
                CompanyType: Data.Connections[1].Source.Identifier,
                FlightNumbers: Data.Connections[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[state.FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[state.FFSelected[1]],
            ],
          };
          break;
        default:
          break;
      }
    } else if (state.SearchResultType === 6 && state.ResultView === 2) {
  
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType: Data[0].DataFormat == "INT-ML-OWOW-2" ? "Multiway International Split 2" : "Multiway Hybgrid Split 2",
            Params: [
              {
                DataFormat: Data[0].DataFormat,
                Company: Data[0].Source.Company,
                CompanyType: Data[0].Source.Identifier,
                FlightNumbers: Data[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
              {
                DataFormat: Data[1].DataFormat,
                Company: Data[1].Source.Company,
                CompanyType: Data[1].Source.Identifier,
                FlightNumbers: Data[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [
              Data[0].FareFamilies.FFList[state.FFSelected[0]],
              Data[1].FareFamilies.FFList[state.FFSelected[1]],
            ],
          };
  
    } else if (state.SearchResultType === 8 && state.ResultView === 1) {

      switch (Data.DataFormat) {
        case "INT-ML-3" || "HYB-ML-3":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType:
              Data.DataFormat == "INT-ML-3"
                ? "Multiway International 3"
                : "Multiway Hybgrid 3",
            Params: [
              {
                DataFormat: "INT-ML-3",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                FlightNumbers: [
                  Data?.Segments[0]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[1]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[2]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                ],
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };
          break;
        case "INT-ML-OWOW-3" || "HYB-ML-OWOW-3":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType:
              Data.DataFormat == "INT-ML-OWOW-3"
                ? "Multiway International Combined 3"
                : "Multiway Hybgrid Combined 3",
            Params: [
              {
                DataFormat: Data.Connections[0].DataFormat,
                Company: Data.Connections[0].Source.Company,
                CompanyType: Data.Connections[0].Source.Identifier,
                FlightNumbers: Data.Connections[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
              {
                DataFormat: Data.Connections[1].DataFormat,
                Company: Data.Connections[1].Source.Company,
                CompanyType: Data.Connections[1].Source.Identifier,
                FlightNumbers: Data.Connections[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
              {
                DataFormat: Data.Connections[2].DataFormat,
                Company: Data.Connections[2].Source.Company,
                CompanyType: Data.Connections[2].Source.Identifier,
                FlightNumbers: Data.Connections[2]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[state.FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[state.FFSelected[1]],
              Data.Connections[2].FareFamilies.FFList[state.FFSelected[2]],
            ],
          };
          break;
        default:
          break;
      }


    } else if (state.SearchResultType === 8 && state.ResultView === 2) {

        
      apiParams = {
        SearchId: state.SearchResults.SearchId,
        Mode: "fr",
        FunctionType: Data[0].DataFormat == "INT-ML-OWOW-3" ? "Multiway International Split 3" : "Multiway Hybgrid Split 3",
        Params: [
          {
            DataFormat: Data[0].DataFormat,
            Company: Data[0].Source.Company,
            CompanyType: Data[0].Source.Identifier,
            FlightNumbers: Data[0]?.Segments?.map((item) => {
              return {
                Code: item.Airline.AirlineCode,
                Number: item.Airline.FlightNumber,
              };
            }),
          },
          {
            DataFormat: Data[1].DataFormat,
            Company: Data[1].Source.Company,
            CompanyType: Data[1].Source.Identifier,
            FlightNumbers: Data[1]?.Segments?.map((item) => {
              return {
                Code: item.Airline.AirlineCode,
                Number: item.Airline.FlightNumber,
              };
            }),
          },
          {
            DataFormat: Data[2].DataFormat,
            Company: Data[2].Source.Company,
            CompanyType: Data[2].Source.Identifier,
            FlightNumbers: Data[2]?.Segments?.map((item) => {
              return {
                Code: item.Airline.AirlineCode,
                Number: item.Airline.FlightNumber,
              };
            }),
          },
        ],
        FareFamilyChoosed: [
          Data[0].FareFamilies.FFList[state.FFSelected[0]],
          Data[1].FareFamilies.FFList[state.FFSelected[1]],
          Data[2].FareFamilies.FFList[state.FFSelected[2]],
        ],
      };


    } else if (state.SearchResultType === 10 && state.ResultView === 1) {

      switch (Data.DataFormat) {
        case "INT-ML-4" || "HYB-ML-4":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType:
              Data.DataFormat == "INT-ML-4"
                ? "Multiway International 4"
                : "Multiway Hybgrid 4",
            Params: [
              {
                DataFormat: "INT-ML-4",
                Company: Data.Source.Company,
                CompanyType: Data.Source.Identifier,
                FlightNumbers: [
                  Data?.Segments[0]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[1]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[2]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                  Data?.Segments[3]?.map((item) => {
                    return {
                      Code: item.Airline.AirlineCode,
                      Number: item.Airline.FlightNumber,
                    };
                  }),
                ],
              },
            ],
            FareFamilyChoosed: [Data.FareFamilies.FFList[state.FFSelected[0]]],
          };
          break;
        case "INT-ML-OWOW-4" || "HYB-ML-OWOW-4":
          apiParams = {
            SearchId: state.SearchResults.SearchId,
            Mode: "fr",
            FunctionType:
              Data.DataFormat == "INT-ML-OWOW-4"
                ? "Multiway International Combined 4"
                : "Multiway Hybgrid Combined 4",
            Params: [
              {
                DataFormat: Data.Connections[0].DataFormat,
                Company: Data.Connections[0].Source.Company,
                CompanyType: Data.Connections[0].Source.Identifier,
                FlightNumbers: Data.Connections[0]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
              {
                DataFormat: Data.Connections[1].DataFormat,
                Company: Data.Connections[1].Source.Company,
                CompanyType: Data.Connections[1].Source.Identifier,
                FlightNumbers: Data.Connections[1]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
              {
                DataFormat: Data.Connections[2].DataFormat,
                Company: Data.Connections[2].Source.Company,
                CompanyType: Data.Connections[2].Source.Identifier,
                FlightNumbers: Data.Connections[2]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
              {
                DataFormat: Data.Connections[3].DataFormat,
                Company: Data.Connections[3].Source.Company,
                CompanyType: Data.Connections[3].Source.Identifier,
                FlightNumbers: Data.Connections[3]?.Segments?.map((item) => {
                  return {
                    Code: item.Airline.AirlineCode,
                    Number: item.Airline.FlightNumber,
                  };
                }),
              },
            ],
            FareFamilyChoosed: [
              Data.Connections[0].FareFamilies.FFList[state.FFSelected[0]],
              Data.Connections[1].FareFamilies.FFList[state.FFSelected[1]],
              Data.Connections[2].FareFamilies.FFList[state.FFSelected[2]],
              Data.Connections[3].FareFamilies.FFList[state.FFSelected[3]],
            ],
          };
          break;
        default:
          break;
      }


    } else if (state.SearchResultType === 10 && state.ResultView === 2) {

              
      apiParams = {
        SearchId: state.SearchResults.SearchId,
        Mode: "fr",
        FunctionType: Data[0].DataFormat == "INT-ML-OWOW-4" ? "Multiway International Split 4" : "Multiway Hybgrid Split 4",
        Params: [
          {
            DataFormat: Data[0].DataFormat,
            Company: Data[0].Source.Company,
            CompanyType: Data[0].Source.Identifier,
            FlightNumbers: Data[0]?.Segments?.map((item) => {
              return {
                Code: item.Airline.AirlineCode,
                Number: item.Airline.FlightNumber,
              };
            }),
          },
          {
            DataFormat: Data[1].DataFormat,
            Company: Data[1].Source.Company,
            CompanyType: Data[1].Source.Identifier,
            FlightNumbers: Data[1]?.Segments?.map((item) => {
              return {
                Code: item.Airline.AirlineCode,
                Number: item.Airline.FlightNumber,
              };
            }),
          },
          {
            DataFormat: Data[2].DataFormat,
            Company: Data[2].Source.Company,
            CompanyType: Data[2].Source.Identifier,
            FlightNumbers: Data[2]?.Segments?.map((item) => {
              return {
                Code: item.Airline.AirlineCode,
                Number: item.Airline.FlightNumber,
              };
            }),
          },
          {
            DataFormat: Data[3].DataFormat,
            Company: Data[3].Source.Company,
            CompanyType: Data[3].Source.Identifier,
            FlightNumbers: Data[3]?.Segments?.map((item) => {
              return {
                Code: item.Airline.AirlineCode,
                Number: item.Airline.FlightNumber,
              };
            }),
          },
        ],
        FareFamilyChoosed: [
          Data[0].FareFamilies.FFList[state.FFSelected[0]],
          Data[1].FareFamilies.FFList[state.FFSelected[1]],
          Data[2].FareFamilies.FFList[state.FFSelected[2]],
          Data[3].FareFamilies.FFList[state.FFSelected[3]],
        ],
      };
   
    }

    console.log("thr api params ehre si  :  " , apiParams);
    
    try {

      let HoldParas = await HoldTheFlight(apiParams);

      dispatch(setbookLoading(false))

      let itr = HoldParas.data.ItenaryID;
      let srid = HoldParas.data.SearchId;

      // Prepare the data to be encrypted
      
      const dataToEncrypt = {
        Theme,
        searchkey: state.searchkey,
        itrCode: state.itrCode,
      };

      // Encrypt the object using AES encryption
      const secretKey = 'your-secret-key'; // Replace with your own secret key
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), secretKey).toString();

      // Construct the URL with the encrypted data and plain itr and srid
      window.open(`/flights/review-details?itr=${itr}&sri=${srid}&dti=${encodeURIComponent(encryptedData)}`, '_blank');

    } catch(e) {
      dispatch(setbookLoading(false))
      dispatch(setbookLoadingError(true))
    }

    console.log("the api params are  : ", apiParams);
  };
};

// Round International = 4
// Multi domestic 2 Row= 5
// Multi International/Hybgrid 2 Row= 6
// Multi domestic 3 Row= 7
// Multi International/Hybgrid 3 Row= 8
// Multi domestic 4 Row= 9
// Multi International/Hybgrid 4 Row= 10

const ResultPageFlightsFlightSlice = createSlice({
  name: "ResultPageFlightsFlight",
  initialState,
  reducers: {
    // Search bar Reducers

    getUrlParams: (state, action) => {
      let GenerateTheSegments = (itrData, params) => {
        let splitTheItr = itrData.split("|");

        // Map each segment to an object with the required fields
        let finalSegments = splitTheItr.map((item, index) => {
          return {
            Origin: item.substring(0, 3),
            Destination: item.substring(4, 7),
            OriginCity: params[index].OriginCity,
            DestinationCity: params[index].DestinationCity,
            OriginCountryCode: item.substring(19, 21),
            DestinationCountryCode: item.substring(22, 25),
            Date: item.substring(8, 18),
          };
        });

        if (finalSegments?.length > 1) {
          state.multiRowNumber = finalSegments?.length;
        }
        return finalSegments;
      };

      let isInternationalTrip = (cc1, cc2) => {
        return cc1 !== cc2;
      };

      let getTheResultType = (itrData) => {
        // Oneway domestic = 1
        // Oneway International = 2
        // Round domestic = 3
        // Round International = 4
        // Multi domestic 2 Row= 5
        // Multi International/Hybgrid 2 Row= 6
        // Multi domestic 3 Row= 7
        // Multi International/Hybgrid 3 Row= 8
        // Multi domestic 4 Row= 9
        // Multi International/Hybgrid 4 Row= 10

        if (itrData?.length === 1) {
          let isInternational = isInternationalTrip(
            itrData[0].substring(19, 21),
            itrData[0].substring(22, 25)
          );

          if (isInternational === true) {
            return 2;
          }
          return 1;
        } else if (itrData?.length === 2) {
          if (
            itrData[0].substring(0, 3) === itrData[1].substring(4, 7) &&
            itrData[0].substring(4, 7) === itrData[1].substring(0, 3)
          ) {
            let isInternational = isInternationalTrip(
              itrData[0].substring(19, 21),
              itrData[0].substring(22, 25)
            );

            if (isInternational === true) {
              return 4;
            }
            return 3;
          } else {
            let isInternational1 = isInternationalTrip(
              itrData[0].substring(19, 21),
              itrData[0].substring(22, 25)
            );
            let isInternational2 = isInternationalTrip(
              itrData[1].substring(19, 21),
              itrData[1].substring(22, 25)
            );

            if (isInternational1 === true || isInternational2 === true) {
              return 6;
            }
            return 5;
          }
        } else if (itrData?.length === 3) {
          let isInternational1 = isInternationalTrip(
            itrData[0].substring(19, 21),
            itrData[0].substring(22, 25)
          );
          let isInternational2 = isInternationalTrip(
            itrData[1].substring(19, 21),
            itrData[1].substring(22, 25)
          );
          let isInternational3 = isInternationalTrip(
            itrData[2].substring(19, 21),
            itrData[2].substring(22, 25)
          );

          if (
            isInternational1 === true ||
            isInternational2 === true ||
            isInternational3 === true
          ) {
            return 8;
          }
          return 7;
        } else if (itrData?.length === 4) {
          let isInternational1 = isInternationalTrip(
            itrData[0].substring(19, 21),
            itrData[0].substring(22, 25)
          );
          let isInternational2 = isInternationalTrip(
            itrData[1].substring(19, 21),
            itrData[1].substring(22, 25)
          );
          let isInternational3 = isInternationalTrip(
            itrData[2].substring(19, 21),
            itrData[2].substring(22, 25)
          );
          let isInternational4 = isInternationalTrip(
            itrData[3].substring(19, 21),
            itrData[3].substring(22, 25)
          );

          if (
            isInternational1 === true ||
            isInternational2 === true ||
            isInternational3 === true ||
            isInternational4 === true
          ) {
            return 10;
          }
          return 9;
        }
      };
      const urlParams = new URLSearchParams(window.location.search);

      const params = {};
      for (const [key, value] of urlParams) {
        params[key] = value;
      }

      // Validate the Url Params

      // Validator 1 : all parametrs are there ?

      if (
        params["tp"] === undefined ||
        params["tp"] === null ||
        params["tp"] === "" ||
        params["a"] === undefined ||
        params["a"] === null ||
        params["a"] === "" ||
        params["c"] === undefined ||
        params["c"] === null ||
        params["c"] === "" ||
        params["i"] === undefined ||
        params["i"] === null ||
        params["i"] === "" ||
        params["frc"] === undefined ||
        params["frc"] === null ||
        params["frc"] === "" ||
        params["frt"] === undefined ||
        params["frt"] === null ||
        params["frt"] === "" ||
        params["itr"] === undefined ||
        params["itr"] === null ||
        params["itr"] === ""
      ) {
        state.SearchResultsStatus = "idle";
        state.ErrorOnresultPage = true;
        state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
        state.ErrorOnresultPageMessageDetailed =
          FlightsResultPageErrorMainDescription.TED;
        return;
      }

      // Validator 2 : Check Individual parameters in right type ?

      // tp

      let tpp = ["ow", "rw", "mw"];

      if (!tpp.includes(params["tp"])) {
        state.SearchResultsStatus = "idle";
        state.ErrorOnresultPage = true;
        state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
        state.ErrorOnresultPageMessageDetailed =
          FlightsResultPageErrorMainDescription.TED;
        return;
      }

      // a c i

      try {
        let adult = +params["a"];
        let child = +params["c"];
        let infant = +params["i"];

        if (adult + child + infant > 9) {
          state.SearchResultsStatus = "idle";
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription.TED;
          return;
        }

        // a
        if (
          adult > Travellers.adults[Travellers.adults?.length - 1] ||
          adult === 0 ||
          adult < 0 ||
          infant > adult
        ) {
          state.SearchResultsStatus = "idle";
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription.TED;
          return;
        }

        // c
        if (
          child > Travellers.childs[Travellers.childs?.length - 1] ||
          child < 0
        ) {
          state.SearchResultsStatus = "idle";
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription.TED;
          return;
        }

        // i
        if (
          infant > Travellers.infants[Travellers.infants?.length - 1] ||
          infant > adult
        ) {
          state.SearchResultsStatus = "idle";
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription.TED;
          return;
        }
      } catch (e) {
        state.SearchResultsStatus = "idle";
        state.ErrorOnresultPage = true;
        state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
        state.ErrorOnresultPageMessageDetailed =
          FlightsResultPageErrorMainDescription.TED;
        return;
      }

      console.log(params);

      // frc

      if (!TravellerClasses.includes(params["frc"])) {
        state.SearchResultsStatus = "idle";
        state.ErrorOnresultPage = true;
        state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
        state.ErrorOnresultPageMessageDetailed =
          FlightsResultPageErrorMainDescription.TED;
        return;
      }

      // frt

      let adult = +params["a"];
      let child = +params["c"];
      let infant = +params["i"];

      if (
        !fareOptions.includes(params["frt"]) ||
        (params["frt"] === "Student" && (child > 0 || infant > 0)) ||
        (params["frt"] === "Senior Citizen" && (child > 0 || infant > 0))
      ) {
        state.SearchResultsStatus = "idle";
        state.ErrorOnresultPage = true;
        state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
        state.ErrorOnresultPageMessageDetailed =
          FlightsResultPageErrorMainDescription.TED;
        return;
      }

      // itr

      try {
        let itrSeparated = params["itr"].split("|");

        // for oneway
        if (params["tp"] === "ow") {
          if (params["itr"]?.length != 24) {
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.TED;

            return;
          }

          let extractedDateStr = itrSeparated[0].substring(8, 18);
          let [day, month, year] = extractedDateStr.split("-").map(Number);

          // Create a date object from the extracted date
          let extractedDate = new Date(year, month - 1, day); // Months are 0-based in JavaScript

          // Get today's date (without time)
          let today = new Date();
          today.setHours(0, 0, 0, 0);

          // Calculate the date one year from today
          let oneYearFromToday = new Date(today);
          oneYearFromToday.setFullYear(today.getFullYear() + 1);

          if (extractedDate < today || extractedDate > oneYearFromToday) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
        }

        // for roundway
        if (params["tp"] === "rw") {
          if (params["itr"]?.length != 49) {
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.TED;
            return;
          }

          // Check departure date
          let extractedDateStr = itrSeparated[0].substring(8, 18);
          let [day, month, year] = extractedDateStr.split("-").map(Number);

          // Create a date object from the extracted date
          let extractedDate = new Date(year, month - 1, day); // Months are 0-based in JavaScript

          // Get today's date (without time)
          let today = new Date();
          today.setHours(0, 0, 0, 0);

          // Calculate the date one year from today
          let oneYearFromToday = new Date(today);
          oneYearFromToday.setFullYear(today.getFullYear() + 1);

          if (extractedDate < today || extractedDate > oneYearFromToday) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }

          // check return date
          let extractedDateStr2 = itrSeparated[1].substring(8, 18);
          let [day2, month2, year2] = extractedDateStr2.split("-").map(Number);

          // Create a date object from the extracted date
          let extractedDate2 = new Date(year2, month2 - 1, day2); // Months are 0-based in JavaScript

          if (
            extractedDate2 < extractedDate ||
            extractedDate > oneYearFromToday
          ) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
        }

        // for multiway

        let hasDateError = false;
        if (params["tp"] === "mw") {
          let irtParams = params["itr"].split("|");

          irtParams.forEach((element) => {
            if (element?.length != 24) {
              state.SearchResultsStatus = "idle";
              state.ErrorOnresultPage = true;
              state.ErrorOnresultPageMessageType =
                FlightsResultPageErrorMain.DDNF;
              state.ErrorOnresultPageMessageDetailed =
                FlightsResultPageErrorMainDescription.DDNFD;
              return;
            }

            let extractedDateStr = element.substring(8, 18);
            let [day, month, year] = extractedDateStr.split("-").map(Number);

            // Create a date object from the extracted date
            let extractedDate = new Date(year, month - 1, day); // Months are 0-based in JavaScript

            // Get today's date (without time)
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            // Calculate the date one year from today
            let oneYearFromToday = new Date(today);
            oneYearFromToday.setFullYear(today.getFullYear() + 1);

            if (extractedDate < today || extractedDate > oneYearFromToday) {
              // Set the error state
              state.SearchResultsStatus = "idle";
              state.ErrorOnresultPage = true;
              state.ErrorOnresultPageMessageType =
                FlightsResultPageErrorMain.PEFD;
              state.ErrorOnresultPageMessageDetailed =
                FlightsResultPageErrorMainDescription.PEFDD;

              hasDateError = true;
              return;
            }
          });
        }

        if (hasDateError === true) {
          return;
        }
      } catch (e) {
        state.SearchResultsStatus = "idle";
        state.ErrorOnresultPage = true;
        state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.DDNF;
        state.ErrorOnresultPageMessageDetailed =
          FlightsResultPageErrorMainDescription.DDNFD;
        return;
      }

      // Set the params and other important stuff

      state.SearchResultType = getTheResultType(params["itr"].split("|"));
      state.itrCode = params["itr"];
      // get the theme type
      if (params["frc"] === "Business" || params["frc"] === "First") {
        state.PageTheme = 2;
      }

      // set the Search Params for Search bar

      let splitTheItr = params["itr"].split("|");
      console.log("splitTheItr is : ", splitTheItr);
      let AllAvaiableAirprotsData = [
        ...getLocalStorageJSON("fts-hm-ta"),
        ...getLocalStorageJSON("fts-hm-fa"),
      ];

      if (params["tp"] === "ow") {
        state.searchBarMode = 1;

        let OriginData = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[0].substring(0, 3);
        })[0];

        let DestinyData = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[0].substring(4, 7);
        })[0];

        state.searchParams[0].OriginCity = OriginData.city_name;
        state.searchParams[0].DestinationCity = DestinyData.city_name;
        state.searchParams[0].OriginCode = OriginData.airport_code;
        state.searchParams[0].DestinyCode = DestinyData.airport_code;
        state.searchParams[0].Origin = OriginData.airport_name;
        state.searchParams[0].Destiny = DestinyData.airport_name;
        state.searchParams[0].OriginCountryCode = OriginData.country_code;
        state.searchParams[0].DestinationCountryCode = DestinyData.country_code;
        state.searchParams[0].OriginCountry = OriginData.country_name;
        state.searchParams[0].DestinationCountry = DestinyData.country_name;
        state.searchParams[0].OriginDate = formatDateToSeatchParams(
          splitTheItr[0].substring(8, 18)
        ); // convert date to this format;
        state.searchParams[0].ReturnDate = formatDateToSeatchParams(
          splitTheItr[0].substring(8, 18)
        ); // convert date to this format;
      } else if (params["tp"] === "rw") {
        state.searchBarMode = 2;

        let OriginData = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[0].substring(0, 3);
        })[0];

        let DestinyData = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[0].substring(4, 7);
        })[0];

        state.searchParams[0].OriginCity = OriginData.city_name;
        state.searchParams[0].DestinationCity = DestinyData.city_name;
        state.searchParams[0].OriginCode = OriginData.airport_code;
        state.searchParams[0].DestinyCode = DestinyData.airport_code;
        state.searchParams[0].Origin = OriginData.airport_name;
        state.searchParams[0].Destiny = DestinyData.airport_name;
        state.searchParams[0].OriginCountryCode = OriginData.country_code;
        state.searchParams[0].DestinationCountryCode = DestinyData.country_code;
        state.searchParams[0].OriginCountry = OriginData.country_name;
        state.searchParams[0].DestinationCountry = DestinyData.country_name;
        state.searchParams[0].OriginDate = formatDateToSeatchParams(
          splitTheItr[0].substring(8, 18)
        ); // convert date to this format;
        state.searchParams[0].ReturnDate = formatDateToSeatchParams(
          splitTheItr[1].substring(8, 18)
        ); // convert date to this format;
      } else if (params["tp"] === "mw") {
        state.searchBarMode = 3;

        let rowLength = params["itr"].split("|")?.length;

        if (rowLength === 2) {
          let date1 = splitTheItr[0].substring(8, 18);
          let date2 = splitTheItr[1].substring(8, 18);

          let extractedDateStr1 = date1.substring(8, 18);
          let [day1, month1, year1] = extractedDateStr1.split("-").map(Number);

          let extractedDateStr2 = date2.substring(8, 18);
          let [day2, month2, year2] = extractedDateStr2.split("-").map(Number);

          // Create a date object from the extracted date
          let extractedDateToCompare1 = new Date(year1, month1 - 1, day1); // Months are 0-based in JavaScript
          let extractedDateToCompare2 = new Date(year2, month2 - 1, day2); // Months are 0-based in JavaScript

          if (extractedDateToCompare2 < extractedDateToCompare1) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
        } else if (rowLength === 3) {
          let date1 = splitTheItr[0].substring(8, 18);
          let date2 = splitTheItr[1].substring(8, 18);
          let date3 = splitTheItr[2].substring(8, 18);

          let extractedDateStr1 = date1.substring(8, 18);
          let [day1, month1, year1] = extractedDateStr1.split("-").map(Number);

          let extractedDateStr2 = date2.substring(8, 18);
          let [day2, month2, year2] = extractedDateStr2.split("-").map(Number);

          let extractedDateStr3 = date3.substring(8, 18);
          let [day3, month3, year3] = extractedDateStr3.split("-").map(Number);

          // Create a date object from the extracted date
          let extractedDateToCompare1 = new Date(year1, month1 - 1, day1); // Months are 0-based in JavaScript
          let extractedDateToCompare2 = new Date(year2, month2 - 1, day2); // Months are 0-based in JavaScript
          let extractedDateToCompare3 = new Date(year3, month3 - 1, day3); // Months are 0-based in JavaScript

          if (extractedDateToCompare2 < extractedDateToCompare1) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
          if (extractedDateToCompare3 < extractedDateToCompare2) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
        } else if (rowLength === 4) {
          let date1 = splitTheItr[0].substring(8, 18);
          let date2 = splitTheItr[1].substring(8, 18);
          let date3 = splitTheItr[2].substring(8, 18);
          let date4 = splitTheItr[3].substring(8, 18);

          let extractedDateStr1 = date1.substring(8, 18);
          let [day1, month1, year1] = extractedDateStr1.split("-").map(Number);

          let extractedDateStr2 = date2.substring(8, 18);
          let [day2, month2, year2] = extractedDateStr2.split("-").map(Number);

          let extractedDateStr3 = date3.substring(8, 18);
          let [day3, month3, year3] = extractedDateStr3.split("-").map(Number);

          let extractedDateStr4 = date4.substring(8, 18);
          let [day4, month4, year4] = extractedDateStr4.split("-").map(Number);

          // Create a date object from the extracted date
          let extractedDateToCompare1 = new Date(year1, month1 - 1, day1); // Months are 0-based in JavaScript
          let extractedDateToCompare2 = new Date(year2, month2 - 1, day2); // Months are 0-based in JavaScript
          let extractedDateToCompare3 = new Date(year3, month3 - 1, day3); // Months are 0-based in JavaScript
          let extractedDateToCompare4 = new Date(year4, month4 - 1, day4); // Months are 0-based in JavaScript

          if (extractedDateToCompare2 < extractedDateToCompare1) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
          if (extractedDateToCompare3 < extractedDateToCompare2) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
          if (extractedDateToCompare4 < extractedDateToCompare3) {
            // Set the error state
            state.SearchResultsStatus = "idle";
            state.ErrorOnresultPage = true;
            state.ErrorOnresultPageMessageType =
              FlightsResultPageErrorMain.PEFD;
            state.ErrorOnresultPageMessageDetailed =
              FlightsResultPageErrorMainDescription.PEFDD;

            return;
          }
        }

        state.multiRowNumber = rowLength;

        let OriginData1 = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[0].substring(0, 3);
        })[0];

        let DestinyData1 = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[0].substring(4, 7);
        })[0];

        let OriginData2 = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[1].substring(0, 3);
        })[0];

        let DestinyData2 = AllAvaiableAirprotsData.filter((item) => {
          return item.airport_code === splitTheItr[1].substring(4, 7);
        })[0];

        state.searchParams[0].OriginCity = OriginData1.city_name;
        state.searchParams[0].DestinationCity = DestinyData1.city_name;
        state.searchParams[0].OriginCode = OriginData1.airport_code;
        state.searchParams[0].DestinyCode = DestinyData1.airport_code;
        state.searchParams[0].Origin = OriginData1.airport_name;
        state.searchParams[0].Destiny = DestinyData1.airport_name;
        state.searchParams[0].OriginCountryCode = OriginData1.country_code;
        state.searchParams[0].DestinationCountryCode =
          DestinyData1.country_code;
        state.searchParams[0].OriginCountry = OriginData1.country_name;
        state.searchParams[0].DestinationCountry = DestinyData1.country_name;
        state.searchParams[0].OriginDate = formatDateToSeatchParams(
          splitTheItr[0].substring(8, 18)
        ); // convert date to this format;
        state.searchParams[0].ReturnDate = null; // convert date to this format;

        state.searchParams[1].OriginCity = OriginData2.city_name;
        state.searchParams[1].DestinationCity = DestinyData2.city_name;
        state.searchParams[1].OriginCode = OriginData2.airport_code;
        state.searchParams[1].DestinyCode = DestinyData2.airport_code;
        state.searchParams[1].Origin = OriginData2.airport_name;
        state.searchParams[1].Destiny = DestinyData2.airport_name;
        state.searchParams[1].OriginCountryCode = OriginData2.country_code;
        state.searchParams[1].DestinationCountryCode =
          DestinyData2.country_code;
        state.searchParams[1].OriginCountry = OriginData2.country_name;
        state.searchParams[1].DestinationCountry = DestinyData2.country_name;
        state.searchParams[1].OriginDate = formatDateToSeatchParams(
          splitTheItr[1].substring(8, 18)
        ); // convert date to this format;
        state.searchParams[1].ReturnDate = null; // convert date to this format;

        if (rowLength === 3 || rowLength === 4) {
          let OriginData3 = AllAvaiableAirprotsData.filter((item) => {
            return item.airport_code === splitTheItr[2].substring(0, 3);
          })[0];

          let DestinyData3 = AllAvaiableAirprotsData.filter((item) => {
            return item.airport_code === splitTheItr[2].substring(4, 7);
          })[0];

          state.searchParams[2].OriginCity = OriginData3.city_name;
          state.searchParams[2].DestinationCity = DestinyData3.city_name;
          state.searchParams[2].OriginCode = OriginData3.airport_code;
          state.searchParams[2].DestinyCode = DestinyData3.airport_code;
          state.searchParams[2].Origin = OriginData3.airport_name;
          state.searchParams[2].Destiny = DestinyData3.airport_name;
          state.searchParams[2].OriginCountryCode = OriginData3.country_code;
          state.searchParams[2].DestinationCountryCode =
            DestinyData3.country_code;
          state.searchParams[2].OriginCountry = OriginData3.country_name;
          state.searchParams[2].DestinationCountry = DestinyData3.country_name;
          state.searchParams[2].OriginDate = formatDateToSeatchParams(
            splitTheItr[2].substring(8, 18)
          ); // convert date to this format;
          state.searchParams[2].ReturnDate = null; // convert date to this format;
        }

        if (rowLength === 4) {
          let OriginData4 = AllAvaiableAirprotsData.filter((item) => {
            return item.airport_code === splitTheItr[3].substring(0, 3);
          })[0];

          let DestinyData4 = AllAvaiableAirprotsData.filter((item) => {
            return item.airport_code === splitTheItr[3].substring(4, 7);
          })[0];

          state.searchParams[3].OriginCity = OriginData4.city_name;
          state.searchParams[3].DestinationCity = DestinyData4.city_name;
          state.searchParams[3].OriginCode = OriginData4.airport_code;
          state.searchParams[3].DestinyCode = DestinyData4.airport_code;
          state.searchParams[3].Origin = OriginData4.airport_name;
          state.searchParams[3].Destiny = DestinyData4.airport_name;
          state.searchParams[3].OriginCountryCode = OriginData4.country_code;
          state.searchParams[3].DestinationCountryCode =
            DestinyData4.country_code;
          state.searchParams[3].OriginCountry = OriginData4.country_name;
          state.searchParams[3].DestinationCountry = DestinyData4.country_name;
          state.searchParams[3].OriginDate = formatDateToSeatchParams(
            splitTheItr[3].substring(8, 18)
          ); // convert date to this format;
          state.searchParams[3].ReturnDate = null; // convert date to this format;
        }
      }

      // set the travellers for search bar
      state.searchParamsTravellers.Adults = adult;
      state.searchParamsTravellers.Childs = child;
      state.searchParamsTravellers.Infrants = infant;

      // set the fare type and class
      state.searchParamsFare.class = params["frc"];
      state.searchParamsFare.type = params["frt"];

      // Start the Search by setting the paraams

      state.searchkey = window.location.search;

      try {
        let Params = {
          Travellers: {
            Adults: adult,
            Childs: child,
            Infrants: infant,
          },
          User: {
            Country: {
              name: action.payload.name,
              code: action.payload.code,
              currency: action.payload.currency,
              dial_code: action.payload.dial_code,
            },
          },
          Fare: {
            class: params["frc"],
            type: params["frt"],
          },
          Segments: GenerateTheSegments(params["itr"], state.searchParams),
          searchKey: state.searchkey,
        };

        state.SearchParameters = Params;
        state.StartTheSearch = true;
      } catch (e) {
        state.SearchResultsStatus = "idle";
        state.ErrorOnresultPage = true;
        state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.DDNF;
        state.ErrorOnresultPageMessageDetailed =
          FlightsResultPageErrorMainDescription.DDNFD;
        return;
      }
    },
    setSearchBarMode: (state, action) => {
      state.disableSearch = false;
      state.searchBarMode = action.payload;
    },
    setMultiRowNumber: (state, action) => {
      state.disableSearch = false;
      state.multiRowNumber = action.payload;
    },
    setSearchClicked: (state, action) => {
      state.disableSearch = false;
      state.searchClicked = false;
    },
    setResultView: (state, action) => {
      state.ResultView = action.payload;
    },
    setbarModeSelected: (state, action) => {
      state.barModeSelected = action.payload;
    },
    setbarModeData: (state, action) => {
      state.barModeData[action.payload.mode] = action.payload.Data;
    },
    setbookLoading : (state, action) => {
      state.bookLoading = action.payload;
    },
    setbookLoadingError : (state, action) => {
      state.bookLoadingError = action.payload;
    },
    setTheFareType: (state, action) => {
      state.disableSearch = false;
      state.searchParamsFare.type = action.payload;

      const { searchParamsFare, searchParamsTravellers } = state;

      if (
        (searchParamsFare.type === "Student" ||
          searchParamsFare.type === "Senior Citizen") &&
        (searchParamsTravellers.Childs > 0 ||
          searchParamsTravellers.Infrants > 0)
      ) {
        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          TravellersFareMessage(searchParamsFare.type);
        state.searchParamsTravellers.TravellersAndClassesErrorOut = true;
        state.searchParamsTravellers.TravellersAndClassesErrorOutMessage =
          TravellersFareMessage(searchParamsFare.type);

        return;
      }

      state.searchParamsTravellers.TravellersAndClassesErrorIn = false;
      state.searchParamsTravellers.TravellersAndClassesErrorInMessage = "";
      state.searchParamsTravellers.TravellersAndClassesErrorOut = false;
      state.searchParamsTravellers.TravellersAndClassesErrorOutMessage = "";
    },
    setSearchSegmentsParams: (state, action) => {
      state.disableSearch = false;
      console.log("action.pauylaod is  : ", action.payload);
      // check if is of From or To
      if (action.payload.PopupType === 0) {
        // From
        state.searchParams[action.payload.type].OriginCity =
          action.payload.City;
        state.searchParams[action.payload.type].Origin =
          action.payload.AirportName;
        state.searchParams[action.payload.type].OriginCode =
          action.payload.Code;
        state.searchParams[action.payload.type].OriginCountryCode =
          action.payload.CountryCode;
        state.searchParams[action.payload.type].OriginCountry =
          action.payload.CountryName;

        // check if error there ,, update the error

        if (action.payload.type === 0) {
          if (state.AirportsFromError1[0] === true) {
            state.AirportsFromError1 = [false, ""];
          }
        }
        if (action.payload.type === 1) {
          if (state.AirportsFromError2[0] === true) {
            state.AirportsFromError2 = [false, ""];
          }
        }
        if (action.payload.type === 2) {
          if (state.AirportsFromError3[0] === true) {
            state.AirportsFromError3 = [false, ""];
          }
        }
        if (action.payload.type === 3) {
          if (state.AirportsFromError4[0] === true) {
            state.AirportsFromError4 = [false, ""];
          }
        }

        if (action.payload.type === 0) {
          if (
            action.payload.Code ==
            state.searchParams[action.payload.type].DestinyCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError1 = [false, ""];
          }
        }
        if (action.payload.type === 1) {
          if (
            action.payload.Code ==
            state.searchParams[action.payload.type].DestinyCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError2 = [false, ""];
          }
        }
        if (action.payload.type === 2) {
          if (
            action.payload.Code ==
            state.searchParams[action.payload.type].DestinyCode
          ) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError3 = [false, ""];
          }
        }
        if (action.payload.type === 3) {
          if (
            action.payload.Code ==
            state.searchParams[action.payload.type].DestinyCode
          ) {
            state.AirportsToError4 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError4 = [false, ""];
          }
        }

        // Save the from airports
        const fromAirports = getLocalStorageJSON("fts-hm-fa");

        if (fromAirports?.length > 0) {
          // Check if this airport already exists
          const existingIndex = fromAirports?.findIndex(
            (airport) => airport.airport_code === action.payload.Code
          );

          if (existingIndex !== -1) {
            // If it already exists, remove it
            fromAirports.splice(existingIndex, 1);
          }
        }

        // Push the new airport to the top of the list
        fromAirports.unshift(action.payload.airport);

        // Check if the length of fromAirports is greater than 5
        if (fromAirports?.length > 16) {
          // Remove the last element
          fromAirports.pop();
        }

        // Update the state with the modified fromAirports array
        state.fromAirports = fromAirports;

        setLocalStorageJSON("fts-hm-fa", state.fromAirports);
      } else {
        // To
        state.searchParams[action.payload.type].DestinationCity =
          action.payload.City;
        state.searchParams[action.payload.type].Destiny =
          action.payload.AirportName;
        state.searchParams[action.payload.type].DestinyCode =
          action.payload.Code;
        state.searchParams[action.payload.type].DestinationCountryCode =
          action.payload.CountryCode;
        state.searchParams[action.payload.type].DestinationCountry =
          action.payload.CountryName;

        // check if error there ,, update the error

        if (action.payload.type === 0) {
          if (state.AirportsToError1[0] === true) {
            state.AirportsToError1 = [false, ""];
          }
        }
        if (action.payload.type === 1) {
          if (state.AirportsToError2[0] === true) {
            state.AirportsToError2 = [false, ""];
          }
        }
        if (action.payload.type === 2) {
          if (state.AirportsToError3[0] === true) {
            state.AirportsToError3 = [false, ""];
          }
        }
        if (action.payload.type === 3) {
          if (state.AirportsToError4[0] === true) {
            state.AirportsToError4 = [false, ""];
          }
        }

        if (action.payload.type === 0) {
          if (
            action.payload.Code ==
            state.searchParams[action.payload.type].OriginCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError1 = [false, ""];
          }
        } else if (action.payload.type === 1) {
          if (
            action.payload.Code ==
            state.searchParams[action.payload.type].OriginCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError2 = [false, ""];
          }
        } else if (action.payload.type === 2) {
          if (
            action.payload.Code ==
            state.searchParams[action.payload.type].OriginCode
          ) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError3 = [false, ""];
          }
        } else if (action.payload.type === 3) {
          if (
            action.payload.Code == state.searchParams[action.payload.type].OriginCode
          ) {
            state.AirportsToError4 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError4 = [false, ""];
          }
        }

        if (action.payload.type === 0 && state.searchBarMode === 3) {
          state.searchParams[1].OriginCity = action.payload.City;
          state.searchParams[1].Origin = action.payload.AirportName;
          state.searchParams[1].OriginCode = action.payload.Code;
          state.searchParams[1].OriginCountryCode = action.payload.CountryCode;
          state.searchParams[1].OriginCountry = action.payload.CountryName;

          if (action.payload.Code === state.searchParams[1].DestinyCode) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError2 = [false, ""];
          }
        } else if (action.payload.type === 1) {
          state.searchParams[2].OriginCity = action.payload.City;
          state.searchParams[2].Origin = action.payload.AirportName;
          state.searchParams[2].OriginCode = action.payload.Code;
          state.searchParams[2].OriginCountryCode = action.payload.CountryCode;
          state.searchParams[2].OriginCountry = action.payload.CountryName;

          if (action.payload.Code === state.searchParams[2].DestinyCode) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError3 = [false, ""];
          }
        } else if (action.payload.type === 2) {
          state.searchParams[3].OriginCity = action.payload.City;
          state.searchParams[3].Origin = action.payload.AirportName;
          state.searchParams[3].OriginCode = action.payload.Code;
          state.searchParams[3].OriginCountryCode = action.payload.CountryCode;
          state.searchParams[3].OriginCountry = action.payload.CountryName;

          if (action.payload.Code === state.searchParams[3].DestinyCode) {
            state.AirportsToError4 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError4 = [false, ""];
          }
        }

        // save the from airports

        // Save the from airports
        const toAirports = getLocalStorageJSON("fts-hm-ta");

        if (toAirports?.length > 0) {
          // Check if this airport already exists
          const existingIndex = toAirports?.findIndex(
            (airport) => airport.airport_code === action.payload.Code
          );

          if (existingIndex !== -1) {
            // If it already exists, remove it
            toAirports.splice(existingIndex, 1);
          }
        }

        // Push the new airport to the top of the list
        toAirports.unshift(action.payload.airport);

        // Check if the length of fromAirports is greater than 5
        if (toAirports?.length > 16) {
          // Remove the last element
          toAirports.pop();
        }

        // Update the state with the modified toAirports array
        state.toAirports = toAirports;

        setLocalStorageJSON("fts-hm-ta", state.toAirports);
      }
    },
    setTheDates: (state, action) => {
      state.disableSearch = false;
      for (let i = 0; i < state.searchParams?.length; i++) {
        state.searchParams[i].OriginDate = action.payload[i].OriginDate;
        state.searchParams[i].ReturnDate = action.payload[i].ReturnDate;
      }
    },
    setTravellersAndClasses: (state, action) => {
      state.disableSearch = false;
      let { adults, childs, infants, classs } = action.payload;
      const { searchParamsFare, searchParamsTravellers } = state;

      if (adults + childs > 9) {
        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          SearchBarErrorMessages.TravellersPopup[0];

        return;
      } else if (adults < infants) {
        infants = adults;
        state.searchParamsTravellers.Adults = adults;
        state.searchParamsTravellers.Infrants = infants;

        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          SearchBarErrorMessages.TravellersPopup[1];

        return;
      }

      if (
        (searchParamsFare.type === "Student" ||
          searchParamsFare.type === "Senior Citizen") &&
        (childs > 0 || infants > 0)
      ) {
        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          TravellersFareMessage(searchParamsFare.type);
        state.searchParamsTravellers.TravellersAndClassesErrorOut = true;
        state.searchParamsTravellers.TravellersAndClassesErrorOutMessage =
          TravellersFareMessage(searchParamsFare.type);

        return;
      }

      state.searchParamsTravellers.Adults = adults;
      state.searchParamsTravellers.Childs = childs;
      state.searchParamsTravellers.Infrants = infants;
      state.searchParamsFare.class = classs;

      state.searchParamsTravellers.TravellersAndClassesErrorIn = false;
      state.searchParamsTravellers.TravellersAndClassesErrorInMessage = "";
      state.searchParamsTravellers.TravellersAndClassesErrorOut = false;
      state.searchParamsTravellers.TravellersAndClassesErrorOutMessage = "";
    },
    setTravellersErrors: (state, action) => {
      state.searchParamsTravellers.TravellersAndClassesErrorIn = false;
      state.searchParamsTravellers.TravellersAndClassesErrorInMessage = "";
      state.searchParamsTravellers.TravellersAndClassesErrorOut = false;
      state.searchParamsTravellers.TravellersAndClassesErrorOutMessage = "";
    },
    resetTheErrors: (state, action) => {
      state.searchParamsTravellers.TravellersAndClassesErrorIn = false;
      state.searchParamsTravellers.TravellersAndClassesErrorInMessage = "";
      state.searchParamsTravellers.TravellersAndClassesErrorOut = false;
      state.searchParamsTravellers.TravellersAndClassesErrorOutMessage = "";

      state.AirportsToError1 = [false, ""];
      state.AirportsToError2 = [false, ""];
      state.AirportsToError3 = [false, ""];
      state.AirportsToError4 = [false, ""];

      state.AirportsFromError1 = [false, ""];
      state.AirportsFromError2 = [false, ""];
      state.AirportsFromError3 = [false, ""];
      state.AirportsFromError4 = [false, ""];

      state.DateError1 = [false, ""];
      state.DateError11 = [false, ""];
      state.DateError2 = [false, ""];
      state.DateError3 = [false, ""];
      state.DateError4 = [false, ""];
    },
    setExchangeSearchParas: (state, action) => {
      if (
        state.searchParams[action.payload].OriginCode === "" ||
        state.searchParams[action.payload].DestinyCode === ""
      ) {
        return;
      }

      state.disableSearch = false;

      let previousOriginCityData = {
        City: state.searchParams[action.payload].OriginCity,
        AirportName: state.searchParams[action.payload].Origin,
        Code: state.searchParams[action.payload].OriginCode,
        CountryCode: state.searchParams[action.payload].OriginCountryCode,
        CountryName: state.searchParams[action.payload].OriginCountry,
      };

      let previousDestinyCityData = {
        City: state.searchParams[action.payload].DestinationCity,
        AirportName: state.searchParams[action.payload].Destiny,
        Code: state.searchParams[action.payload].DestinyCode,
        CountryCode: state.searchParams[action.payload].DestinationCountryCode,
        CountryName: state.searchParams[action.payload].DestinationCountry,
      };

      state.searchParams[action.payload].OriginCity =
        previousDestinyCityData.City;
      state.searchParams[action.payload].Origin =
        previousDestinyCityData.AirportName;
      state.searchParams[action.payload].OriginCode =
        previousDestinyCityData.Code;
      state.searchParams[action.payload].OriginCountryCode =
        previousDestinyCityData.CountryCode;
      state.searchParams[action.payload].OriginCountry =
        previousDestinyCityData.CountryName;

      state.searchParams[action.payload].DestinationCity =
        previousOriginCityData.City;
      state.searchParams[action.payload].Destiny =
        previousOriginCityData.AirportName;
      state.searchParams[action.payload].DestinyCode =
        previousOriginCityData.Code;
      state.searchParams[action.payload].DestinationCountryCode =
        previousOriginCityData.CountryCode;
      state.searchParams[action.payload].DestinationCountry =
        previousOriginCityData.CountryName;

      if (action.payload === 0) {
        // save the searcvh params

        if (state.searchBarMode === 3) {
          if (
            state.searchParams[1].OriginCode ==
            state.searchParams[1].DestinyCode
          ) {
            state.AirportsToError2 = true;
          } else {
            state.AirportsToError2 = false;
          }
        }
      }
      if (action.payload === 1) {
        // save the searcvh params

        if (
          state.searchParams[2].OriginCode === state.searchParams[2].DestinyCode
        ) {
          state.AirportsToError3 = true;
        } else {
          state.AirportsToError3 = false;
        }
      }
      if (action.payload === 2) {
        // save the searcvh params

        if (
          state.searchParams[3].OriginCode === state.searchParams[3].DestinyCode
        ) {
          state.AirportsToError4 = true;
        } else {
          state.AirportsToError4 = false;
        }
      }
      if (action.payload === 3) {
      }
    },
    searchTheFlightUrlCreator: (state, action) => {
      // Step 1 : Check if There any Error

      // const { navigate } = action.payload;

      // For Oneway and Round
      if (state.searchBarMode === 1 || state.searchBarMode === 2) {
        let sholdReturn = 0;
        if (state.searchParams[0].OriginCode === "") {
          state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
          sholdReturn++;
        }
        if (state.searchParams[0].DestinyCode === "") {
          state.AirportsToError1 = [true, SearchBarErrorMessages.To];
          sholdReturn++;
        }

        state.searchClicked = true;
        if (sholdReturn > 0) {
          return;
        }
      }

      if (state.searchBarMode === 1 || state.searchBarMode === 2) {
        let sholdReturn = 0;

        if (
          state.searchParams[0].OriginCode === state.searchParams[0].DestinyCode
        ) {
          state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
          sholdReturn++;
        }

        state.searchClicked = true;
        if (sholdReturn > 0) {
          return;
        }
      }

      if (state.searchBarMode === 1) {
        let sholdReturn = 0;

        if (state.searchParams[0].OriginDate === null) {
          state.DateError1 = [true, FlightsResultPageErrorMain.IVD];
          sholdReturn++;
        }

        state.searchClicked = true;
        if (sholdReturn > 0) {
          return;
        }
      }

      if (state.searchBarMode === 2) {
        let sholdReturn = 0;

        if (state.searchParams[0].OriginDate === null) {
          state.DateError1 = [true, FlightsResultPageErrorMain.IVD];
          sholdReturn++;
        }

        if (state.searchParams[0].ReturnDate === null) {
          state.DateError11 = [true, FlightsResultPageErrorMain.IVD];
          sholdReturn++;
        }

        state.searchClicked = true;
        if (sholdReturn > 0) {
          return;
        }
      }

      // For Multiway
      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 1) {
          let sholdReturn = 0;
          if (state.searchParams[0].OriginCode === "") {
            state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[0].DestinyCode === "") {
            state.AirportsToError1 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        }

        if (state.multiRowNumber === 2) {
          let sholdReturn = 0;
          if (state.searchParams[0].OriginCode === "") {
            state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[0].DestinyCode === "") {
            state.AirportsToError1 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams[1].OriginCode === "") {
            state.AirportsFromError2 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[1].DestinyCode === "") {
            state.AirportsToError2 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 3) {
          let sholdReturn = 0;
          if (state.searchParams[0].OriginCode === "") {
            state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[0].DestinyCode === "") {
            state.AirportsToError1 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams[1].OriginCode === "") {
            state.AirportsFromError2 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[1].DestinyCode === "") {
            state.AirportsToError2 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams[2].OriginCode === "") {
            state.AirportsFromError3 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[2].DestinyCode === "") {
            state.AirportsToError3 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 4) {
          let sholdReturn = 0;
          if (state.searchParams[0].OriginCode === "") {
            state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[0].DestinyCode === "") {
            state.AirportsToError1 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams[1].OriginCode === "") {
            state.AirportsFromError2 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[1].DestinyCode === "") {
            state.AirportsToError2 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams[2].OriginCode === "") {
            state.AirportsFromError3 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[2].DestinyCode === "") {
            state.AirportsToError3 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams[3].OriginCode === "") {
            state.AirportsFromError4 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams[3].DestinyCode === "") {
            state.AirportsToError4 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        }
      }

      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 1) {
          let sholdReturn = 0;

          if (
            state.searchParams[0].OriginCode ==
            state.searchParams[0].DestinyCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        }

        if (state.multiRowNumber === 2) {
          let sholdReturn = 0;

          if (
            state.searchParams[0].OriginCode ==
            state.searchParams[0].DestinyCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          if (
            state.searchParams[1].OriginCode ==
            state.searchParams[1].DestinyCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 3) {
          let sholdReturn = 0;

          if (
            state.searchParams[0].OriginCode ==
            state.searchParams[0].DestinyCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          if (
            state.searchParams[1].OriginCode ==
            state.searchParams[1].DestinyCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          if (
            state.searchParams[2].OriginCode ==
            state.searchParams[2].DestinyCode
          ) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 4) {
          let sholdReturn = 0;

          if (
            state.searchParams[0].OriginCode ==
            state.searchParams[0].DestinyCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          if (
            state.searchParams[1].OriginCode ==
            state.searchParams[1].DestinyCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          if (
            state.searchParams[2].OriginCode ==
            state.searchParams[2].DestinyCode
          ) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          if (
            state.searchParams[3].OriginCode ==
            state.searchParams[3].DestinyCode
          ) {
            state.AirportsToError4 = [true, SearchBarErrorMessages.From_To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        }
      }

      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 1) {
          let sholdReturn = 0;

          if (state.searchParams[0].OriginDate === null) {
            state.DateError1 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        }

        if (state.multiRowNumber === 2) {
          let sholdReturn = 0;

          if (state.searchParams[0].OriginDate === null) {
            state.DateError1 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          if (state.searchParams[1].OriginDate === null) {
            state.DateError2 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 3) {
          let sholdReturn = 0;

          if (state.searchParams[0].OriginDate === null) {
            state.DateError1 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          if (state.searchParams[1].OriginDate === null) {
            state.DateError2 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          if (state.searchParams[2].OriginDate === null) {
            state.DateError3 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 4) {
          let sholdReturn = 0;
          if (state.searchParams[0].OriginDate === null) {
            state.DateError1 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          if (state.searchParams[1].OriginDate === null) {
            state.DateError2 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          if (state.searchParams[2].OriginDate === null) {
            state.DateError3 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          if (state.searchParams[3].OriginDate === null) {
            state.DateError4 = [true, FlightsResultPageErrorMain.IVD];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        }
      }

      // For Oneway and Round
      if (state.searchBarMode === 1 || state.searchBarMode === 2) {
        if (
          state.AirportsToError1[0] === true ||
          state.AirportsToError2[0] === true ||
          state.AirportsToError3[0] === true ||
          state.AirportsToError4[0] === true ||
          state.AirportsFromError1[0] === true ||
          state.AirportsFromError2[0] === true ||
          state.AirportsFromError3[0] === true ||
          state.AirportsFromError4[0] === true ||
          state.searchParamsTravellers.TravellersAndClassesErrorIn === true ||
          state.searchParamsTravellers.TravellersAndClassesErrorOut === true
        ) {
          state.searchClicked = true;
          return;
        }
      }

      // For Multiway
      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 1) {
          if (
            state.AirportsToError1[0] === true ||
            state.AirportsFromError1[0] === true ||
            state.searchParamsTravellers.TravellersAndClassesErrorOut ===
              true ||
            state.searchParamsTravellers.TravellersAndClassesErrorIn === true
          ) {
            state.searchClicked = true;
            return;
          }
        } else if (state.multiRowNumber === 2) {
          if (
            state.AirportsToError1[0] === true ||
            state.AirportsToError2[0] === true ||
            state.AirportsFromError1[0] === true ||
            state.AirportsFromError2[0] === true ||
            state.searchParamsTravellers.TravellersAndClassesErrorOut ===
              true ||
            state.searchParamsTravellers.TravellersAndClassesErrorIn === true
          ) {
            state.searchClicked = true;
            return;
          }
        } else if (state.multiRowNumber === 3) {
          if (
            state.AirportsToError1[0] === true ||
            state.AirportsToError2[0] === true ||
            state.AirportsToError3[0] === true ||
            state.AirportsFromError1[0] === true ||
            state.AirportsFromError2[0] === true ||
            state.AirportsFromError3[0] === true ||
            state.searchParamsTravellers.TravellersAndClassesErrorOut ===
              true ||
            state.searchParamsTravellers.TravellersAndClassesErrorIn === true
          ) {
            state.searchClicked = true;
            return;
          }
        } else if (state.multiRowNumber === 4) {
          if (
            state.AirportsToError1[0] === true ||
            state.AirportsToError2[0] === true ||
            state.AirportsToError3[0] === true ||
            state.AirportsToError4[0] === true ||
            state.AirportsFromError1[0] === true ||
            state.AirportsFromError2[0] === true ||
            state.AirportsFromError3[0] === true ||
            state.AirportsFromError4[0] === true ||
            state.searchParamsTravellers.TravellersAndClassesErrorOut ===
              true ||
            state.searchParamsTravellers.TravellersAndClassesErrorIn === true
          ) {
            state.searchClicked = true;
            return;
          }
        }
      }

      // Step 2 : Create The Url accoridng to flight

      // For Oneway
      if (state.searchBarMode === 1) {
        let url = `tp=ow&a=${state.searchParamsTravellers.Adults}&c=${
          state.searchParamsTravellers.Childs
        }&i=${state.searchParamsTravellers.Infrants}&frc=${
          state.searchParamsFare.class
        }&frt=${state.searchParamsFare.type}&itr=${
          state.searchParams[0].OriginCode
        }-${state.searchParams[0].DestinyCode}-${formatDateToSeatchParams(
          state.searchParams[0].OriginDate
        )}-${state.searchParams[0].OriginCountryCode}-${
          state.searchParams[0].DestinationCountryCode
        }`;

        window.location.replace(`/flights/results?${url}`);
      }
      // navigate(`/flights/results?${url}`);

      // For Roundway
      if (state.searchBarMode === 2) {
        let url = `tp=rw&a=${state.searchParamsTravellers.Adults}&c=${
          state.searchParamsTravellers.Childs
        }&i=${state.searchParamsTravellers.Infrants}&frc=${
          state.searchParamsFare.class
        }&frt=${state.searchParamsFare.type}&itr=${
          state.searchParams[0].OriginCode
        }-${state.searchParams[0].DestinyCode}-${formatDateToSeatchParams(
          state.searchParams[0].OriginDate
        )}-${state.searchParams[0].OriginCountryCode}-${
          state.searchParams[0].DestinationCountryCode
        }|${state.searchParams[0].DestinyCode}-${
          state.searchParams[0].OriginCode
        }-${formatDateToSeatchParams(state.searchParams[0].ReturnDate)}-${
          state.searchParams[0].DestinationCountryCode
        }-${state.searchParams[0].OriginCountryCode}`;

        window.location.replace(`/flights/results?${url}`);
      }

      // For Multiway
      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 1) {
          let url = `tp=ow&a=${state.searchParamsTravellers.Adults}&c=${
            state.searchParamsTravellers.Childs
          }&i=${state.searchParamsTravellers.Infrants}&frc=${
            state.searchParamsFare.class
          }&frt=${state.searchParamsFare.type}&itr=${
            state.searchParams[0].OriginCode
          }-${state.searchParams[0].DestinyCode}-${formatDateToSeatchParams(
            state.searchParams[0].OriginDate
          )}-${state.searchParams[0].OriginCountryCode}-${
            state.searchParams[0].DestinationCountryCode
          }`;

          window.location.replace(`/flights/results?${url}`);
        } else if (state.multiRowNumber === 2) {
          let url = `tp=${
            state.searchParams[0].OriginCode ==
            state.searchParams[1].DestinyCode
              ? "rw"
              : "mw"
          }&a=${state.searchParamsTravellers.Adults}&c=${
            state.searchParamsTravellers.Childs
          }&i=${state.searchParamsTravellers.Infrants}&frc=${
            state.searchParamsFare.class
          }&frt=${state.searchParamsFare.type}&itr=${
            state.searchParams[0].OriginCode
          }-${state.searchParams[0].DestinyCode}-${formatDateToSeatchParams(
            state.searchParams[0].OriginDate
          )}-${state.searchParams[0].OriginCountryCode}-${
            state.searchParams[0].DestinationCountryCode
          }|${state.searchParams[1].OriginCode}-${
            state.searchParams[1].DestinyCode
          }-${formatDateToSeatchParams(state.searchParams[1].OriginDate)}-${
            state.searchParams[1].OriginCountryCode
          }-${state.searchParams[1].DestinationCountryCode}`;

          window.location.replace(`/flights/results?${url}`);
        } else if (state.multiRowNumber === 3) {
          let url = `tp=mw&a=${state.searchParamsTravellers.Adults}&c=${
            state.searchParamsTravellers.Childs
          }&i=${state.searchParamsTravellers.Infrants}&frc=${
            state.searchParamsFare.class
          }&frt=${state.searchParamsFare.type}&itr=${
            state.searchParams[0].OriginCode
          }-${state.searchParams[0].DestinyCode}-${formatDateToSeatchParams(
            state.searchParams[0].OriginDate
          )}-${state.searchParams[0].OriginCountryCode}-${
            state.searchParams[0].DestinationCountryCode
          }|${state.searchParams[1].OriginCode}-${
            state.searchParams[1].DestinyCode
          }-${formatDateToSeatchParams(state.searchParams[1].OriginDate)}-${
            state.searchParams[1].OriginCountryCode
          }-${state.searchParams[1].DestinationCountryCode}|${
            state.searchParams[2].OriginCode
          }-${state.searchParams[2].DestinyCode}-${formatDateToSeatchParams(
            state.searchParams[2].OriginDate
          )}-${state.searchParams[2].OriginCountryCode}-${
            state.searchParams[2].DestinationCountryCode
          }`;

          window.location.replace(`/flights/results?${url}`);
        } else if (state.multiRowNumber === 4) {
          let url = `tp=mw&a=${state.searchParamsTravellers.Adults}&c=${
            state.searchParamsTravellers.Childs
          }&i=${state.searchParamsTravellers.Infrants}&frc=${
            state.searchParamsFare.class
          }&frt=${state.searchParamsFare.type}&itr=${
            state.searchParams[0].OriginCode
          }-${state.searchParams[0].DestinyCode}-${formatDateToSeatchParams(
            state.searchParams[0].OriginDate
          )}-${state.searchParams[0].OriginCountryCode}-${
            state.searchParams[0].DestinationCountryCode
          }|${state.searchParams[1].OriginCode}-${
            state.searchParams[1].DestinyCode
          }-${formatDateToSeatchParams(state.searchParams[1].OriginDate)}-${
            state.searchParams[1].OriginCountryCode
          }-${state.searchParams[1].DestinationCountryCode}|${
            state.searchParams[2].OriginCode
          }-${state.searchParams[2].DestinyCode}-${formatDateToSeatchParams(
            state.searchParams[2].OriginDate
          )}-${state.searchParams[2].OriginCountryCode}-${
            state.searchParams[2].DestinationCountryCode
          }|${state.searchParams[3].OriginCode}-${
            state.searchParams[3].DestinyCode
          }-${formatDateToSeatchParams(state.searchParams[3].OriginDate)}-${
            state.searchParams[3].OriginCountryCode
          }-${state.searchParams[3].DestinationCountryCode}`;

          window.location.replace(`/flights/results?${url}`);
        }
      }
    },
    setFiltersFinalUpdate: (state, action) => {
      state.filtersGenerated = action.payload;
      state.filtersLoading = false;
    },
    SearchTheFligthFromCalendarBar: (state, action) => {
      if (state.searchBarMode === 1) {
        let url = `tp=ow&a=${state.SearchParameters.Travellers.Adults}&c=${state.SearchParameters.Travellers.Childs}&i=${state.SearchParameters.Travellers.Infrants}&frc=${state.SearchParameters.Fare.class}&frt=${state.SearchParameters.Fare.type}&itr=${state.SearchParameters.Segments[0].Origin}-${state.SearchParameters.Segments[0].Destination}-${action.payload}-${state.SearchParameters.Segments[0].OriginCountryCode}-${state.SearchParameters.Segments[0].DestinationCountryCode}`;

        window.location.replace(`/flights/results?${url}`);
      }
    },
    setFilteredData: (state, action) => {
      state.FilteredData = action.payload;
    },
    setFiltersAppliedManual: (state, action) => {
      state.FiltersApplied = action.payload;
    },
    setbarModeDataInitial: (state, action) => {
      state.barModeData = action.payload;
    },
    setopenMainPopup: (state, action) => {
      state.openMainPopup = action.payload;
    },
    setdataMainPopup: (state, action) => {
      state.dataMainPopup = action.payload;
    },
    setResultTypeMainPopup: (state, action) => {
      state.ResultTypeMainPopup = action.payload;
    },
    setFFSelectedMain: (state, action) => {
      state.FFSelected = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(SearchTheFlights.pending, (state) => {
        state.SearchResultsStatus = "Loading";
      })
      .addCase(SearchTheFlights.fulfilled, (state, action) => {
        // SearchResultType

        state.StartTheSearch = false;
        state.SearchResultsStatus = "idle";

        if (action.payload?.["Data Available"] === false) {
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.FNA;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription.FNAD;
        }
        state.SearchResults = action.payload;
        state.FullDataInitial = action.payload.Data;
        state.FilteredData = action.payload.Data;
      })
      .addCase(SearchTheFlights.rejected, (state, action) => {
        state.SearchResultsStatus = "idle";
        state.SearchResults = null;

        if (
          action.error.code === "ERR_NETWORK" ||
          action.error.name === "TypeError"
        ) {
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.IC;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription.ICD;
          state.popularCitiesLoading = false;

          return;
        } else if (action.payload.errorcode === "SERVER DOWN") {
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain?.SRD;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription?.SRDD;
          state.popularCitiesLoading = false;
          return;
        } else if (action.payload.errorcode === "ERR_BAD_REQUEST") {
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType =
            FlightsResultPageErrorMain?.[action.payload.dd.code];
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription?.[
              action.payload.dd.code + "D"
            ];
          state.popularCitiesLoading = false;
          return;
        } else if (action.payload.errorcode === "ERR_BAD_RESPONSE") {
          state.ErrorOnresultPage = true;
          state.ErrorOnresultPageMessageType = FlightsResultPageErrorMain.TE;
          state.ErrorOnresultPageMessageDetailed =
            FlightsResultPageErrorMainDescription.TED;
          return;
        }
      });
  },
});

export const {
  getUrlParams,
  setSearchBarMode,
  setResultView,
  setMultiRowNumber,
  setSearchClicked,
  resetTheErrors,
  setTheFareType,
  setSearchSegmentsParams,
  setTheDates,
  setTravellersAndClasses,
  setTravellersErrors,
  setExchangeSearchParas,
  searchTheFlightUrlCreator,

  setFiltersFinalUpdate,
  setbarModeSelected,

  SearchTheFligthFromCalendarBar,
  setbarModeData,
  setFilteredData,

  setFiltersAppliedManual,

  setbarModeDataInitial,
  setPopupMode,
  setPopupTopBarMode,
  setopenMainPopup,
  setdataMainPopup,
  setResultTypeMainPopup,
  setFFSelectedMain,

  setbookLoading,
  setbookLoadingError
} = ResultPageFlightsFlightSlice.actions;

export default ResultPageFlightsFlightSlice.reducer;

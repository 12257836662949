import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import StatusCounter from "../ReviewSection/Components/StatusCounter/StatusCounter";
import OnewayData from "../ReviewSection/OnewayData/index";
import FareDetailsSB from "../ReviewSection/Components/FareDetailsSB/FareDetailsSB";
import CouponSection from "../ReviewSection/Components/CouponSection/CouponSection";
import {
  Call_Continue3,
  CallTheAncellaries,
  InitializePopup,
  removeTheAncellaries,
  SellTheFlight,
  setairlineNameFormatAccepted,
  setAncellariesApiData,
  setAncellariesDownloaded,
  setAncellariesLoading,
  setcontinueButtonError,
  setOpenPopupData,
  setOpenPopupMini,
  setOpenPopupMiniType,
  setstartButtonShaking,
  SetTheTravellersInitial,
} from "../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";
import {
  createCouponsFlight,
  getNameFormatMessage,
} from "../../../../Utils/Flight_review_func";
import RoundwayDom from "../ReviewSection/RoundwayData_Domestic";
import RoundwayINTSplit from "../ReviewSection/Round_International/Split";
import RoundwayINTCombine from "../ReviewSection/Round_International/Combined";
import MultiwayDom2 from "../ReviewSection/MultiDomestic/2Row";
import MultiwayDom3 from "../ReviewSection/MultiDomestic/3Row";
import MultiwayDom4 from "../ReviewSection/MultiDomestic/4Row";
import MultiwayINTSplit2 from "../ReviewSection/MultiInternational/2Row/Split";
import MultiwayINTCombine2 from "../ReviewSection/MultiInternational/2Row/Combined";
import MultiwayINTSplit3 from "../ReviewSection/MultiInternational/3Row/Split";
import MultiwayINTCombine3 from "../ReviewSection/MultiInternational/3Row/Combined";
import MultiwayINTSplit4 from "../ReviewSection/MultiInternational/4Row/Split";
import MultiwayINTCombine4 from "../ReviewSection/MultiInternational/4Row/Combined";
import TravellersSection from "../TravellersSection/TravellersSection";
import AddonsSection from "../AddonsSection/AddonsSection";

function MainReviewPage({ Theme }) {
  
  let reviewPageData = useSelector((state) => state.ReviewPageFlight);

  console.log("reviewPageData is : ", reviewPageData);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(InitializePopup());
    dispatch(SetTheTravellersInitial());
  }, [dispatch]);

  const handleContinue = (callsell) => {
    dispatch(setOpenPopupMini(false));
    dispatch(setOpenPopupMiniType(""));
    dispatch(setOpenPopupData({}));

    if (callsell == true) {
      dispatch(SellTheFlight()); /// to fetch the latest and new flight details
    }
  };

  const handleContinue2 = (callsell) => {
    dispatch(setOpenPopupMini(false));
    dispatch(setOpenPopupMiniType(""));
    dispatch(setOpenPopupData({}));

    if (callsell == true) {
      dispatch(SellTheFlight()); /// to fetch the latest and new flight details
    }
    dispatch(setAncellariesApiData({ Message: null }));
    dispatch(setAncellariesDownloaded(false));
    dispatch(setAncellariesLoading(true));

    dispatch(CallTheAncellaries());
  };

  const handleGoBack = () => {
    window.location.replace(`/flights/results${reviewPageData.searchKey}`);
  };

  let startButtonShaking = useSelector(
    (state) => state.ReviewPageFlight.startButtonShaking
  );

  useEffect(() => {
    let timeoutId;

    if (startButtonShaking === true) {
      // Set a timeout to dispatch the action after 2 seconds
      timeoutId = setTimeout(() => {
        dispatch(setstartButtonShaking(false));
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [startButtonShaking]);

  let handleContinueNameFormat = () => {
    dispatch(setairlineNameFormatAccepted(true));
    dispatch(setOpenPopupMiniType(""));
    dispatch(setOpenPopupMini(false));
  };

  let handleGoBackNameFormat = () => {
    dispatch(setOpenPopupMiniType(""));
    dispatch(setOpenPopupMini(false));
  };

  let continueButtonError = useSelector(
    (state) => state.ReviewPageFlight.continueButtonError
  );

  useEffect(() => {
    let timeoutId;

    if (continueButtonError === true) {
      // Set a timeout to dispatch the action after 2 seconds
      timeoutId = setTimeout(() => {
        dispatch(setcontinueButtonError(false));
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [continueButtonError]);

  let handleProceedToPay = (ancName) => {
    dispatch(removeTheAncellaries(ancName)); // WHEN THIS FUCNTION compltes its execvtion only then after it call below fucntion
    dispatch(Call_Continue3());
  };

  return (
    <>
      <div className={`review-page-main-section ${Theme}`}>
        <div className={`top-bar-section ${Theme}`}>
          <StatusCounter Theme={Theme} />
        </div>
        <div className={`main-bar-section ${Theme}`}>
          <div className="detail-section">
            {reviewPageData?.currentSection == 1 && (
              <>
                {(reviewPageData?.SearchResultType === 1 ||
                  reviewPageData?.SearchResultType === 2) && (
                  <OnewayData
                    Theme={Theme}
                    Data={reviewPageData}
                    startButtonShaking={startButtonShaking}
                  />
                )}
                {reviewPageData?.SearchResultType === 3 && (
                  <RoundwayDom
                    Theme={Theme}
                    Data={reviewPageData}
                    startButtonShaking={startButtonShaking}
                  />
                )}
                {reviewPageData?.SearchResultType === 4 &&
                  (reviewPageData?.FlightDetail[0] == undefined ? (
                    <RoundwayINTSplit
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ) : (
                    <RoundwayINTCombine
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ))}
                {reviewPageData?.SearchResultType === 5 && (
                  <MultiwayDom2
                    Theme={Theme}
                    Data={reviewPageData}
                    startButtonShaking={startButtonShaking}
                  />
                )}
                {reviewPageData?.SearchResultType === 7 && (
                  <MultiwayDom3
                    Theme={Theme}
                    Data={reviewPageData}
                    startButtonShaking={startButtonShaking}
                  />
                )}
                {reviewPageData?.SearchResultType === 9 && (
                  <MultiwayDom4
                    Theme={Theme}
                    Data={reviewPageData}
                    startButtonShaking={startButtonShaking}
                  />
                )}
                {reviewPageData?.SearchResultType === 6 &&
                  (reviewPageData?.FlightDetail[0] == undefined ? (
                    <MultiwayINTSplit2
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ) : (
                    <MultiwayINTCombine2
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ))}
                {reviewPageData?.SearchResultType === 8 &&
                  (reviewPageData?.FlightDetail[0] == undefined ? (
                    <MultiwayINTSplit3
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ) : (
                    <MultiwayINTCombine3
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ))}
                {reviewPageData?.SearchResultType === 10 &&
                  (reviewPageData?.FlightDetail[0] == undefined ? (
                    <MultiwayINTSplit4
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ) : (
                    <MultiwayINTCombine4
                      Theme={Theme}
                      Data={reviewPageData}
                      startButtonShaking={startButtonShaking}
                    />
                  ))}
              </>
            )}

            {reviewPageData?.currentSection == 2 && (
              <>
                {
                  <TravellersSection
                    firstNameLimit={reviewPageData.firstNameLimit}
                    lastNameLimit={reviewPageData.lastNameLimit}
                    airlineNameFormat={reviewPageData.airlineNameFormat}
                    IsPassportRequired={reviewPageData.IsPassportRequired}
                    Theme={Theme}
                    Data={reviewPageData}
                    startButtonShaking={startButtonShaking}
                  />
                }
              </>
            )}

            {reviewPageData?.currentSection == 3 && (
              <>
                {
                  <AddonsSection
                    startButtonShaking={startButtonShaking}
                    Theme={Theme}
                    Data={reviewPageData}
                  />
                }
              </>
            )}

            <div className="fare-summary-section">
              <FareDetailsSB
                Theme={Theme}
                ResultType={reviewPageData?.SearchResultType}
                Data={reviewPageData?.FlightDetail}
                CouponData={reviewPageData.appliedCoupon}
                AncellariesPrice={reviewPageData?.AncellariesPrice}
                isPriceChanged={reviewPageData.isPriceChanged}
                Message={reviewPageData?.PriceChangedData.Message}
              />
              <CouponSection
                coupons={createCouponsFlight(
                  reviewPageData?.SearchResultType,
                  reviewPageData?.FlightDetail
                )} // get to know -ca has single or double
                Theme={Theme}
                DefaultappliedCoupon={reviewPageData.appliedCoupon}
              />
            </div>
          </div>
        </div>
      </div>

      {reviewPageData.OpenPopupMini && (
        <div className={`mini-popup-overlay-Review ${Theme}`}>
          <div className="popup-content">
            {reviewPageData.OpenPopupMiniType == "Price_Changed" && (
              <>
                <p>{reviewPageData.OpenPopupData?.Message}</p>
                <div className="popup-buttons">
                  <button
                    onClick={() =>
                      handleContinue(reviewPageData.OpenPopupData?.fetchFlight)
                    }
                    className="popup-button continue-button"
                  >
                    Continue
                  </button>
                  <button
                    onClick={() => handleGoBack()}
                    className="popup-button go-back-button"
                  >
                    Go Back
                  </button>
                </div>
              </>
            )}
            {reviewPageData.OpenPopupMiniType == "Loading" && (
              <p>Loading .. Please Wait</p>
            )}
            {reviewPageData.OpenPopupMiniType == "Message" && (
              <p>Redirecting You to Payment Page </p>
            )}
            {reviewPageData.OpenPopupMiniType == "Error" && (
              <>
                <h2>{reviewPageData.OpenPopupData?.Heading}</h2>
                {!Array.isArray(reviewPageData.OpenPopupData?.Data) ? (
                  <p>{reviewPageData.OpenPopupData?.Data}</p>
                ) : (
                  <div>
                    {reviewPageData.OpenPopupData?.Data.map((item, id) => {
                      return <div>{item.Message}</div>;
                    })}
                  </div>
                )}
                <div className="popup-buttons">
                  {reviewPageData.OpenPopupData?.showContinue == true && (
                    <button
                      onClick={() =>
                        reviewPageData.OpenPopupData?.Heading !==
                          "Seat has Error" &&
                        reviewPageData.OpenPopupData?.Heading !==
                          "Meal has Error" &&
                        reviewPageData.OpenPopupData?.Heading !==
                          "Bag has Error"
                          ? handleContinue(
                              reviewPageData.OpenPopupData?.fetchFlight
                            )
                          : handleContinue2(
                              reviewPageData.OpenPopupData?.fetchFlight
                            )
                      }
                      className="popup-button continue-button"
                    >
                      {reviewPageData.OpenPopupData?.Heading !==
                        "Seat has Error" &&
                      reviewPageData.OpenPopupData?.Heading !==
                        "Meal has Error" &&
                      reviewPageData.OpenPopupData?.Heading !== "Bag has Error"
                        ? "Continue"
                        : "Choose Another"}
                    </button>
                  )}
                  <button
                    onClick={() =>
                      reviewPageData.OpenPopupData?.Heading !==
                        "Seat has Error" &&
                      reviewPageData.OpenPopupData?.Heading !==
                        "Meal has Error" &&
                      reviewPageData.OpenPopupData?.Heading !== "Bag has Error"
                        ? handleGoBack()
                        : handleProceedToPay(
                            reviewPageData.OpenPopupData.ProceedToPayName
                          )
                    }
                    className="popup-button go-back-button"
                  >
                    {reviewPageData.OpenPopupData?.Heading !==
                      "Seat has Error" &&
                    reviewPageData.OpenPopupData?.Heading !==
                      "Meal has Error" &&
                    reviewPageData.OpenPopupData?.Heading !== "Bag has Error"
                      ? "Go back"
                      : `Proceed To Pay ( Continue Without ${reviewPageData.OpenPopupData.ProceedToPayName} )`}
                  </button>
                </div>
              </>
            )}
            {reviewPageData.OpenPopupMiniType == "NameFormat" && (
              <>
                <p className={`${Theme}_regular color_errie_black font16`}>
                  Last Name is Missing !
                </p>
                <br />
                {reviewPageData?.airlineNameFormat.map((item, id) => {
                  return (
                    <div
                      key={id}
                      className={`${Theme}_regular color_deep-ocean font14`}
                    >
                      {getNameFormatMessage(item.airline, item.conditon)}
                    </div>
                  );
                })}

                <div className="popup-buttons">
                  <button
                    onClick={handleGoBackNameFormat}
                    className="popup-button go-back-button"
                  >
                    Go Back
                  </button>
                  <button
                    onClick={handleContinueNameFormat}
                    className="popup-button continue-button"
                  >
                    Continue
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default MainReviewPage;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.css";
import images from "../../../../../Utils/importImages";

import ValueRangePicker from "./Components/Draggers/ValueRangePicker"
import { TripSlider } from "./Components/TripSlider";
import { useDispatch, useSelector } from "react-redux";
import {
  generateFiltersAsync,
  setbarModeSelected,
  setFiltersApplied,
} from "../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import AppliedFilters from "./Components/AppliedFilters";
import ValueTimePicker from "./Components/Draggers/ValueTimePicker/ValueTimePicker";

// ../../../../../Assets/Images/

export default function FiltersSection({Theme : FontTheme}) {
  let dispatch = useDispatch();

  let ResultPageData = useSelector((state) => state?.ResultPageFlightsFlight);

  let [layoverLimit, setLayoverLimit] = useState(true);
  let [arrivalLimit, setarrivalLimit] = useState(true);
  let [airlineLimit, setairlineLimit] = useState(true);

  let layoverLimitNum = 3;
  let arrivalLimitNum = 3;
  let airlineLimitNum = 3;

  useMemo(() => {
    dispatch(generateFiltersAsync());
  }, []);

  useEffect(() => {
    if (ResultPageData.SearchResultType === 3) {
      dispatch(generateFiltersAsync());
    }
  }, [ResultPageData.barModeSelected]);

  const handleToggle = (mode) => {
    dispatch(setbarModeSelected(mode));
  };

  const handleFilterChange = (index, key, value, mode) => {
    dispatch(
      setFiltersApplied({ index: index, key: key, value: value, mode: mode })
    );
  };

  const FiltersApplied = useMemo(
    () => ResultPageData?.FiltersApplied[ResultPageData?.barModeSelected] || [],
    [ResultPageData?.FiltersApplied, ResultPageData?.barModeSelected]
  );

  let PriceMinValuedefault = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      ResultPageData?.barModeSelected
    ]?.filter((item) => {
      return item.key === `PRICE${ResultPageData?.barModeSelected}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
        ?.PriceDragger.minPrice;
    }

    return value[0].Value.min;
  }, [FiltersApplied, ResultPageData?.barModeSelected]);

  let PriceMaxValuedefault = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      ResultPageData?.barModeSelected
    ]?.filter((item) => {
      return item.key === `PRICE${ResultPageData?.barModeSelected}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
        ?.PriceDragger.maxPrice;
    }

    return value[0].Value.max;
  }, [FiltersApplied, ResultPageData?.barModeSelected]);

  let MinMinutesValueDurationdefault = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      ResultPageData?.barModeSelected
    ]?.filter((item) => {
      return item.key === `DURA${ResultPageData?.barModeSelected}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
        ?.DurationDragger.MinimumMinutes;
    }

    return value[0].Value.min;
  }, [FiltersApplied, ResultPageData?.barModeSelected]);

  let MaxMinutesValueDurationdefault = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      ResultPageData?.barModeSelected
    ]?.filter((item) => {
      return item.key === `DURA${ResultPageData?.barModeSelected}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
        ?.DurationDragger.MaximumMinutes;
    }

    return value[0].Value.max;
  }, [FiltersApplied, ResultPageData?.barModeSelected]);

  const handlePriceChange = (min, max, mode) => {
    handleFilterChange(
      mode,
      `PRICE${mode}`,
      { min, max },
      "Price"
    );
  };

  const handleDurationChange = (min, max) => {
    handleFilterChange(
      ResultPageData?.barModeSelected,
      `DURA${ResultPageData?.barModeSelected}`,
      { min, max },
      "Duration"
    );
  };

  const handleLayoverDurationChange = (min, max) => {
    handleFilterChange(
      ResultPageData?.barModeSelected,
      `DURALAY${ResultPageData?.barModeSelected}`,
      { min, max },
      "DurationLayover"
    );
  };

  let MinMinutesLayoverValueDurationdefault = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      ResultPageData?.barModeSelected
    ]?.filter((item) => {
      return item.key === `DURALAY${ResultPageData?.barModeSelected}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
        ?.LayoverDurationDragger.MinimumMinutes;
    }

    return value[0].Value.min;
  }, [FiltersApplied, ResultPageData?.barModeSelected]);

  let MaxMinutesLayoverValueDurationdefault = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      ResultPageData?.barModeSelected
    ]?.filter((item) => {
      return item.key === `DURALAY${ResultPageData?.barModeSelected}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
        ?.LayoverDurationDragger?.MaximumMinutes;
    }

    return value[0].Value.max;
  }, [FiltersApplied, ResultPageData?.barModeSelected]);


  const SelectedTimesKeys = useMemo(() => {
    let data = ResultPageData?.FiltersApplied[ResultPageData?.barModeSelected]
      ?.map((item) => {
        if (item.Mode === "DepartureTime" || item.Mode === "ArrivalTime") {
          return item.key;
        }
      })
      .filter((item) => item !== null);

    return data;
  }, [ResultPageData?.FiltersApplied, ResultPageData?.barModeSelected]);

  const isSecondFormOfFilter = useMemo(() => {
    const resultView2Conditions = [
      ResultPageData?.SearchResultType === 4,
      ResultPageData?.SearchResultType === 6,
      ResultPageData?.SearchResultType === 8,
      ResultPageData?.SearchResultType === 10,
    ];

    const isResultView2 = resultView2Conditions.some((condition) => condition) && ResultPageData?.ResultView === 1;

    // const filterType =
    //   ResultPageData?.SearchResultType === 4 ||
    //   ResultPageData?.SearchResultType === 6
    //     ? 0
    //     : ResultPageData?.SearchResultType === 8
    //     ? 3
    //     : 4;

    return [isResultView2, 0];
  }, [ResultPageData?.SearchResultType, ResultPageData?.ResultView]);

  let PriceMinValuedefault2 = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      isSecondFormOfFilter[1]
    ]?.filter((item) => {
      return item.key === `PRICE${isSecondFormOfFilter[1]}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[isSecondFormOfFilter[1]]
        ?.PriceDragger.minPrice;
    }

    return value[0].Value.min;
  }, [FiltersApplied, isSecondFormOfFilter[1]]);

  let PriceMaxValuedefault2 = useMemo(() => {
    let value = ResultPageData?.FiltersApplied[
      isSecondFormOfFilter[1]
    ]?.filter((item) => {
      return item.key === `PRICE${isSecondFormOfFilter[1]}`;
    });

    if (value.length === 0) {
      return ResultPageData?.filtersGenerated?.[isSecondFormOfFilter[1]]
        ?.PriceDragger.maxPrice;
    }

    return value[0].Value.max;
  }, [FiltersApplied, isSecondFormOfFilter[1]]);

  // color_light-pale-bg

  return (
    ResultPageData?.filtersLoading === false && (
      <>

        <AppliedFilters Theme={FontTheme} OriginalFiltersApplied={ResultPageData?.FiltersApplied} FiltersApplied={FiltersApplied} isSecondFormOfFilter={isSecondFormOfFilter} />

        {ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
          ?.PopularFilters && (
            <div className="frame-260">
              <div className="frame-104-3 frame-104-5">
                <div className="frame-175">
                  <div className="frame-201">
                    <div className={`popular-filters ${FontTheme}_regular color_magic-potion font18`}>
                      Popular Filters
                    </div>
                  </div>
                </div>

                {isSecondFormOfFilter[0] === true ? (
                  <div className="frame-1">
                    {ResultPageData?.filtersGenerated[
                      isSecondFormOfFilter[1]
                    ].PopularFilters?.map((item, index) => {
                      if (item.hasLogo === true) {
                        return (
                          <div
                            className="frame-17-1 frame-17-3"
                            key={index}
                            onClick={() =>
                              handleFilterChange(
                                isSecondFormOfFilter[1],
                                `ARLN${isSecondFormOfFilter[1]}${item.code}_Pop`,
                                item.code + "_Pop",
                                "Airline"
                              )
                            }
                          >
                            <div className="frame-7">
                              <div className="frame">
                                <div className="frame-3">
                                  <div className="group-46"></div>

                                  {ResultPageData?.FiltersApplied[
                                    isSecondFormOfFilter[1]
                                  ]?.some(
                                    (filter) =>
                                      filter.Mode === "Airline" &&
                                      filter.key ===
                                      `ARLN${isSecondFormOfFilter[1]}${item.code}_Pop`
                                  ) && (
                                      <img
                                        className="check"
                                        src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                        alt="check"
                                      />
                                    )}
                                </div>
                                <div className="frame-20-1 frame-20-3">
                                  <img
                                    className="unnamed-1 unnamed-5"
                                    src={
                                      item.logo === ""
                                        ? images["default-airline.png"]
                                        : item.logo
                                    }
                                    alt="unnamed"
                                  />
                                  <div className="frame-201-3">
                                  
                                      <div className={`air-india ${FontTheme}_regular color_gravel font16`}>
                                        {item.name}
                                      </div>
                                  
                                  </div>
                                </div>
                              </div>
                              <div className="frame-4">
                                <img
                                  className="vector"
                                  src={images["vector-42.svg"]}
                                  alt="Vector"
                                />
                                <div className={`number-21 number-34 ${FontTheme}_regular color_eerie-black font14`}>
                                  {item.price}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="frame-201-6"
                            onClick={() =>
                              handleFilterChange(
                                isSecondFormOfFilter[1],
                                `${item.code === "RF" ? "RF" : "STOP"}${isSecondFormOfFilter[1]
                                }${index}`,
                                item.code,
                                item.code === "RF" ? "Refundable" : "Stops"
                              )
                            }
                          >
                            <div className="frame-1-1">
                              <div className="frame-3">
                                <div className="group-46"></div>

                                {ResultPageData?.FiltersApplied[
                                  isSecondFormOfFilter[1]
                                ]?.some(
                                  (filter) =>
                                    filter.Mode ===
                                    (item.code === "RF"
                                      ? "Refundable"
                                      : "Stops") &&
                                    filter.key ===
                                    `${item.code === "RF" ? "RF" : "STOP"}${isSecondFormOfFilter[1]
                                    }${index}`
                                ) && (
                                    <img
                                      className="check"
                                      src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                      alt="check"
                                    />
                                  )}
                              </div>
                              <div className={`non-stop-flights ${FontTheme}_regular color_gravel font16`}>
                                {item.name}
                              </div>
                            </div>
                            <div className="frame-4">
                              <img
                                className="vector"
                                src={images["vector-31.svg"]}
                                alt="Vector"
                              />
                              <div className={`number-2 number-34 ${FontTheme}_regular color_eerie-black font14`}>
                                {item.price}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <div className="frame-1">
                    {ResultPageData?.filtersGenerated[
                      ResultPageData?.barModeSelected
                    ].PopularFilters?.map((item, index) => {
                      if (item.hasLogo === true) {
                        console.log("`ARLN${ResultPageData?.barModeSelected}${item.code}` is : ", `ARLN${ResultPageData?.barModeSelected}${item.code}`);
                        return (
                          <div
                            className="frame-17-1 frame-17-3"
                            key={index}
                            onClick={() =>
                              handleFilterChange(
                                ResultPageData?.barModeSelected,
                                `ARLN${ResultPageData?.barModeSelected}${item.code}`,
                                item.code,
                                "Airline"
                              )
                            }
                          >
                            <div className="frame-7">
                              <div className="frame">
                                <div className="frame-3">
                                  <div className="group-46"></div>

                                  {FiltersApplied?.some(
                                    (filter) =>
                                      filter.Mode === "Airline" &&
                                      filter.key ===
                                      `ARLN${ResultPageData?.barModeSelected}${item.code}`
                                  ) && (
                                      <img
                                        className="check"
                                        src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                        alt="check"
                                      />
                                    )}
                                </div>
                                <div className="frame-20-1 frame-20-3">
                                  <img
                                    className="unnamed-1 unnamed-5"
                                    src={
                                      item.logo === ""
                                        ? images["default-airline.png"]
                                        : item.logo
                                    }
                                    alt="unnamed"
                                  />
                                  <div className="frame-201-3">
                                  
                                      <div className={`air-india ${FontTheme}_regular color_gravel font16`}>
                                        {item.name}
                                      </div>
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="frame-4">
                                <img
                                  className="vector"
                                  src={images["vector-42.svg"]}
                                  alt="Vector"
                                />
                                <div className={`number-21 number-34 ${FontTheme}_regular color_eerie-black font14`}>
                                  {item.price}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        console.log("khgcjsdsttysgjhcbuytrr : ", `${item.code === "RF" ? "RF" : "STOP"}${ResultPageData?.barModeSelected
                          }${index}`);
                        return (
                          <div
                            className="frame-201-6"
                            onClick={() =>
                              handleFilterChange(
                                ResultPageData?.barModeSelected,
                                `${item.code === "RF" ? "RF" : "STOP"}${ResultPageData?.barModeSelected
                                }${index}`,
                                item.code,
                                item.code === "RF" ? "Refundable" : "Stops"
                              )
                            }
                          >
                            <div className="frame-1-1">
                              <div className="frame-3">
                                <div className="group-46"></div>

                                {FiltersApplied?.some(
                                  (filter) =>
                                    filter.Mode ===
                                    (item.code === "RF"
                                      ? "Refundable"
                                      : "Stops") &&
                                    filter.key ===
                                    `${item.code === "RF" ? "RF" : "STOP"}${ResultPageData?.barModeSelected
                                    }${index}`
                                ) && (
                                    <img
                                      className="check"
                                      src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                      alt="check"
                                    />
                                  )}
                              </div>
                              <div className={`non-stop-flights ${FontTheme}_regular color_gravel font16`}>
                                {item.name}
                              </div>
                            </div>
                            <div className="frame-4">
                              <img
                                className="vector"
                                src={images["vector-31.svg"]}
                                alt="Vector"
                              />
                              <div className={`number-2 number-34 ${FontTheme}_regular color_eerie-black font14`}>
                                {item.price}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}

                {isSecondFormOfFilter[0] === true &&
                  ResultPageData?.filtersGenerated?.[
                    isSecondFormOfFilter[1]
                  ]?.PriceDragger.minPrice !=
                  ResultPageData?.filtersGenerated?.[
                    isSecondFormOfFilter[1]
                  ]?.PriceDragger.maxPrice &&
                  ResultPageData?.filtersGenerated?.[
                    isSecondFormOfFilter[1]
                  ]?.PriceDragger.minPrice > 0 && (
                    <ValueRangePicker
                           Theme={FontTheme}
             
                      min={
                        ResultPageData?.filtersGenerated?.[
                          isSecondFormOfFilter[1]
                        ]?.PriceDragger.minPrice
                      }
                      max={
                        ResultPageData?.filtersGenerated?.[
                          isSecondFormOfFilter[1]
                        ]?.PriceDragger.maxPrice
                      }
                      minDefault={
                        PriceMinValuedefault2 === undefined
                          ? ResultPageData?.filtersGenerated?.[
                            isSecondFormOfFilter[1]
                          ]?.PriceDragger.minPrice
                          : PriceMinValuedefault2
                      }
                      maxDefault={
                        PriceMaxValuedefault2 === undefined
                          ? ResultPageData?.filtersGenerated?.[
                            isSecondFormOfFilter[1]
                          ]?.PriceDragger.maxPrice
                          : PriceMaxValuedefault2
                      }
                      mode={isSecondFormOfFilter[1]}
                      onChange={handlePriceChange}
                    />
                  )}
              </div>
            </div>
          )}


{ResultPageData.SearchResultType === 5 && (
          <div className="frame-129">
            <div className={`frame-175-5 ${FontTheme}`}>
              <div className="frame">
                <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                  {
                    ResultPageData.SearchParameters.Segments[
                      ResultPageData?.barModeSelected
                    ].OriginCity
                  }
                  <img
                    src={images["city-exchange-arrow.svg"]}
                    alt="exghange arrow"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  />
                  {
                    ResultPageData.SearchParameters.Segments[
                      ResultPageData?.barModeSelected
                    ].DestinationCity
                  }
                </div>
              </div>
            </div>
          </div>
        )}

        {ResultPageData.SearchResultType === 6 &&
          (ResultPageData.ResultView === 2 ? (
            <div className="frame-129">
              <div className={`frame-175-5 ${FontTheme}`}>
                <div className="frame">
                  <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                    {
                      ResultPageData.SearchParameters.Segments[
                        ResultPageData?.barModeSelected - 2
                      ].OriginCity
                    }
                    <img
                      src={images["city-exchange-arrow.svg"]}
                      alt="exghange arrow"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    />
                    {
                      ResultPageData.SearchParameters.Segments[
                        ResultPageData?.barModeSelected - 2
                      ].DestinationCity
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <TripSlider
                        Theme={FontTheme}
      
              trips={[
                {
                  toSelect: 0,
                  id: 1,
                  from: ResultPageData.SearchParameters.Segments[0].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[0]
                    .DestinationCity,
                },
                {
                  toSelect: 1,
                  id: 2,
                  from: ResultPageData.SearchParameters.Segments[1].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[1]
                    .DestinationCity,
                },
              ]}
              selected={ResultPageData?.barModeSelected}
            />
          ))}

        {ResultPageData.SearchResultType === 7 && (
          <div className="frame-129">
            <div className={`frame-175-5 ${FontTheme}`}>
              <div className="frame">
                <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                  {
                    ResultPageData.SearchParameters.Segments[
                      ResultPageData?.barModeSelected
                    ].OriginCity
                  }
                  <img
                    src={images["city-exchange-arrow.svg"]}
                    alt="exghange arrow"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  />
                  {
                    ResultPageData.SearchParameters.Segments[
                      ResultPageData?.barModeSelected
                    ].DestinationCity
                  }
                </div>
              </div>
            </div>
          </div>
        )}

        {ResultPageData.SearchResultType === 8 &&
          (ResultPageData.ResultView === 2 ? (
            <div className="frame-129">
              <div className={`frame-175-5 ${FontTheme}`}>
                <div className="frame">
                  <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                    {
                      ResultPageData.SearchParameters.Segments[
                        ResultPageData?.barModeSelected - 3
                      ].OriginCity
                    }
                    <img
                      src={images["city-exchange-arrow.svg"]}
                      alt="exghange arrow"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    />
                    {
                      ResultPageData.SearchParameters.Segments[
                        ResultPageData?.barModeSelected - 3
                      ].DestinationCity
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <TripSlider
                        Theme={FontTheme}
              trips={[
                {
                  toSelect: 0,
                  id: 1,
                  from: ResultPageData.SearchParameters.Segments[0].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[0]
                    .DestinationCity,
                },
                {
                  toSelect: 1,
                  id: 2,
                  from: ResultPageData.SearchParameters.Segments[1].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[1]
                    .DestinationCity,
                },
                {
                  toSelect: 2,
                  id: 3,
                  from: ResultPageData.SearchParameters.Segments[2].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[2]
                    .DestinationCity,
                },
              ]}
              selected={ResultPageData?.barModeSelected}
            />
          ))}

        {ResultPageData.SearchResultType === 9 && (
          <div className="frame-129">
            <div className={`frame-175-5 ${FontTheme}`}>
              <div className="frame">
                <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                  {
                    ResultPageData.SearchParameters.Segments[
                      ResultPageData?.barModeSelected
                    ].OriginCity
                  }
                  <img
                    src={images["city-exchange-arrow.svg"]}
                    alt="exghange arrow"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  />
                  {
                    ResultPageData.SearchParameters.Segments[
                      ResultPageData?.barModeSelected
                    ].DestinationCity
                  }
                </div>
              </div>
            </div>
          </div>
        )}

        {ResultPageData.SearchResultType === 10 &&
          (ResultPageData.ResultView === 2 ? (
            <div className="frame-129">
              <div className={`frame-175-5 ${FontTheme}`}>
                <div className="frame">
                  <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                    {
                      ResultPageData.SearchParameters.Segments[
                        ResultPageData?.barModeSelected - 4
                      ].OriginCity
                    }
                    <img
                      src={images["city-exchange-arrow.svg"]}
                      alt="exghange arrow"
                      style={{ marginLeft: "10%", marginRight: "10%" }}
                    />
                    {
                      ResultPageData.SearchParameters.Segments[
                        ResultPageData?.barModeSelected - 4
                      ].DestinationCity
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <TripSlider
                        Theme={FontTheme}
              trips={[
                {
                  toSelect: 0,
                  id: 1,
                  from: ResultPageData.SearchParameters.Segments[0].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[0]
                    .DestinationCity,
                },
                {
                  toSelect: 1,
                  id: 2,
                  from: ResultPageData.SearchParameters.Segments[1].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[1]
                    .DestinationCity,
                },
                {
                  toSelect: 2,
                  id: 3,
                  from: ResultPageData.SearchParameters.Segments[2].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[2]
                    .DestinationCity,
                },
                {
                  toSelect: 3,
                  id: 4,
                  from: ResultPageData.SearchParameters.Segments[3].OriginCity,
                  to: ResultPageData.SearchParameters.Segments[3]
                    .DestinationCity,
                },
              ]}
              selected={ResultPageData?.barModeSelected}
            />
          ))}

          
        {ResultPageData.SearchResultType === 4 &&
          (ResultPageData.ResultView === 2 ? (
            <div className="frame-129">
              {ResultPageData?.barModeSelected === 2 ? (
                <div className={`frame-175-5 ${FontTheme}`}>
                  <div className="frame">
                    <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                      {ResultPageData.SearchParameters.Segments[0].OriginCity}
                      <img
                        src={images["city-exchange-arrow.svg"]}
                        alt="exghange arrow"
                        style={{ marginLeft: "10%", marginRight: "10%" }}
                      />
                      {
                        ResultPageData.SearchParameters.Segments[0]
                          .DestinationCity
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <div className={`frame-175-5 ${FontTheme}`}>
                  <div className="frame">
                    <div className={`return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                      {
                        ResultPageData.SearchParameters.Segments[0]
                          .DestinationCity
                      }
                      <img
                        src={images["city-exchange-arrow.svg"]}
                        alt="exghange arrow"
                        style={{ marginLeft: "10%", marginRight: "10%" }}
                      />
                      {ResultPageData.SearchParameters.Segments[0].OriginCity}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="frame-129">
              <div
                className={
                  ResultPageData?.barModeSelected === 0
                    ? `frame-176-2 ${FontTheme}`
                    : `frame-176 ${FontTheme}`
                }
                onClick={() => handleToggle(0)}
              >
                <div className="frame">
                  <div
                    className={
                      ResultPageData?.barModeSelected === 0
                        ? `return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"}  font16`
                        : "onward"
                    }
                  >
                    ONWARD
                  </div>
                </div>
              </div>
              <div
                className={
                  ResultPageData?.barModeSelected === 1
                    ? `frame-176-2 ${FontTheme}`
                    : `frame-176 ${FontTheme}`
                }
                onClick={() => handleToggle(1)}
              >
                <div className="frame">
                  <div
                    className={
                      ResultPageData?.barModeSelected === 1
                        ? `return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"}  font16`
                        : "onward"
                    }
                  >
                    RETURN
                  </div>
                </div>
              </div>
            </div>
          ))}

{ResultPageData.SearchResultType === 3 && (
          <div className="frame-129">
            <div
              className={
                ResultPageData?.barModeSelected === 0
                  ? `frame-176-2 ${FontTheme}`
                  : `frame-176 ${FontTheme}`
              }
              onClick={() => handleToggle(0)}
            >
              <div className="frame">
                <div
                  className={
                    ResultPageData?.barModeSelected === 0
                      ? `return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`
                      : "onward"
                  }
                >
                  ONWARD
                </div>
              </div>
            </div>
            <div
              className={
                ResultPageData?.barModeSelected === 1
                  ? `frame-176-2 ${FontTheme}`
                  : `frame-176 ${FontTheme}`
              }
              onClick={() => handleToggle(1)}
            >
              <div className="frame">
                <div
                  className={
                    ResultPageData?.barModeSelected === 1
                      ? `return ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"}  font16`
                      : "onward"
                  }
                >
                  RETURN
                </div>
              </div>
            </div>
          </div>
        )}

        
        <div className="frame-232-1 frame-232-3">
          {isSecondFormOfFilter[0] === true
            ? ""
            : ResultPageData?.filtersGenerated?.[
              ResultPageData?.barModeSelected
            ]?.PriceDragger.minPrice !=
            ResultPageData?.filtersGenerated?.[
              ResultPageData?.barModeSelected
            ]?.PriceDragger.maxPrice &&
            ResultPageData?.filtersGenerated?.[
              ResultPageData?.barModeSelected
            ]?.PriceDragger.minPrice > 0 && (
              <ValueRangePicker
                     Theme={FontTheme}
                min={
                  ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.PriceDragger.minPrice
                }
                max={
                  ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.PriceDragger.maxPrice
                }
                minDefault={
                  PriceMinValuedefault === undefined
                    ? ResultPageData?.filtersGenerated?.[
                      ResultPageData?.barModeSelected
                    ]?.PriceDragger.minPrice
                    : PriceMinValuedefault
                }
                maxDefault={
                  PriceMaxValuedefault === undefined
                    ? ResultPageData?.filtersGenerated?.[
                      ResultPageData?.barModeSelected
                    ]?.PriceDragger.maxPrice
                    : PriceMaxValuedefault
                }
                mode={ResultPageData?.barModeSelected}
                onChange={handlePriceChange}
              />
            )}

          {ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
            ?.DurationDragger.MinimumMinutes !=
            ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
              ?.DurationDragger.MaximumMinutes &&
            ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
              ?.DurationDragger.MinimumMinutes > 0 ? (
            <ValueTimePicker
            type={1}
              Theme={FontTheme}
   
              min={
                ResultPageData?.filtersGenerated?.[
                  ResultPageData?.barModeSelected
                ]?.DurationDragger.MinimumMinutes
              }
              max={
                ResultPageData?.filtersGenerated?.[
                  ResultPageData?.barModeSelected
                ]?.DurationDragger.MaximumMinutes
              }
              text={"Flight Duration"}
              minDefault={
                MinMinutesValueDurationdefault === undefined
                  ? ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.DurationDragger.MinimumMinutes
                  : MinMinutesValueDurationdefault
              }
              maxDefault={
                MaxMinutesValueDurationdefault === undefined
                  ? ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.DurationDragger.MaximumMinutes
                  : MaxMinutesValueDurationdefault
              }
              onChange={handleDurationChange}
            />
          ) : (
            null
          )}

          <div className="frame-1-3">
            <div className="frame-201-2">
              <div className={`departure-time ${FontTheme}_regular color_magic-potion font18`}>
                Departure Time
              </div>
            </div>
            <div className="frame-20">
              <div className="frame-168">
                <div className="frame-175-2">
                  <div
                    className="frame-1-4"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `DEPT${ResultPageData?.barModeSelected}0`,
                        0,
                        "DepartureTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 morning-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `DEPT${ResultPageData?.barModeSelected}0`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          Before 6 AM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-1-4"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `DEPT${ResultPageData?.barModeSelected}1`,
                        1,
                        "DepartureTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 afternoon-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `DEPT${ResultPageData?.barModeSelected}1`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          6 AM - 12 PM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-1-4"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `DEPT${ResultPageData?.barModeSelected}2`,
                        2,
                        "DepartureTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 evening-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `DEPT${ResultPageData?.barModeSelected}2`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          12 PM - 6 PM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-1-4-1"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `DEPT${ResultPageData?.barModeSelected}3`,
                        3,
                        "DepartureTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 night-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `DEPT${ResultPageData?.barModeSelected}3`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          After 6 PM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-1-3">
            <div className="frame-201-2">
              <div className={`arrival-time ${FontTheme}_regular color_magic-potion font18`}>
                Arrival Time
              </div>
            </div>
            <div className="frame-20">
              <div className="frame-168">
                <div className="frame-175-2">
                  <div
                    className="frame-1-4"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `ARRT${ResultPageData?.barModeSelected}0`,
                        0,
                        "ArrivalTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 morning-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `ARRT${ResultPageData?.barModeSelected}0`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          Before 6 AM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-1-4"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `ARRT${ResultPageData?.barModeSelected}1`,
                        1,
                        "ArrivalTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 afternoon-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `ARRT${ResultPageData?.barModeSelected}1`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          6 AM - 12 PM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-1-4"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `ARRT${ResultPageData?.barModeSelected}2`,
                        2,
                        "ArrivalTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 evening-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `ARRT${ResultPageData?.barModeSelected}2`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          12 PM - 6 PM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-1-4-1"
                    onClick={() =>
                      handleFilterChange(
                        ResultPageData?.barModeSelected,
                        `ARRT${ResultPageData?.barModeSelected}3`,
                        3,
                        "ArrivalTime"
                      )
                    }
                  >
                    <img
                      className={`frame-1-5 night-image ${FontTheme} ${SelectedTimesKeys &&
                          SelectedTimesKeys.length > 0 &&
                          SelectedTimesKeys.includes(
                            `ARRT${ResultPageData?.barModeSelected}3`
                          )
                          ? "selected"
                          : ""
                        }`}
                      alt="Frame 168"
                    />
                    <div className="frame-1-6">
                      <div className="frame-17">
                        <div className={`before-6 valign-text-middle ${FontTheme}_regular color_eerie-black font10`}>
                          After 6 PM
                        </div>
                      </div>
                      <div className="frame-17">
                        <div className={`am valign-text-middle ${FontTheme}_regular color_eerie-black font10`}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
            ?.LayoverDurationDragger.MinimumMinutes !=
            ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
              ?.LayoverDurationDragger.MaximumMinutes &&
            ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
              ?.LayoverDurationDragger.MinimumMinutes > 0 ? (
            <ValueTimePicker
            type={2}
              Theme={FontTheme}
              min={
                ResultPageData?.filtersGenerated?.[
                  ResultPageData?.barModeSelected
                ]?.LayoverDurationDragger.MinimumMinutes
              }
              max={
                ResultPageData?.filtersGenerated?.[
                  ResultPageData?.barModeSelected
                ]?.LayoverDurationDragger.MaximumMinutes
              }
              text={"Layover Duration"}
              minDefault={
                MinMinutesLayoverValueDurationdefault === undefined
                  ? ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.LayoverDurationDragger.MinimumMinutes
                  : MinMinutesLayoverValueDurationdefault
              }
              maxDefault={
                MaxMinutesLayoverValueDurationdefault === undefined
                  ? ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.LayoverDurationDragger.MaximumMinutes
                  : MaxMinutesLayoverValueDurationdefault
              }
              onChange={handleLayoverDurationChange}
            />
          ) : (
            null
          )}

          {ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
            ?.LayoverAirports?.length > 0 && (
              <div className="frame-1-3">
                <div className="frame-201-2">
                  <div className={`layover-airports layover ${FontTheme}_regular color_magic-potion font18`}>
                    Layover Airports
                  </div>
                </div>
                <div className="frame-1">
                  {ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.LayoverAirports?.slice(
                    0,
                    layoverLimit === true
                      ? 3
                      : ResultPageData?.filtersGenerated?.[
                        ResultPageData?.barModeSelected
                      ]?.LayoverAirports?.length
                  ).map((item, index) => {
                    return (
                      <div
                        className="frame-201-6"
                        key={index}
                        onClick={() =>
                          handleFilterChange(
                            ResultPageData?.barModeSelected,
                            `LAYAIRP${ResultPageData?.barModeSelected}${index}`,
                            item.Code,
                            "LayoverAirport"
                          )
                        }
                      >
                        <div className="frame-1-1">
                          <div className="frame-3">
                            <div className="group-46"></div>
                            {FiltersApplied?.some(
                              (filter) =>
                                filter.Mode === "LayoverAirport" &&
                                filter.key ===
                                `LAYAIRP${ResultPageData?.barModeSelected}${index}`
                            ) && (
                                <img
                                  className="check"
                                  src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                  alt="check"
                                />
                              )}
                          </div>
                          <div className={`non-stop-flights ${FontTheme}_regular color_gravel font16`}>
                            {item.Name}
                          </div>
                        </div>
                        <div className="frame-4">
                          <img
                            className="vector"
                            src={images["vector-31.svg"]}
                            alt="Vector"
                          />
                          <div className={`number-2 number-34 ${FontTheme}_regular color_eerie-black font14`}>
                            {item?.Price}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.LayoverAirports.length > 3 && layoverLimit === true ? (
                    <div
                      className="frame-203"
                      onClick={() => setLayoverLimit(false)}
                    >
                      <img
                        className="plus"
                        src={images[FontTheme == "proxima" ? "plus-1.svg" : "golden-plus.svg"]}
                        alt="Plus"
                      />
                      <div className={`address-1 address-19 ${FontTheme}_regular color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                        {ResultPageData?.filtersGenerated?.[
                          ResultPageData?.barModeSelected
                        ]?.LayoverAirports.length - layoverLimitNum}{" "}
                        more
                      </div>
                    </div>
                  ) : ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.LayoverAirports.length <= 3 ? (
                    ""
                  ) : (
                    <div
                      className="frame-203"
                      onClick={() => setLayoverLimit(true)}
                    >
                      <img
                        className="plus"
                        src={images[FontTheme == "proxima" ? "minus-icon.svg" : "minus-icon-gold.svg"]}
                        alt="Plus"
                      />
                      <div className={`address-1 address-19 ${FontTheme}_regular color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                        show less
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

          {ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
            ?.nearbyArrivalAirports?.length > 0 && (
              <div className="frame-1-3">
                <div className="frame-201-2">
                  <div className={`layover-airports layover ${FontTheme}_regular color_magic-potion font18`}>
                    Nearby Arrival Airports
                  </div>
                </div>
                <div className="frame-1">
                  {ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.nearbyArrivalAirports
                    ?.slice(
                      0,
                      arrivalLimit === true
                        ? 3
                        : ResultPageData?.filtersGenerated?.[
                          ResultPageData?.barModeSelected
                        ]?.nearbyArrivalAirports?.length
                    )
                    .map((item, index) => {
                      return (
                        <div
                          className="frame-201-6"
                          key={index}
                          onClick={() =>
                            handleFilterChange(
                              ResultPageData?.barModeSelected,
                              `NEARBYAIRP${ResultPageData?.barModeSelected}${index}`,
                              item.Code,
                              "NearbyAirport"
                            )
                          }
                        >
                          <div className="frame-1-1">
                            <div className="frame-3">
                              <div className="group-46"></div>
                              {FiltersApplied?.some(
                                (filter) =>
                                  filter.Mode === "NearbyAirport" &&
                                  filter.key ===
                                  `NEARBYAIRP${ResultPageData?.barModeSelected}${index}`
                              ) && (
                                  <img
                                    className="check"
                                    src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                    alt="check"
                                  />
                                )}
                            </div>
                            <div className={`non-stop-flights ${FontTheme}_regular color_gravel font16`}>
                              {item.Name}
                            </div>
                          </div>
                          <div className="frame-4">
                            <img
                              className="vector"
                              src={images["vector-31.svg"]}
                              alt="Vector"
                            />
                            <div className={`number-2 number-34 ${FontTheme}_regular color_eerie-black font14`}>
                              {item.Price}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.nearbyArrivalAirports.length > 3 && arrivalLimit === true ? (
                    <div
                      className="frame-203"
                      onClick={() => setarrivalLimit(false)}
                    >
                      <img
                        className="plus"
                        src={images[FontTheme == "proxima" ? "plus-1.svg" : "golden-plus.svg"]}
                        alt="Plus"
                      />
                      <div className={`address-1 address-19 ${FontTheme}_regular color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                        {ResultPageData?.filtersGenerated?.[
                          ResultPageData?.barModeSelected
                        ]?.nearbyArrivalAirports.length - arrivalLimitNum}{" "}
                        more
                      </div>
                    </div>
                  ) : ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.nearbyArrivalAirports.length <= 3 ? (
                    ""
                  ) : (
                    <div
                      className="frame-203"
                      onClick={() => setarrivalLimit(true)}
                    >
                      <img
                        className="plus"
                        src={images[FontTheme == "proxima" ? "minus-icon.svg" : "minus-icon-gold.svg"]}
                        alt="Plus"
                      />
                      <div className={`address-1 address-19 ${FontTheme}_regular color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                        show less
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>

        {ResultPageData?.filtersGenerated?.[ResultPageData?.barModeSelected]
          ?.Airlines && (
            <div className="frame-117">
              <div className="frame-201-11">
                <div className="frame-202-10">
                  <div className={`airlines ${FontTheme}_regular color_magic-potion font18`}>
                    Airlines
                  </div>
                </div>
                <div className="frame-6">
                  <div className="frame-6">
                    {ResultPageData?.filtersGenerated?.[
                      ResultPageData?.barModeSelected
                    ]?.Airlines?.slice(
                      0,
                      airlineLimit === true
                        ? 3
                        : ResultPageData?.filtersGenerated?.[
                          ResultPageData?.barModeSelected
                        ]?.Airlines?.length
                    ).map((item, index) => {
                      return (
                        <div
                          className="frame-17-1 frame-17-3"
                          key={index}
                          onClick={() => {
                            let isOfPopular = item.isOfPopular === undefined;

                            if (isOfPopular === true) {
                              handleFilterChange(
                                ResultPageData?.barModeSelected,
                                `ARLN${ResultPageData?.barModeSelected}${item.code}`,
                                item.code,
                                "Airline"
                              )

                            } else {
                              handleFilterChange(
                                isSecondFormOfFilter[1],
                                `ARLN${isSecondFormOfFilter[1]}${item.code}_Pop`,
                                item.code + "_Pop",
                                "Airline"
                              )

                            }
                          }
                          }
                        >
                          <div className="frame-7">
                            <div
                              style={{ display: "flex", alignItems: "center" , gap:"8px"}}
                            >
                              <div className="frame-3">
                                <div className="group-46"></div>

                                {
                                  item.isOfPopular === undefined &&
                                  (FiltersApplied?.some(
                                    (filter) =>
                                      filter.Mode === "Airline" &&
                                      filter.key ===
                                      `ARLN${ResultPageData?.barModeSelected}${item.code}`
                                  ) && (
                                      <img
                                        className="check"
                                        src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                        alt="check"
                                      />
                                    ))
                                }

                                {
                                  item.isOfPopular != undefined && (
                                    ResultPageData?.FiltersApplied[
                                      isSecondFormOfFilter[1]
                                    ]?.some(
                                      (filter) =>
                                        filter.Mode === "Airline" &&
                                        filter.key ===
                                        `ARLN${isSecondFormOfFilter[1]}${item.code}_Pop`
                                    ) && (
                                      <img
                                        className="check"
                                        src={images[FontTheme == "proxima" ? "check-19.svg" : "flight-busines-fliter-tick.svg"]}
                                        alt="check"
                                      />
                                    )
                                  )
                                }

                              </div>
                              <div className="frame-20-1 frame-20-3">
                                <img
                                  className="unnamed-1 unnamed-5"
                                  src={
                                    item.logo === ""
                                      ? images["default-airline.png"]
                                      : item.logo
                                  }
                                  alt="unnamed"
                                />
                                <div className="frame-201-3">
                                 
                                    <div
                                      className={`air-india ${FontTheme}_regular color_gravel font16`}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.name}
                                    </div>
                          
                                </div>
                              </div>
                            </div>

                            <div className="frame-4">
                              <img
                                className="vector"
                                src={images["vector-42.svg"]}
                                alt="Vector"
                              />
                              <div className={`number-21 number-34 ${FontTheme}_regular color_eerie-black font14`}>
                                {item.price}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {ResultPageData?.filtersGenerated?.[
                    ResultPageData?.barModeSelected
                  ]?.Airlines.length > 3 &&
                    (airlineLimit === true ? (
                      <div
                        className="frame-203"
                        onClick={() => setairlineLimit(false)}
                      >
                        <img
                          className="plus"
                          src={images[FontTheme == "proxima" ? "plus-1.svg" : "golden-plus.svg"]}
                          alt="Plus"
                        />
                        <div className={`address-1 address-19 ${FontTheme}_regular color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                          {ResultPageData?.filtersGenerated?.[
                            ResultPageData?.barModeSelected
                          ]?.Airlines.length - airlineLimitNum}{" "}
                          more
                        </div>
                      </div>
                    ) : ResultPageData?.filtersGenerated?.[
                      ResultPageData?.barModeSelected
                    ]?.Airlines.length <= 3 ? (
                      ""
                    ) : (
                      <div
                        className="frame-203"
                        onClick={() => setairlineLimit(true)}
                      >
                        <img
                          className="plus"
                          src={images[FontTheme == "proxima" ? "minus-icon.svg" : "minus-icon-gold.svg"]}
                          alt="Plus"
                        />
                        <div className={`address-1 address-19 ${FontTheme}_regular color_${FontTheme == "proxima" ? "endeavour" : "burning-gold"} font16`}>
                          show less
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
      </>
    )
  );
}

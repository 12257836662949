import React, { useEffect, useState } from "react";
import images from "../../../../Utils/importImages";
import SpecialFareOptions from "./SpecialFareOptions";
import {
  formatDate,
  getDayOfTheDate,
  getFirstTwoLettersOfDate,
  getThreeLetterMonth,
  getTwoLetterYear,
} from "../../../../Utils/Date_Time_Calendar_Functions";
import From_To_Popup from "../../../Components/Popups/From_To_Popup";
import TravellersPopup from "../../../Components/Popups/TravellersPopup";
import RangeCalender from "../../../Components/Popups/Calendars/RangeCalender";
import { useDispatch, useSelector } from "react-redux";
import {
  setExchangeSearchParas,
  setSearchBarMode,
  setSearchSegmentsParams,
  setTheDates,
  setTravellersAndClasses,
  setTravellersErrors,
} from "../../../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE";
import ErrorMessagePopup from "../../../Components/Popups/ErrorMessagePopup";
import {
  SearchBarErrorMessages,
  TravellersFareMessage,
} from "../../../../Constants/ConstantsFlights";
import { fetchCalendarValues } from "../../../../Redux/Apis/Flight-Apis";
import { getLocalStorageJSON } from "../../../../Utils/localStorageFunc";

export default function OnewaySearchBar({
  openPopup,
  setopenPopup,
  openPopupActual,
  setopenPopupActual,
}) {
  let searchBarData = useSelector((state) => state.HomePageSearchBarFlight);

  let travellersData = useSelector(
    (state) => state.HomePageSearchBarFlight.searchParamsTravellers
  );
  let classData = useSelector(
    (state) => state.HomePageSearchBarFlight.searchParamsFare
  );

  let dispatch = useDispatch();

  let changeTheDate = (date) => {
    let selectedDate1 = date;
    let selectedDate2 = new Date(
      searchBarData?.searchParams?.Segments[1]?.OriginDate
    );

    let selectedDate3 = new Date(
      searchBarData?.searchParams?.Segments[2]?.OriginDate
    );

    let selectedDate4 = new Date(
      searchBarData?.searchParams?.Segments[3]?.OriginDate
    );

    let ReturnDate = searchBarData?.searchParams?.Segments[0]?.ReturnDate;

    if (ReturnDate != null) {
      if (
        selectedDate1 >
        new Date(searchBarData?.searchParams?.Segments[0]?.ReturnDate).setHours(
          0,
          0,
          0,
          0
        )
      ) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        ReturnDate = newDate;
      }
    }

    if (selectedDate1 > selectedDate2) {
      const newDate = new Date(selectedDate1);
      newDate.setDate(selectedDate1.getDate() + 1);
      selectedDate2 = newDate;
    }
    if (selectedDate2 > selectedDate3) {
      const newDate = new Date(selectedDate2);
      newDate.setDate(selectedDate2.getDate() + 1);
      selectedDate3 = newDate;
    }
    if (selectedDate3 > selectedDate4) {
      const newDate = new Date(selectedDate3);
      newDate.setDate(selectedDate3.getDate() + 1);
      selectedDate4 = newDate;
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let [calendarValues, setCalendarValues] = useState(null);

  useEffect(() => {
    if (openPopup === 3) {
      if (
        searchBarData?.AirportsFromError1[0] === true ||
        searchBarData?.AirportsToError1[0] === true ||
        searchBarData?.searchParams?.Segments[0]?.OriginCode === "" ||
        searchBarData?.searchParams?.Segments[0]?.DestinyCode === ""
      ) {
        setCalendarValues(null);
      } else {
        // Call the calendar values API
        const origin = searchBarData?.searchParams?.Segments[0]?.OriginCode;
        const destination =
          searchBarData?.searchParams?.Segments[0]?.DestinyCode;

        fetchCalendarValues(origin, destination)
          .then((data) => {
            setCalendarValues(data);
          })
          .catch((error) => {
            setCalendarValues(null);
          });
      }
    }
  }, [openPopup]);

  let handleClose = (item, PopupType) => {
    let StructuredAirport = {
      City: item.city_name,
      Code: item.airport_code,
      AirportName: item.airport_name,
      CountryCode: item.country_code,

      type: PopupType[0],
      PopupType: PopupType[1],

      airport: item,
    };

    dispatch(setSearchSegmentsParams(StructuredAirport));

    setopenPopup(openPopup + 1);
    setopenPopupActual(openPopup + 1);
  };

  return (
    <>
      <div className="frame-33">
        <div className="frame">
          <div className="frame-99-5">

            <div>
              <div
                className="frame-28"
                onClick={() => setopenPopup(1)}
                style={openPopup === 1 ? { backgroundColor: "#E8F3FF" } : {}}
              >
                <div className="frame-99-6">
                  <div
                    className="place-1 proxima_regular color_light-gray-black font14"
                    style={openPopup === 1 ? { color: "#0057B7" } : {}}
                  >
                    From
                  </div>
                  {/* Render the CustomPopup component */}
                </div>
                <div className="place-2 proxima_regular color_eerie-black font24">
                  {searchBarData?.searchParams?.Segments[0]?.OriginCity}
                </div>
                <div className="del-indira-gandhi-airport proxima_regular color_light-gray-black font16 text-overflow-elipsis-search">
                  {searchBarData?.searchParams?.Segments[0]?.OriginCode}
                  {!searchBarData?.searchParams?.Segments[0]?.OriginCode && (
                    <span style={{ visibility: "hidden" }}>dummy</span>
                  )}
                  {searchBarData?.searchParams?.Segments[0]?.OriginCode && ","}{" "}
                  {searchBarData?.searchParams?.Segments[0]?.Origin}
                </div>
              </div>
              {searchBarData?.AirportsFromError1[0] === true && (
                <ErrorMessagePopup
                  Theme={"proxima"}
                  style={{
                    display: "flex",
                    alignItems: "end",
                    width: "max-content",
                    zIndex: 1,
                    backgroundColor: "#FFE0DA",
                    position: "absolute",
                    top: "108%",
                    left: "10px",
                    padding: "4px 8px",
                  }}
                  addClass={searchBarData?.searchClicked}
                  message={searchBarData?.AirportsFromError1[1]}
                />
              )}
              {openPopup === 1 && (
                <From_To_Popup
                  Theme={"proxima"}
                  segmentsData={searchBarData?.searchParams}
                  popular_cities={searchBarData?.popularAirports}
                  recent_searches={
                    getLocalStorageJSON("fts-hm-fa") === null
                      ? []
                      : getLocalStorageJSON("fts-hm-fa")
                  }
                  open={openPopup === 1}
                  position={openPopup}
                  PopupType={[0, 0]}
                  onClickClose={handleClose}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  placeholder={"From"}
                  left={"-1px"}
                  top={"35px"}
                  positionType={"absolute"}
                />
              )}
            </div>

            <div>
              <div
                className="frame-29"
                style={openPopup === 2 ? { backgroundColor: "#E8F3FF" } : {}}
                onClick={() => {
                  setopenPopup(2);
                  setopenPopupActual(2);
                }}
              >
                <div className="frame-99-7">
                  <div
                    className="to proxima_regular color_light-gray-black font14"
                    style={
                      openPopup === 2 && openPopupActual === 2
                        ? { color: "#0057B7" }
                        : {}
                    }
                  >
                    To
                  </div>
                  <div className="airplane-landing-arrival-1"></div>
                </div>
                <div className="place-3 proxima_regular color_eerie-black font24">
                  {searchBarData?.searchParams?.Segments[0]?.DestinationCity}
                </div>
                <div className="atq-amritsar-airport proxima_regular color_light-gray-black font16 text-overflow-elipsis-search">
                  {searchBarData?.searchParams?.Segments[0]?.DestinyCode}
                  {!searchBarData?.searchParams?.Segments[0]?.DestinyCode && (
                    <span style={{ visibility: "hidden" }}>dummy</span>
                  )}
                  {searchBarData?.searchParams?.Segments[0]?.DestinyCode && ","}{" "}
                  {searchBarData?.searchParams?.Segments[0]?.Destiny}
                </div>
              </div>
              <img
                className="exchange-alt"
                src={images["exchange-alt-6.svg"]}
                alt="exchange-alt"
                onClick={() => dispatch(setExchangeSearchParas(0))}
              />
              {searchBarData?.AirportsToError1[0] === true && (
                <ErrorMessagePopup
                  Theme={"proxima"}
                  style={{
                    display: "flex",
                    alignItems: "end",
                    width: "max-content",
                    zIndex: 1,
                    backgroundColor: "#FFE0DA",
                    position: "absolute",
                    top: "108%",
                    left: "300px",
                    padding: "4px 8px",
                  }}
                  addClass={searchBarData?.searchClicked}
                  message={searchBarData?.AirportsToError1[1]}
                />
              )}
              {openPopup === 2 && openPopupActual === 2 && (
                <From_To_Popup
                  Theme={"proxima"}
                  segmentsData={searchBarData?.searchParams}
                  popular_cities={searchBarData?.popularAirports}
                  recent_searches={
                    getLocalStorageJSON("fts-hm-ta") === null
                      ? []
                      : getLocalStorageJSON("fts-hm-ta")
                  }
                  open={openPopup === 2}
                  position={openPopup}
                  PopupType={[0, 1]}
                  onClickClose={handleClose}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  left={"301px"}
                  top={"35px"}
                  positionType={"absolute"}
                  placeholder={"To"}
                />
              )}
            </div>

            <div>
              <div
                className="frame-30"
                style={openPopup === 3 ? { backgroundColor: "#E8F3FF" } : {}}
                onClick={() => {
                  setopenPopup(3);
                  setopenPopupActual(3);
                }}
              >
                <div className="frame-103-9">
                  <div
                    className="departure proxima_regular color_light-gray-black font14"
                    style={openPopup === 3 ? { color: "#0057B7" } : {}}
                  >
                    Departure
                  </div>
                </div>
                <div className="frame proxima_regular color_eerie-black font24">
                  <div className="address">
                    {getFirstTwoLettersOfDate(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}{" "}
                    {getThreeLetterMonth(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}
                    `
                  </div>
                  <div className="text-9">
                    {getTwoLetterYear(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}
                  </div>
                </div>
                <div className="tuesday proxima_regular color_light-gray-black font16">
                  {getDayOfTheDate(
                    searchBarData?.searchParams?.Segments[0]?.OriginDate
                  )}
                </div>
              </div>

              {openPopup === 3 && openPopupActual === 3 && (
                <RangeCalender
                  Theme={"proxima"}
                  marginTop={"0px"}
                  left={"599px"}
                  showTopButtons={false}
                  Buttonelements={
                    <div style={{ display: "flex", width: "max-content" }}>
                      <div
                        className="frame-30"
                        style={
                          openPopup === 3 ? { backgroundColor: "#E8F3FF" } : {}
                        }
                        onClick={() => {
                          setopenPopup(3);
                          setopenPopupActual(3);
                        }}
                      >
                        <div className="frame-103-9">
                          <div
                            className="departure proxima_regular color_light-gray-black font14"
                            style={openPopup === 3 ? { color: "#0057B7" } : {}}
                          >
                            Departure
                          </div>
                        </div>
                      </div>

                      <div
                        className="frame-31"
                        onClick={() => {
                          dispatch(setSearchBarMode(2));
                          setopenPopup(9);
                          setopenPopupActual(9);
                        }}
                      >
                        <div className="return proxima_regular color_light-gray-black font14">
                          Return
                        </div>
                      </div>
                    </div>
                  }
                  open={openPopup === 3}
                  position={openPopup}
                  onClickClose={() => {
                    setopenPopup(5);
                    setopenPopupActual(5);
                  }}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  minimumDate={new Date().setDate(new Date().getDate() - 1)}
                  CalenderValues={calendarValues}
                  selectedDate={
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )
                  } // Currently selected date
                  setSelectedDate={changeTheDate}
                  showReturnDate={true}
                  returnDateValue={"Add Return Date To Save More"}
                  showNumberOfDaysCount={false}
                  activeHeaderBar={1}
                  onClickDepart={null}
                  onClickReturn={() => {
                    dispatch(setSearchBarMode(2));
                    setopenPopup(9);
                    setopenPopupActual(9);
                    // alert("here");
                  }}
                  isReturnCalender={false}
                  range={[null, null]}
                />
              )}
            </div>

            <div
              className="frame-31"
              onClick={() => {
                dispatch(setSearchBarMode(2));
                setopenPopup(9);
                setopenPopupActual(9);
              }}
            >
              <div className="return proxima_regular color_light-gray-black font14">
                Return
              </div>
              <div className="add-return">Add Return</div>
              <div className="to-save-more proxima_regular color_light-gray-black font16">
                To Save More
              </div>
            </div>

            <div>
              <div
                className="frame-32"
                onClick={() => {
                  setopenPopup(5);
                  setopenPopupActual(5);
                }}
              >
                <div
                  className="travellers-and-class proxima_regular color_light-gray-black font14"
                  style={openPopup === 5 ? { color: "#0057B7" } : {}}
                >
                  Travellers and Class
                </div>
                <div className="address-1 proxima_regular color_eerie-black font24">
                  {searchBarData?.searchParamsTravellers?.Adults +
                    searchBarData?.searchParamsTravellers?.Childs +
                    searchBarData?.searchParamsTravellers?.Infrants}{" "}
                  Traveller
                </div>
                <div className="place-4 proxima_regular color_light-gray-black font16">
                  {searchBarData?.searchParamsFare?.class}
                </div>
                {searchBarData?.searchParamsTravellers
                  ?.TravellersAndClassesErrorOut === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display: "flex",
                      alignItems: "end",
                      width: " max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "108%",
                      left: "-30%",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={TravellersFareMessage(
                      searchBarData?.searchParamsFare?.type
                    )}
                  />
                )}
              </div>
              {openPopup === 5 && openPopupActual === 5 && (
                <TravellersPopup
                  Theme={"proxima"}
                  open={openPopup === 5}
                  position={openPopup}
                  onClickClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  travellersData={travellersData}
                  classData={classData}
                  timeOutFunc={setTravellersErrors}
                  setTravellersAndClassesFunc={setTravellersAndClasses}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}





import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./TravellersSection.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Call_Continue2,
  setairlineNameFormatAccepted,
  setcurrentSection,
  setmainDialCode,
  setOpenPopupMini,
  setOpenPopupMiniType,
  setTravellerCountryDetails,
  setTravellerGender,
  setTravellerGstData,
  setTravellerLastName,
  setTravellerPassportIssuingCountry,
} from "../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE";

import images from "../../../../Utils/importImages";
import TravellersAccordian from "./Components/TravellersAccordian";
import ContactDetails from "./Components/ContactDetails";
import GstDetails from "./Components/GstDetails";
import {
  extractFirstDate,
  validateDob,
  validateEmail,
  validateFName,
  validatefrequentFlyerDetailsAirline,
  validatefrequentFlyerDetailsNumber,
  validateGSTCompanyName,
  validateGSTNumber,
  validateLName,
  validatePassportExpiryDate,
  validatePassportIssueDate,
  validatePassportNumber,
  validatePhoneNumber,
  validateTitle,
} from "../../../../Utils/Flight_review_func";
import { Countrieslist } from "../../../../Constants/CountriesAndCodes";
import { isValidPhoneNumber } from "libphonenumber-js";
import EnsureBar from "./Components/EnsureBar";
import ErrorMessagePopup from "../../../Components/Popups/ErrorMessagePopup";
import ErrorMessageBar from "../Components/ErrorMessageBar";

function TravellersSection({
  firstNameLimit,
  lastNameLimit,
  airlineNameFormat,
  IsPassportRequired,
  Theme,
  Data,
  startButtonShaking,
  SearchResultType,
}) {
  let TravellersDataFilledUp = useSelector(
    (state) => state.ReviewPageFlight.TravellersDataFilledUp
  );
  let DialCodeDefault = useSelector(
    (state) => state.ReviewPageFlight.mainDialCode
  );
  let mainEmail = useSelector((state) => state.ReviewPageFlight.mainMail);
  let mainphone = useSelector((state) => state.ReviewPageFlight.mainPhone);
  let gstAvailable = useSelector(
    (state) => state.ReviewPageFlight.gstAvailable
  );
  let gstCompany = useSelector((state) => state.ReviewPageFlight.gstCompany);
  let gstNumber = useSelector((state) => state.ReviewPageFlight.gstNumber);
  let airlineNameFormatAccepted = useSelector(
    (state) => state.ReviewPageFlight.airlineNameFormatAccepted
  );
  let OpenPopupMini = useSelector(
    (state) => state.ReviewPageFlight.OpenPopupMini
  );
  let searchKey = useSelector(
    (state) => state.ReviewPageFlight.searchKey
  );
  let fareType = useSelector(
    (state) => state.ReviewPageFlight.fareType
  );

  let [insureContinue, setInsureContinue] = useState(false);
  // Get the user's default dial code from Redux
  let userDC = useSelector(
    (state) => state.user.UserCurrentLocationData.dial_code
  );

  let dispatch = useDispatch();

  const scrollToSection = (name) => {
    const wrapper = document.querySelector("." + name);
    if (wrapper) {
      wrapper.scrollIntoView({ behavior: "smooth", block: "center" }); // You can use 'auto' instead of 'smooth' for instant scrolling
    }
  };

  let [activeErrorTraveller, setactiveErrorTraveller] = useState("");

  useEffect(() => {
    let timeoutId;

    if (activeErrorTraveller !== "") {
      // Set a timeout to dispatch the action after 2 seconds
      timeoutId = setTimeout(() => {
        setactiveErrorTraveller("");
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [activeErrorTraveller]);

  // let continueButtonLoading = true;
  let continueButtonLoading = useSelector(
    (state) => state.ReviewPageFlight.continueButtonLoading
  );

  let continueButtonError = useSelector(
    (state) => state.ReviewPageFlight.continueButtonError
  );

  let CheckandProceed = (TypeOfBtn) => {

    // Validate Trvaellers
    const defaultCountrySelected = Countrieslist?.find(
      (country) => country.phone === userDC
    );

    console.log("defaultCountrySelected is : ", defaultCountrySelected);

    let hasError = false;
    for (let i = 0; i < TravellersDataFilledUp.length; i++) {
      const element = TravellersDataFilledUp[i];
      
      if (validateTitle(element.title) == true) {
        setactiveErrorTraveller(`Traveller${i}`);
        scrollToSection(`Traveller${i}`);
        hasError = true;

        break;
      }
      if (validateFName(element.firstName, firstNameLimit) == true) {
        setactiveErrorTraveller(`Traveller${i}`);
        scrollToSection(`Traveller${i}`);

        hasError = true;
        break;
      }
      if (validateLName(element.lastName, lastNameLimit) == true) {
        setactiveErrorTraveller(`Traveller${i}`);
        scrollToSection(`Traveller${i}`);

        hasError = true;
        break;
      }
      if (validateDob(element.dob, element.type) == true) {
        setactiveErrorTraveller(`Traveller${i}`);
        scrollToSection(`Traveller${i}`);

        hasError = true;
        break;
      }
      if (element.gender === "") {
        dispatch(
          setTravellerGender({
            index: i,
            data:
              element.title == "Mr" || element.title == "Mstr"
                ? "Male"
                : "Female",
          })
        );
      }
      if (element.countryCode == "") {
        let data = {
          city: defaultCountrySelected.name,
          countryCode: defaultCountrySelected.code,
          countryName: defaultCountrySelected.name,
          nationality: defaultCountrySelected.nationality,
        };

        dispatch(setTravellerCountryDetails({ index: i, data: data }));
      }
      if (IsPassportRequired == true) {
        if (
          validatePassportNumber(element.passportDetails.PassportNo) == true
        ) {
          setactiveErrorTraveller(`Traveller${i}`);
          scrollToSection(`Traveller${i}`);
          hasError = true;

          break;
        }

        if (element.passportDetails.PassportIssuingCountry == "") {
          dispatch(
            setTravellerPassportIssuingCountry({
              index: i,
              data: defaultCountrySelected.code,
            })
          );
        }

        if (
          validatePassportIssueDate(
            element.passportDetails.PassportIssueDate
          ) == true
        ) {
          setactiveErrorTraveller(`Traveller${i}`);
          scrollToSection(`Traveller${i}`);
          hasError = true;
          break;
        }
        if (
          validatePassportExpiryDate(element.passportDetails.PassportExpiry , extractFirstDate(searchKey)) ==
          true
        ) {
          setactiveErrorTraveller(`Traveller${i}`);

          scrollToSection(`Traveller${i}`);
          hasError = true;
          break;
        }
      }

      if (element.frequentFlyerDetails.available == true) {
        if (
          validatefrequentFlyerDetailsNumber(
            element.frequentFlyerDetails.number
          ) == true
        ) {
          setactiveErrorTraveller(`Traveller${i}`);

          scrollToSection(`Traveller${i}`);
          hasError = true;
          break;
        }
        if (
          validatefrequentFlyerDetailsAirline(
            element.frequentFlyerDetails.airline
          ) == true
        ) {
          setactiveErrorTraveller(`Traveller${i}`);
          scrollToSection(`Traveller${i}`);
          hasError = true;
          break;
        }
      }
    }

    // validate phone and email

    if (hasError == false) {
      const defaultCountry = Countrieslist?.find((country) =>
        DialCodeDefault == ""
          ? country.phone === userDC
          : country.phone === DialCodeDefault
      );

      if (DialCodeDefault == "") {
        dispatch(setmainDialCode(defaultCountry.phone));
      }
      if (validateEmail(mainEmail) == true) {
        setactiveErrorTraveller(`contact-error`);
        scrollToSection(`contact-details`);
        hasError = true;
      }
      if (
        validatePhoneNumber(
          mainphone,
          defaultCountry.code,
          isValidPhoneNumber
        ) == true
      ) {
        setactiveErrorTraveller(`contact-error`);
        scrollToSection(`contact-details`);
        hasError = true;
      }
    }

    if (hasError == false) {
      if (gstAvailable == true) {
        if (validateGSTCompanyName(gstCompany) == true) {
          setactiveErrorTraveller(`gst-error`);
          scrollToSection(`gst-details`);
          hasError = true;
        }
        if (validateGSTNumber(gstNumber) == true) {
          setactiveErrorTraveller(`gst-error`);
          scrollToSection(`gst-details`);
          hasError = true;
        }
      }
    }

    if (hasError == false) {
      if (gstAvailable == true) {
        // enter the gst details into the travellers data

        for (let i = 0; i < TravellersDataFilledUp.length; i++) {
          dispatch(
            setTravellerGstData({
              index: i,
              data: {
                name: gstCompany,
                number: gstNumber,
              },
            })
          );
        }
      }
    }

    if (hasError == false) {
      if (insureContinue == false) {
        setactiveErrorTraveller(`ensure-error`);
        scrollToSection(`ensure-details`);
        hasError = true;
      }
    }

    // Every Thing Ok

    // just tell the user about name format and move to section 3

    let withNoLNameFound = false;
    if (hasError == false) {
        // check if any lastname is empty
        for (let i = 0; i < TravellersDataFilledUp.length; i++) {
          if (TravellersDataFilledUp[i].lastName == "") {
            withNoLNameFound = true
            break;
          }
        }
    }

    let airlineNameFormatDummy = false;

    if(withNoLNameFound == false){
      dispatch(setairlineNameFormatAccepted(true));
      airlineNameFormatDummy = true;
    }
    if(withNoLNameFound == true && (airlineNameFormatDummy == false && airlineNameFormatAccepted == false)){
      dispatch(setOpenPopupMiniType("NameFormat"));
      dispatch(setOpenPopupMini(true));
    }

    if (OpenPopupMini == false && hasError == false && (airlineNameFormatDummy == true || airlineNameFormatAccepted == true)) {
      dispatch(Call_Continue2(TypeOfBtn));
    }

  };


  return (
    <>
      <div className="main-section-wrapper">
        <div className="main-data-section">
          <div
            className={`flight-details-1 ${Theme}_semibold color_${
              Theme == "proxima" ? "magic-potion" : "optera-galaxy"
            } font16`}
          >
            ADD TRAVELLER DETAILS
          </div>

          <div className="travellers-info-section">
            <div className={`${Theme}_semibold color_deep-ocean font14`}>
              Important:
            </div>
            <div className={`${Theme}_regular color_deep-ocean font14`}>
              {
                IsPassportRequired == true ? 
                "Enter your name as it is mentioned on your passport. Passport should be valid for minimum 6 months from the date of travel." : "Enter name as mentioned on your passport or Government approved IDs."
              }
       
            </div>
          </div>

          <div className={`travellers-wrapper ${Theme}`}>
            {TravellersDataFilledUp?.map((item, id) => {
              return (
                <TravellersAccordian
                fareType={fareType}
                  classNameTop={`Traveller${id}`}
                  isActiveError={activeErrorTraveller == `Traveller${id}`}
                  userDC={userDC}
                  Item={item}
                  Index={id}
                  Theme={Theme}
                  Type={
                    item.type == "ADT"
                      ? "Adult"
                      : item.type == "CHD"
                      ? "Child"
                      : "Infant"
                  }
                  icon={
                    images[

                      `${
                        item.type == "ADT"
                          ? "adult"
                          : item.type == "CHD"
                          ? "child"
                          : "infant"
                      }-icon-${Theme == "proxima" ? "blue" : "gold"}.svg`
                    ]
                  }
                  name={
                    item.firstName == ""
                      ? `Traveller ${id + 1}`
                      : `${item.firstName} ${item.lastName}`
                  }
                  isOpen={id == 0}
                  showAddNextTraveller={
                    id !== TravellersDataFilledUp.length - 1
                  }
                  firstNameLimit={firstNameLimit}
                  lastNameLimit={lastNameLimit}
                  IsPassportRequired={IsPassportRequired}
                  DateOfTravel={extractFirstDate(searchKey)}
                  TitlesProp={
                    item.type == "ADT" ? ["Mr", "Mrs", "Ms"] : ["Mstr", "Miss"]
                  }
                />
              );
            })}
          </div>

          <ContactDetails
            hasError={activeErrorTraveller == `contact-error`}
            Theme={Theme}
            userDC={userDC}
            DialCode={DialCodeDefault}
            Email={mainEmail}
            Phone={mainphone}
          />

          <GstDetails
            hasError={activeErrorTraveller == `gst-error`}
            Theme={Theme}
          />

          <EnsureBar
            hasError={activeErrorTraveller == `ensure-error`}
            Theme={Theme}
            insureContinue={insureContinue}
            setInsureContinue={setInsureContinue}
          />
        </div>

<div className={`continue-section continue-section-2`}>
        {!!continueButtonError == true && (
          <ErrorMessageBar Theme={Theme} Message={continueButtonError} />
        )}

        <div
          style={continueButtonLoading == true ? { opacity: "0.5" } : {}}
          className={`frame-259 ${Theme} btn-ctn-click ${
            startButtonShaking == true && "shake"
          }`}
          onClick={() => continueButtonLoading == true ? null : CheckandProceed(1)}
        >
          <div
            className={`continue valign-text-middle ${Theme}_semibold color_white font16`}
          >
              Continue {continueButtonLoading == true && <div className="loader"></div>}
          </div>
        </div>

        <div className="info-btn" onClick={() => continueButtonLoading == true ? null : CheckandProceed(2)}>
          <div className={`${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font16 skiptoPay`}>
            Skip To Payment 
          </div>
        </div>

      </div>

          <div className={`others-section ${Theme}`}>
          <div
            className={`text ${Theme}_semibold color_${
              Theme == "proxima" ? "rhino" : "optera-galaxy"
            } font16`}
          >
            {Theme == "proxima" ? "SEATS, MEALS & BAGGAGE" : "ADD-ONS"}
          </div>
        </div>
      </div>
    </>
  );
}

export default TravellersSection;

export const Countrieslist = [
  { code: "AD", name: "Andorra", phone: "+376", nationality: "Andorran" },
  {
    code: "AE",
    name: "United Arab Emirates",
    phone: "+971",
    nationality: "Emirati",
  },
  { code: "AF", name: "Afghanistan", phone: "+93", nationality: "Afghan" },
  {
    code: "AG",
    name: "Antigua and Barbuda",
    phone: "+1-268",
    nationality: "Antiguan or Barbudan",
  },
  { code: "AI", name: "Anguilla", phone: "+1-264", nationality: "Anguillan" },
  { code: "AL", name: "Albania", phone: "+355", nationality: "Albanian" },
  { code: "AM", name: "Armenia", phone: "+374", nationality: "Armenian" },
  { code: "AO", name: "Angola", phone: "+244", nationality: "Angolan" },
  { code: "AQ", name: "Antarctica", phone: "+672", nationality: "Antarctic" },
  { code: "AR", name: "Argentina", phone: "+54", nationality: "Argentine" },
  {
    code: "AS",
    name: "American Samoa",
    phone: "+1-684",
    nationality: "American Samoan",
  },
  { code: "AT", name: "Austria", phone: "+43", nationality: "Austrian" },
  {
    code: "AU",
    name: "Australia",
    phone: "+61",
    nationality: "Australian",
    suggested: true,
  },
  { code: "AW", name: "Aruba", phone: "+297", nationality: "Aruban" },
  {
    code: "AX",
    name: "Alland Islands",
    phone: "+358",
    nationality: "Åland Islander",
  },
  { code: "AZ", name: "Azerbaijan", phone: "+994", nationality: "Azerbaijani" },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
    phone: "+387",
    nationality: "Bosnian or Herzegovinian",
  },
  { code: "BB", name: "Barbados", phone: "+1-246", nationality: "Barbadian" },
  { code: "BD", name: "Bangladesh", phone: "+880", nationality: "Bangladeshi" },
  { code: "BE", name: "Belgium", phone: "+32", nationality: "Belgian" },
  { code: "BF", name: "Burkina Faso", phone: "+226", nationality: "Burkinabe" },
  { code: "BG", name: "Bulgaria", phone: "+359", nationality: "Bulgarian" },
  { code: "BH", name: "Bahrain", phone: "+973", nationality: "Bahraini" },
  { code: "BI", name: "Burundi", phone: "+257", nationality: "Burundian" },
  { code: "BJ", name: "Benin", phone: "+229", nationality: "Beninese" },
  {
    code: "BL",
    name: "Saint Barthelemy",
    phone: "+590",
    nationality: "Saint Barthélemy Islander",
  },
  { code: "BM", name: "Bermuda", phone: "+1-441", nationality: "Bermudian" },
  {
    code: "BN",
    name: "Brunei Darussalam",
    phone: "+673",
    nationality: "Bruneian",
  },
  { code: "BO", name: "Bolivia", phone: "+591", nationality: "Bolivian" },
  { code: "BR", name: "Brazil", phone: "+55", nationality: "Brazilian" },
  { code: "BS", name: "Bahamas", phone: "+1-242", nationality: "Bahamian" },
  { code: "BT", name: "Bhutan", phone: "+975", nationality: "Bhutanese" },
  {
    code: "BV",
    name: "Bouvet Island",
    phone: "+47",
    nationality: "Bouvet Islander",
  },
  { code: "BW", name: "Botswana", phone: "+267", nationality: "Motswana" },
  { code: "BY", name: "Belarus", phone: "+375", nationality: "Belarusian" },
  { code: "BZ", name: "Belize", phone: "+501", nationality: "Belizean" },
  {
    code: "CA",
    name: "Canada",
    phone: "+1",
    nationality: "Canadian",
    suggested: true,
  },
  {
    code: "CC",
    name: "Cocos (Keeling) Islands",
    phone: "+61",
    nationality: "Cocos Islander",
  },
  {
    code: "CD",
    name: "Congo, Democratic Republic of the",
    phone: "+243",
    nationality: "Congolese (DRC)",
  },
  {
    code: "CF",
    name: "Central African Republic",
    phone: "+236",
    nationality: "Central African",
  },
  {
    code: "CG",
    name: "Congo, Republic of the",
    phone: "+242",
    nationality: "Congolese (Republic)",
  },
  { code: "CH", name: "Switzerland", phone: "+41", nationality: "Swiss" },
  { code: "CI", name: "Cote d'Ivoire", phone: "+225", nationality: "Ivorian" },
  {
    code: "CK",
    name: "Cook Islands",
    phone: "+682",
    nationality: "Cook Islander",
  },
  { code: "CL", name: "Chile", phone: "+56", nationality: "Chilean" },
  { code: "CM", name: "Cameroon", phone: "+237", nationality: "Cameroonian" },
  { code: "CN", name: "China", phone: "+86", nationality: "Chinese" },
  { code: "CO", name: "Colombia", phone: "+57", nationality: "Colombian" },
  { code: "CR", name: "Costa Rica", phone: "+506", nationality: "Costa Rican" },
  { code: "CU", name: "Cuba", phone: "+53", nationality: "Cuban" },
  {
    code: "CV",
    name: "Cape Verde",
    phone: "+238",
    nationality: "Cape Verdean",
  },
  { code: "CW", name: "Curacao", phone: "+599", nationality: "Curaçaoan" },
  {
    code: "CX",
    name: "Christmas Island",
    phone: "+61",
    nationality: "Christmas Islander",
  },
  { code: "CY", name: "Cyprus", phone: "+357", nationality: "Cypriot" },
  { code: "CZ", name: "Czech Republic", phone: "+420", nationality: "Czech" },
  {
    code: "DE",
    name: "Germany",
    phone: "+49",
    nationality: "German",
    suggested: true,
  },
  { code: "DJ", name: "Djibouti", phone: "+253", nationality: "Djiboutian" },
  { code: "DK", name: "Denmark", phone: "+45", nationality: "Danish" },
  { code: "DM", name: "Dominica", phone: "+1-767", nationality: "Dominican" },
  {
    code: "DO",
    name: "Dominican Republic",
    phone: "+1-809",
    nationality: "Dominican",
  },
  { code: "DZ", name: "Algeria", phone: "+213", nationality: "Algerian" },
  { code: "EC", name: "Ecuador", phone: "+593", nationality: "Ecuadorian" },
  { code: "EE", name: "Estonia", phone: "+372", nationality: "Estonian" },
  { code: "EG", name: "Egypt", phone: "+20", nationality: "Egyptian" },
  { code: "EH", name: "Western Sahara", phone: "+212", nationality: "Sahrawi" },
  { code: "ER", name: "Eritrea", phone: "+291", nationality: "Eritrean" },
  { code: "ES", name: "Spain", phone: "+34", nationality: "Spanish" },
  { code: "ET", name: "Ethiopia", phone: "+251", nationality: "Ethiopian" },
  { code: "FI", name: "Finland", phone: "+358", nationality: "Finnish" },
  { code: "FJ", name: "Fiji", phone: "+679", nationality: "Fijian" },
  {
    code: "FK",
    name: "Falkland Islands (Malvinas)",
    phone: "+500",
    nationality: "Falkland Islander",
  },
  {
    code: "FM",
    name: "Micronesia, Federated States of",
    phone: "+691",
    nationality: "Micronesian",
  },
  { code: "FO", name: "Faroe Islands", phone: "+298", nationality: "Faroese" },
  {
    code: "FR",
    name: "France",
    phone: "+33",
    nationality: "French",
    suggested: true,
  },
  { code: "GA", name: "Gabon", phone: "+241", nationality: "Gabonese" },
  { code: "GB", name: "United Kingdom", phone: "+44", nationality: "British" },
  { code: "GD", name: "Grenada", phone: "+1-473", nationality: "Grenadian" },
  { code: "GE", name: "Georgia", phone: "+995", nationality: "Georgian" },
  {
    code: "GF",
    name: "French Guiana",
    phone: "+594",
    nationality: "French Guianese",
  },
  {
    code: "GG",
    name: "Guernsey",
    phone: "+44-1481",
    nationality: "Channel Islander",
  },
  { code: "GH", name: "Ghana", phone: "+233", nationality: "Ghanaian" },
  { code: "GI", name: "Gibraltar", phone: "+350", nationality: "Gibraltar" },
  { code: "GL", name: "Greenland", phone: "+299", nationality: "Greenlandic" },
  { code: "GM", name: "Gambia", phone: "+220", nationality: "Gambian" },
  { code: "GN", name: "Guinea", phone: "+224", nationality: "Guinean" },
  {
    code: "GP",
    name: "Guadeloupe",
    phone: "+590",
    nationality: "Guadeloupean",
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
    phone: "+240",
    nationality: "Equatorial Guinean",
  },
  { code: "GR", name: "Greece", phone: "+30", nationality: "Greek" },
  { code: "GT", name: "Guatemala", phone: "+502", nationality: "Guatemalan" },
  { code: "GU", name: "Guam", phone: "+1-671", nationality: "Guamanian" },
  {
    code: "GW",
    name: "Guinea-Bissau",
    phone: "+245",
    nationality: "Bissau-Guinean",
  },
  { code: "GY", name: "Guyana", phone: "+592", nationality: "Guyanese" },
  { code: "HK", name: "Hong Kong", phone: "+852", nationality: "Hong Konger" },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands",
    phone: "+672",
    nationality: "Heard Islander",
  },
  { code: "HN", name: "Honduras", phone: "+504", nationality: "Honduran" },
  { code: "HR", name: "Croatia", phone: "+385", nationality: "Croatian" },
  { code: "HT", name: "Haiti", phone: "+509", nationality: "Haitian" },
  { code: "HU", name: "Hungary", phone: "+36", nationality: "Hungarian" },
  { code: "ID", name: "Indonesia", phone: "+62", nationality: "Indonesian" },
  { code: "IE", name: "Ireland", phone: "+353", nationality: "Irish" },
  { code: "IL", name: "Israel", phone: "+972", nationality: "Israeli" },
  { code: "IM", name: "Isle of Man", phone: "+44-1624", nationality: "Manx" },
  {
    code: "IN",
    name: "India",
    phone: "+91",
    nationality: "Indian",
    suggested: true,
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
    phone: "+246",
    nationality: "Indian Ocean Territory",
  },
  { code: "IQ", name: "Iraq", phone: "+964", nationality: "Iraqi" },
  {
    code: "IR",
    name: "Iran, Islamic Republic of",
    phone: "+98",
    nationality: "Iranian",
  },
  { code: "IS", name: "Iceland", phone: "+354", nationality: "Icelandic" },
  { code: "IT", name: "Italy", phone: "+39", nationality: "Italian" },
  {
    code: "JE",
    name: "Jersey",
    phone: "+44-1534",
    nationality: "Channel Islander",
  },
  { code: "JM", name: "Jamaica", phone: "+1-876", nationality: "Jamaican" },
  { code: "JO", name: "Jordan", phone: "+962", nationality: "Jordanian" },
  {
    code: "JP",
    name: "Japan",
    phone: "+81",
    nationality: "Japanese",
    suggested: true,
  },
  { code: "KE", name: "Kenya", phone: "+254", nationality: "Kenyan" },
  { code: "KG", name: "Kyrgyzstan", phone: "+996", nationality: "Kyrgyzstani" },
  { code: "KH", name: "Cambodia", phone: "+855", nationality: "Cambodian" },
  { code: "KI", name: "Kiribati", phone: "+686", nationality: "I-Kiribati" },
  { code: "KM", name: "Comoros", phone: "+269", nationality: "Comorian" },
  {
    code: "KN",
    name: "Saint Kitts and Nevis",
    phone: "+1-869",
    nationality: "Kittitian or Nevisian",
  },
  {
    code: "KP",
    name: "Korea, Democratic People's Republic of",
    phone: "+850",
    nationality: "North Korean",
  },
  {
    code: "KR",
    name: "Korea, Republic of",
    phone: "+82",
    nationality: "South Korean",
  },
  { code: "KW", name: "Kuwait", phone: "+965", nationality: "Kuwaiti" },
  {
    code: "KY",
    name: "Cayman Islands",
    phone: "+1-345",
    nationality: "Caymanian",
  },
  { code: "KZ", name: "Kazakhstan", phone: "+7", nationality: "Kazakhstani" },
  {
    code: "LA",
    name: "Lao People's Democratic Republic",
    phone: "+856",
    nationality: "Lao",
  },
  { code: "LB", name: "Lebanon", phone: "+961", nationality: "Lebanese" },
  {
    code: "LC",
    name: "Saint Lucia",
    phone: "+1-758",
    nationality: "Saint Lucian",
  },
  {
    code: "LI",
    name: "Liechtenstein",
    phone: "+423",
    nationality: "Liechtensteiner",
  },
  { code: "LK", name: "Sri Lanka", phone: "+94", nationality: "Sri Lankan" },
  { code: "LR", name: "Liberia", phone: "+231", nationality: "Liberian" },
  { code: "LS", name: "Lesotho", phone: "+266", nationality: "Basotho" },
  { code: "LT", name: "Lithuania", phone: "+370", nationality: "Lithuanian" },
  {
    code: "LU",
    name: "Luxembourg",
    phone: "+352",
    nationality: "Luxembourgish",
  },
  { code: "LV", name: "Latvia", phone: "+371", nationality: "Latvian" },
  { code: "LY", name: "Libya", phone: "+218", nationality: "Libyan" },
  { code: "MA", name: "Morocco", phone: "+212", nationality: "Moroccan" },
  { code: "MC", name: "Monaco", phone: "+377", nationality: "Monegasque" },
  {
    code: "MD",
    name: "Moldova, Republic of",
    phone: "+373",
    nationality: "Moldovan",
  },
  { code: "ME", name: "Montenegro", phone: "+382", nationality: "Montenegrin" },
  {
    code: "MF",
    name: "Saint Martin (French part)",
    phone: "+590",
    nationality: "Saint-Martinois",
  },
  { code: "MG", name: "Madagascar", phone: "+261", nationality: "Malagasy" },
  {
    code: "MH",
    name: "Marshall Islands",
    phone: "+692",
    nationality: "Marshallese",
  },
  {
    code: "MK",
    name: "Macedonia, the Former Yugoslav Republic of",
    phone: "+389",
    nationality: "Macedonian",
  },
  { code: "ML", name: "Mali", phone: "+223", nationality: "Malian" },
  { code: "MM", name: "Myanmar", phone: "+95", nationality: "Burmese" },
  { code: "MN", name: "Mongolia", phone: "+976", nationality: "Mongolian" },
  { code: "MO", name: "Macao", phone: "+853", nationality: "Macanese" },
  {
    code: "MP",
    name: "Northern Mariana Islands",
    phone: "+1-670",
    nationality: "Northern Marianan",
  },
  { code: "MQ", name: "Martinique", phone: "+596", nationality: "Martinican" },
  { code: "MR", name: "Mauritania", phone: "+222", nationality: "Mauritanian" },
  {
    code: "MS",
    name: "Montserrat",
    phone: "+1-664",
    nationality: "Montserratian",
  },
  { code: "MT", name: "Malta", phone: "+356", nationality: "Maltese" },
  { code: "MU", name: "Mauritius", phone: "+230", nationality: "Mauritian" },
  { code: "MV", name: "Maldives", phone: "+960", nationality: "Maldivian" },
  { code: "MW", name: "Malawi", phone: "+265", nationality: "Malawian" },
  { code: "MX", name: "Mexico", phone: "+52", nationality: "Mexican" },
  { code: "MY", name: "Malaysia", phone: "+60", nationality: "Malaysian" },
  { code: "MZ", name: "Mozambique", phone: "+258", nationality: "Mozambican" },
  { code: "NA", name: "Namibia", phone: "+264", nationality: "Namibian" },
  {
    code: "NC",
    name: "New Caledonia",
    phone: "+687",
    nationality: "New Caledonian",
  },
  { code: "NE", name: "Niger", phone: "+227", nationality: "Nigerien" },
  {
    code: "NF",
    name: "Norfolk Island",
    phone: "+672",
    nationality: "Norfolk Islander",
  },
  { code: "NG", name: "Nigeria", phone: "+234", nationality: "Nigerian" },
  { code: "NI", name: "Nicaragua", phone: "+505", nationality: "Nicaraguan" },
  { code: "NL", name: "Netherlands", phone: "+31", nationality: "Dutch" },
  { code: "NO", name: "Norway", phone: "+47", nationality: "Norwegian" },
  { code: "NP", name: "Nepal", phone: "+977", nationality: "Nepali" },
  { code: "NR", name: "Nauru", phone: "+674", nationality: "Nauruan" },
  { code: "NU", name: "Niue", phone: "+683", nationality: "Niuean" },
  {
    code: "NZ",
    name: "New Zealand",
    phone: "+64",
    nationality: "New Zealander",
  },
  { code: "OM", name: "Oman", phone: "+968", nationality: "Omani" },
  { code: "PA", name: "Panama", phone: "+507", nationality: "Panamanian" },
  { code: "PE", name: "Peru", phone: "+51", nationality: "Peruvian" },
  {
    code: "PF",
    name: "French Polynesia",
    phone: "+689",
    nationality: "French Polynesian",
  },
  {
    code: "PG",
    name: "Papua New Guinea",
    phone: "+675",
    nationality: "Papua New Guinean",
  },
  { code: "PH", name: "Philippines", phone: "+63", nationality: "Filipino" },
  { code: "PK", name: "Pakistan", phone: "+92", nationality: "Pakistani" },
  { code: "PL", name: "Poland", phone: "+48", nationality: "Polish" },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
    phone: "+508",
    nationality: "Saint-Pierrais or Miquelonnais",
  },
  {
    code: "PN",
    name: "Pitcairn",
    phone: "+64",
    nationality: "Pitcairn Islander",
  },
  {
    code: "PR",
    name: "Puerto Rico",
    phone: "+1-787",
    nationality: "Puerto Rican",
  },
  { code: "PT", name: "Portugal", phone: "+351", nationality: "Portuguese" },
  { code: "PW", name: "Palau", phone: "+680", nationality: "Palauan" },
  { code: "PY", name: "Paraguay", phone: "+595", nationality: "Paraguayan" },
  { code: "QA", name: "Qatar", phone: "+974", nationality: "Qatari" },
  { code: "RE", name: "Réunion", phone: "+262", nationality: "Réunionese" },
  { code: "RO", name: "Romania", phone: "+40", nationality: "Romanian" },
  { code: "RS", name: "Serbia", phone: "+381", nationality: "Serbian" },
  {
    code: "RU",
    name: "Russian Federation",
    phone: "+7",
    nationality: "Russian",
  },
  { code: "RW", name: "Rwanda", phone: "+250", nationality: "Rwandan" },
  {
    code: "SA",
    name: "Saudi Arabia",
    phone: "+966",
    nationality: "Saudi Arabian",
  },
  {
    code: "SB",
    name: "Solomon Islands",
    phone: "+677",
    nationality: "Solomon Islander",
  },
  { code: "SC", name: "Seychelles", phone: "+248", nationality: "Seychellois" },
  { code: "SD", name: "Sudan", phone: "+249", nationality: "Sudanese" },
  { code: "SE", name: "Sweden", phone: "+46", nationality: "Swedish" },
  { code: "SG", name: "Singapore", phone: "+65", nationality: "Singaporean" },
  {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    phone: "+290",
    nationality: "Saint Helenian",
  },
  { code: "SI", name: "Slovenia", phone: "+386", nationality: "Slovene" },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    phone: "+47",
    nationality: "Norwegian",
  },
  { code: "SK", name: "Slovakia", phone: "+421", nationality: "Slovak" },
  {
    code: "SL",
    name: "Sierra Leone",
    phone: "+232",
    nationality: "Sierra Leonean",
  },
  { code: "SM", name: "San Marino", phone: "+378", nationality: "Sammarinese" },
  { code: "SN", name: "Senegal", phone: "+221", nationality: "Senegalese" },
  { code: "SO", name: "Somalia", phone: "+252", nationality: "Somali" },
  { code: "SR", name: "Suriname", phone: "+597", nationality: "Surinamese" },
  {
    code: "SS",
    name: "South Sudan",
    phone: "+211",
    nationality: "South Sudanese",
  },
  {
    code: "ST",
    name: "Sao Tome and Principe",
    phone: "+239",
    nationality: "São Toméan",
  },
  { code: "SV", name: "El Salvador", phone: "+503", nationality: "Salvadoran" },
  {
    code: "SX",
    name: "Sint Maarten (Dutch part)",
    phone: "+1-721",
    nationality: "Sint Maartener",
  },
  {
    code: "SY",
    name: "Syrian Arab Republic",
    phone: "+963",
    nationality: "Syrian",
  },
  { code: "SZ", name: "Eswatini", phone: "+268", nationality: "Swazi" },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    phone: "+1-649",
    nationality: "Turks and Caicos Islander",
  },
  { code: "TD", name: "Chad", phone: "+235", nationality: "Chadian" },
  {
    code: "TF",
    name: "French Southern Territories",
    phone: "+262",
    nationality: "French Southern Territories",
  },
  { code: "TG", name: "Togo", phone: "+228", nationality: "Togolese" },
  { code: "TH", name: "Thailand", phone: "+66", nationality: "Thai" },
  { code: "TJ", name: "Tajikistan", phone: "+992", nationality: "Tajikistani" },
  { code: "TK", name: "Tokelau", phone: "+690", nationality: "Tokelauan" },
  { code: "TL", name: "Timor-Leste", phone: "+670", nationality: "Timorese" },
  { code: "TM", name: "Turkmenistan", phone: "+993", nationality: "Turkmen" },
  { code: "TN", name: "Tunisia", phone: "+216", nationality: "Tunisian" },
  { code: "TO", name: "Tonga", phone: "+676", nationality: "Tongan" },
  { code: "TR", name: "Turkey", phone: "+90", nationality: "Turkish" },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    phone: "+1-868",
    nationality: "Trinidadian or Tobagonian",
  },
  { code: "TV", name: "Tuvalu", phone: "+688", nationality: "Tuvaluan" },
  {
    code: "TW",
    name: "Taiwan, Province of China",
    phone: "+886",
    nationality: "Taiwanese",
  },
  {
    code: "TZ",
    name: "Tanzania, United Republic of",
    phone: "+255",
    nationality: "Tanzanian",
  },
  { code: "UA", name: "Ukraine", phone: "+380", nationality: "Ukrainian" },
  { code: "UG", name: "Uganda", phone: "+256" },
  {
    code: "US",
    name: "United States",
    phone: "+1",
    suggested: true,
  },
  { code: "UY", name: "Uruguay", phone: "+598" },
  { code: "UZ", name: "Uzbekistan", phone: "+998" },
  {
    code: "VA",
    name: "Holy See (Vatican City State)",
    phone: "+379",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    phone: "+1-784",
  },
  { code: "VE", name: "Venezuela", phone: "+58" },
  {
    code: "VG",
    name: "British Virgin Islands",
    phone: "+1-284",
  },
  {
    code: "VI",
    name: "US Virgin Islands",
    phone: "+1-340",
  },
  { code: "VN", name: "Vietnam", phone: "+84" },
  { code: "VU", name: "Vanuatu", phone: "+678" },
  { code: "WF", name: "Wallis and Futuna", phone: "+681" },
  { code: "WS", name: "Samoa", phone: "+685" },
  { code: "XK", name: "Kosovo", phone: "+383" },
  { code: "YE", name: "Yemen", phone: "+967" },
  { code: "YT", name: "Mayotte", phone: "+262" },
  { code: "ZA", name: "South Africa", phone: "+27" },
  { code: "ZM", name: "Zambia", phone: "+260" },
  { code: "ZW", name: "Zimbabwe", phone: "+263" },
  { code: "UG", name: "Uganda", phone: "+256", nationality: "Ugandan" },
  {
    code: "US",
    name: "United States",
    phone: "+1",
    nationality: "American",
    suggested: true,
  },
  { code: "UY", name: "Uruguay", phone: "+598", nationality: "Uruguayan" },
  { code: "UZ", name: "Uzbekistan", phone: "+998", nationality: "Uzbekistani" },
  {
    code: "VA",
    name: "Holy See (Vatican City State)",
    phone: "+379",
    nationality: "Vatican",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    phone: "+1-784",
    nationality: "Saint Vincentian",
  },
  { code: "VE", name: "Venezuela", phone: "+58", nationality: "Venezuelan" },
  {
    code: "VG",
    name: "British Virgin Islands",
    phone: "+1-284",
    nationality: "British Virgin Islander",
  },
  {
    code: "VI",
    name: "US Virgin Islands",
    phone: "+1-340",
    nationality: "American",
  },
  { code: "VN", name: "Vietnam", phone: "+84", nationality: "Vietnamese" },
  { code: "VU", name: "Vanuatu", phone: "+678", nationality: "Vanuatuan" },
  {
    code: "WF",
    name: "Wallis and Futuna",
    phone: "+681",
    nationality: "Wallisian or Futunan",
  },
  { code: "WS", name: "Samoa", phone: "+685", nationality: "Samoan" },
  { code: "XK", name: "Kosovo", phone: "+383", nationality: "Kosovar" },
  { code: "YE", name: "Yemen", phone: "+967", nationality: "Yemeni" },
  { code: "YT", name: "Mayotte", phone: "+262", nationality: "Mahoran" },
  {
    code: "ZA",
    name: "South Africa",
    phone: "+27",
    nationality: "South African",
  },
  { code: "ZM", name: "Zambia", phone: "+260", nationality: "Zambian" },
  { code: "ZW", name: "Zimbabwe", phone: "+263", nationality: "Zimbabwean" },
];

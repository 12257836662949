import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getLocalStorageJSON,
  setLocalStorageJSON,
} from "../../../Utils/localStorageFunc"
import {
  formatDate,
  formatDateRecentSearch,
  formatDateToSeatchParams,
} from "../../../Utils/Date_Time_Calendar_Functions";
import { fetchPopularAirports } from "../../Apis/Flight-Apis";
import {
  SearchBarErrorMessages,
  TravellersFareMessage,
} from "../../../Constants/ConstantsFlights";
import { cleanUpExpiredSearches } from "../../../Utils/Flights_homepage_func";

let recentSearches = [];
let searchParams = null;
let searchParamsTravellers = null;
let searchParamsFare = null;

if (getLocalStorageJSON("fts-hm-sp") === null) {
  searchParams = {
    Segments: [
      {
        OriginCity: "Select Origin Airport",
        DestinationCity: "Select Destiny Airport",
        OriginCode: "",
        DestinyCode: "",
        Origin: "", // airport name
        Destiny: "", // airport name
        OriginCountryCode: "",
        DestinationCountryCode: "",

        OriginDate: formatDate(new Date()), // convert date to this format,
        ReturnDate: formatDate(new Date()), // convert date to this format,
      },
      {
        OriginCity: "Select Origin Airport",
        DestinationCity: "Select Destiny Airport",
        OriginCode: "",
        DestinyCode: "",
        Origin: "",
        Destiny: "",
        OriginCountryCode: "",
        DestinationCountryCode: "",
        OriginDate: formatDate(new Date()), // convert date to this format,
        ReturnDate: null, // convert date to this format,
      },
      {
        OriginCity: "Select Origin Airport",
        DestinationCity: "Select Destiny Airport",
        OriginCode: "",
        DestinyCode: "",
        Origin: "",
        Destiny: "",
        OriginCountryCode: "",
        DestinationCountryCode: "",
        OriginDate: formatDate(new Date()), // convert date to this format,
        ReturnDate: null, // convert date to this format,
      },
      {
        OriginCity: "Select Origin Airport",
        DestinationCity: "Select Destiny Airport",
        OriginCode: "",
        DestinyCode: "",
        Origin: "",
        Destiny: "",
        OriginCountryCode: "",
        DestinationCountryCode: "",
        OriginDate: formatDate(new Date()), // convert date to this format,
        ReturnDate: null, // convert date to this format,
      },
    ],
  };
} else {
  searchParams = getLocalStorageJSON("fts-hm-sp");

  // Fresh the Dates if expired
  const today = new Date();
  const formattedToday = formatDate(today);

  searchParams.Segments = searchParams.Segments.map((segment) => {

    const originDate = new Date(
      segment.OriginDate
    )

    const returnDate = segment.ReturnDate
      ? new Date(segment.ReturnDate)
      : null;

    return {
      ...segment,
      OriginDate: originDate < today ? formattedToday : segment.OriginDate,
      ReturnDate:
        returnDate && returnDate < today ? formattedToday : segment.ReturnDate,
    };
  });

  console.log("here the seahc params is  : " , searchParams);

  setLocalStorageJSON("fts-hm-sp", searchParams);
}

if (getLocalStorageJSON("fts-hm-trv") === null) {
  searchParamsTravellers = {
    Adults: 1,
    Childs: 0,
    Infrants: 0,
    TravellersAndClassesErrorOut: false,
    TravellersAndClassesErrorOutMessage: "",
    TravellersAndClassesErrorIn: null,
    TravellersAndClassesErrorInMessage: "",
  };
} else {
  searchParamsTravellers = getLocalStorageJSON("fts-hm-trv");
}

if (getLocalStorageJSON("fts-hm-fr") === null) {
  searchParamsFare = {
    class: "Economy",
    type: "Regular",
  };
} else {
  searchParamsFare = getLocalStorageJSON("fts-hm-fr");
}

if (getLocalStorageJSON("fts-hm-rs") === null) {
  recentSearches = [];
} else {

  
  recentSearches = cleanUpExpiredSearches(getLocalStorageJSON("fts-hm-rs"));

  setLocalStorageJSON("fts-hm-rs", recentSearches);

}


// default errors Checker

let AirportsToError1 = [false, ""];
let AirportsToError2 = [false, ""];
let AirportsToError3 = [false, ""];
let AirportsToError4 = [false, ""];

if (
  searchParams.Segments[0].OriginCode === searchParams.Segments[0].DestinyCode &&
  searchParams.Segments[0].OriginCode != ""
) {
  AirportsToError1 = [true, SearchBarErrorMessages.From_To];
}
if (
  searchParams.Segments[1].OriginCode === searchParams.Segments[1].DestinyCode &&
  searchParams.Segments[1].OriginCode != ""
) {
  AirportsToError2 = [true, SearchBarErrorMessages.From_To];
}
if (
  searchParams.Segments[2].OriginCode === searchParams.Segments[2].DestinyCode &&
  searchParams.Segments[2].OriginCode != ""
) {
  AirportsToError3 = [true, SearchBarErrorMessages.From_To];
}
if (
  searchParams.Segments[3].OriginCode === searchParams.Segments[3].DestinyCode &&
  searchParams.Segments[3].OriginCode != ""
) {
  AirportsToError4 = [true, SearchBarErrorMessages.From_To];
}

const initialState = {
  searchBarMode: 1,

  searchParams: searchParams,
  searchParamsTravellers: searchParamsTravellers,
  searchParamsFare: searchParamsFare,
  recentSearches: recentSearches,

  popularCitiesLoading: true,
  popularAirports: [],

  AirportsToError1: AirportsToError1,
  AirportsToError2: AirportsToError2,
  AirportsToError3: AirportsToError3,
  AirportsToError4: AirportsToError4,

  AirportsFromError1: [false, ""],
  AirportsFromError2: [false, ""],
  AirportsFromError3: [false, ""],
  AirportsFromError4: [false, ""],

  multiRowNumber: 2,

  searchClicked: false,


  isOffline : false,

  redirectUrl : ""
};

// Api Callings


const HomePageSearchBarFlightSlice = createSlice({
  name: "HomePageSearchBarFlight",
  initialState,
  reducers: {
    setSearchBarMode: (state, action) => {
      state.searchBarMode = action.payload;
    },
    setMultiRowNumber: (state, action) => {
      state.multiRowNumber = action.payload;
    },
    setSearchClicked: (state, action) => {
      state.searchClicked = false;
    },
    setSearchSegmentsParams: (state, action) => {
      // check if is of From or To
      if (action.payload.PopupType === 0) {
        // From
        state.searchParams.Segments[action.payload.type].OriginCity =
          action.payload.City;
        state.searchParams.Segments[action.payload.type].Origin =
          action.payload.AirportName;
        state.searchParams.Segments[action.payload.type].OriginCode =
          action.payload.Code;
        state.searchParams.Segments[action.payload.type].OriginCountryCode =
          action.payload.CountryCode;

        // check if error there ,, update the error

        if (action.payload.type === 0) {
          if (state.AirportsFromError1[0] === true) {
            state.AirportsFromError1 = [false, ""];
          }
        }
        if (action.payload.type === 1) {
          if (state.AirportsFromError2[0] === true) {
            state.AirportsFromError2 = [false, ""];
          }
        }
        if (action.payload.type === 2) {
          if (state.AirportsFromError3[0] === true) {
            state.AirportsFromError3 = [false, ""];
          }
        }
        if (action.payload.type === 3) {
          if (state.AirportsFromError4[0] === true) {
            state.AirportsFromError4 = [false, ""];
          }
        }

        if (action.payload.type === 0) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].DestinyCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError1 = [false, ""];
          }
        }
        if (action.payload.type === 1) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].DestinyCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError2 = [false, ""];
          }
        }
        if (action.payload.type === 2) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].DestinyCode
          ) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError3 = [false, ""];
          }
        }
        if (action.payload.type === 3) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].DestinyCode
          ) {
            state.AirportsToError4 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError4 = [false, ""];
          }
        }

        // save the search params

        setLocalStorageJSON("fts-hm-sp", state.searchParams);

        // Save the from airports
        const fromAirports = getLocalStorageJSON("fts-hm-fa") === null ? [] : getLocalStorageJSON("fts-hm-fa");

        if (fromAirports?.length > 0) {
          // Check if this airport already exists
          const existingIndex = fromAirports?.findIndex(
            (airport) => airport.airport_code === action.payload.Code
          );

          if (existingIndex !== -1) {
            // If it already exists, remove it
            fromAirports.splice(existingIndex, 1);
          }
        }

        // Push the new airport to the top of the list
        fromAirports.unshift(action.payload.airport);

        // Check if the length of fromAirports is greater than 5
        if (fromAirports?.length > 16) {
          // Remove the last element
          fromAirports.pop();
        }

        // Update the state with the modified fromAirports array
        state.fromAirports = fromAirports;

        setLocalStorageJSON("fts-hm-fa", state.fromAirports);
      } else {
        // To
        state.searchParams.Segments[action.payload.type].DestinationCity =
          action.payload.City;
        state.searchParams.Segments[action.payload.type].Destiny =
          action.payload.AirportName;
        state.searchParams.Segments[action.payload.type].DestinyCode =
          action.payload.Code;
        state.searchParams.Segments[
          action.payload.type
        ].DestinationCountryCode = action.payload.CountryCode;

        // check if error there ,, update the error

        if (action.payload.type === 0) {
          if (state.AirportsToError1[0] === true) {
            state.AirportsToError1 = [false, ""];
          }
        }
        if (action.payload.type === 1) {
          if (state.AirportsToError2[0] === true) {
            state.AirportsToError2 = [false, ""];
          }
        }
        if (action.payload.type === 2) {
          if (state.AirportsToError3[0] === true) {
            state.AirportsToError3 = [false, ""];
          }
        }
        if (action.payload.type === 3) {
          if (state.AirportsToError4[0] === true) {
            state.AirportsToError4 = [false, ""];
          }
        }

        if (action.payload.type === 0) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].OriginCode
          ) {
            state.AirportsToError1 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError1 = [false, ""];
          }
        } else if (action.payload.type === 1) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].OriginCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError2 = [false, ""];
          }
        } else if (action.payload.type === 2) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].OriginCode
          ) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError3 = [false, ""];
          }
        } else if (action.payload.type === 3) {
          if (
            action.payload.Code ==
            state.searchParams.Segments[action.payload.type].OriginCode
          ) {
            state.AirportsToError4 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError4 = [false, ""];
          }
        }

        if (action.payload.type === 0 && state.searchBarMode === 3) {
          state.searchParams.Segments[1].OriginCity = action.payload.City;
          state.searchParams.Segments[1].Origin = action.payload.AirportName;
          state.searchParams.Segments[1].OriginCode = action.payload.Code;
          state.searchParams.Segments[1].OriginCountryCode =
            action.payload.CountryCode;

          if (
            action.payload.Code === state.searchParams.Segments[1].DestinyCode
          ) {
            state.AirportsToError2 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError2 = [false, ""];
          }
        } else if (action.payload.type === 1) {
          state.searchParams.Segments[2].OriginCity = action.payload.City;
          state.searchParams.Segments[2].Origin = action.payload.AirportName;
          state.searchParams.Segments[2].OriginCode = action.payload.Code;
          state.searchParams.Segments[2].OriginCountryCode =
            action.payload.CountryCode;

          if (
            action.payload.Code === state.searchParams.Segments[2].DestinyCode
          ) {
            state.AirportsToError3 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError3 = [false, ""];
          }
        } else if (action.payload.type === 2) {
          state.searchParams.Segments[3].OriginCity = action.payload.City;
          state.searchParams.Segments[3].Origin = action.payload.AirportName;
          state.searchParams.Segments[3].OriginCode = action.payload.Code;
          state.searchParams.Segments[3].OriginCountryCode =
            action.payload.CountryCode;

          if (
            action.payload.Code === state.searchParams.Segments[3].DestinyCode
          ) {
            state.AirportsToError4 = [true, SearchBarErrorMessages.From_To];
          } else {
            state.AirportsToError4 = [false, ""];
          }
        }

        // save the search params

        setLocalStorageJSON("fts-hm-sp", state.searchParams);

        // save the from airports

        // Save the from airports
        const toAirports = getLocalStorageJSON("fts-hm-ta") === null ? [] : getLocalStorageJSON("fts-hm-ta");

        if (toAirports?.length > 0) {
          // Check if this airport already exists
          const existingIndex = toAirports?.findIndex(
            (airport) => airport.airport_code === action.payload.Code
          );

          if (existingIndex !== -1) {
            // If it already exists, remove it
            toAirports.splice(existingIndex, 1);
          }
        }

        // Push the new airport to the top of the list
        toAirports.unshift(action.payload.airport);

        // Check if the length of fromAirports is greater than 5
        if (toAirports?.length > 16) {
          // Remove the last element
          toAirports.pop();
        }

        // Update the state with the modified toAirports array
        state.toAirports = toAirports;

        setLocalStorageJSON("fts-hm-ta", state.toAirports);
      }
    },
    setExchangeSearchParas: (state, action) => {
      if (
        state.searchParams.Segments[action.payload].OriginCode === "" ||
        state.searchParams.Segments[action.payload].DestinyCode === ""
      ) {
        return;
      }
      let previousOriginCityData = {
        City: state.searchParams.Segments[action.payload].OriginCity,
        AirportName: state.searchParams.Segments[action.payload].Origin,
        Code: state.searchParams.Segments[action.payload].OriginCode,
        CountryCode:
          state.searchParams.Segments[action.payload].OriginCountryCode,
      };

      let previousDestinyCityData = {
        City: state.searchParams.Segments[action.payload].DestinationCity,
        AirportName: state.searchParams.Segments[action.payload].Destiny,
        Code: state.searchParams.Segments[action.payload].DestinyCode,
        CountryCode:
          state.searchParams.Segments[action.payload].DestinationCountryCode,
      };

      state.searchParams.Segments[action.payload].OriginCity =
        previousDestinyCityData.City;
      state.searchParams.Segments[action.payload].Origin =
        previousDestinyCityData.AirportName;
      state.searchParams.Segments[action.payload].OriginCode =
        previousDestinyCityData.Code;
      state.searchParams.Segments[action.payload].OriginCountryCode =
        previousDestinyCityData.CountryCode;

      state.searchParams.Segments[action.payload].DestinationCity =
        previousOriginCityData.City;
      state.searchParams.Segments[action.payload].Destiny =
        previousOriginCityData.AirportName;
      state.searchParams.Segments[action.payload].DestinyCode =
        previousOriginCityData.Code;
      state.searchParams.Segments[action.payload].DestinationCountryCode =
        previousOriginCityData.CountryCode;

      if (action.payload === 0) {
        // save the searcvh params

        if (state.searchBarMode === 3) {
          if (
            state.searchParams.Segments[1].OriginCode ==
            state.searchParams.Segments[1].DestinyCode
          ) {
            state.AirportsToError2 = true;
          } else {
            state.AirportsToError2 = false;
          }
        }
      }
      if (action.payload === 1) {
        // save the searcvh params

        if (
          state.searchParams.Segments[2].OriginCode ==
          state.searchParams.Segments[2].DestinyCode
        ) {
          state.AirportsToError3 = true;
        } else {
          state.AirportsToError3 = false;
        }
      }
      if (action.payload === 2) {
        // save the searcvh params

        if (
          state.searchParams.Segments[3].OriginCode ==
          state.searchParams.Segments[3].DestinyCode
        ) {
          state.AirportsToError4 = true;
        } else {
          state.AirportsToError4 = false;
        }
      }
      if (action.payload === 3) {
      }
      setLocalStorageJSON("fts-hm-sp", state.searchParams);
    },
    setTheDates: (state, action) => {
      for (let i = 0; i < state.searchParams.Segments?.length; i++) {
        state.searchParams.Segments[i].OriginDate =
          action.payload[i].OriginDate;
        state.searchParams.Segments[i].ReturnDate =
          action.payload[i].ReturnDate;
      }

      setLocalStorageJSON("fts-hm-sp", state.searchParams);
    },
    setTheFareType: (state, action) => {
      state.searchParamsFare.type = action.payload;
      setLocalStorageJSON("fts-hm-fr", state.searchParamsFare);

      const { searchParamsFare, searchParamsTravellers } = state;

      if (
        (searchParamsFare.type === "Student" ||
          searchParamsFare.type === "Senior Citizen") &&
        (searchParamsTravellers.Childs > 0 ||
          searchParamsTravellers.Infrants > 0)
      ) {
        
        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          TravellersFareMessage(searchParamsFare.type);
        state.searchParamsTravellers.TravellersAndClassesErrorOut = true;
        state.searchParamsTravellers.TravellersAndClassesErrorOutMessage =
          TravellersFareMessage(searchParamsFare.type);
        setLocalStorageJSON("fts-hm-trv", searchParamsTravellers);
        return;
      }

      state.searchParamsTravellers.TravellersAndClassesErrorIn = false;
      state.searchParamsTravellers.TravellersAndClassesErrorInMessage = "";
      state.searchParamsTravellers.TravellersAndClassesErrorOut = false;
      state.searchParamsTravellers.TravellersAndClassesErrorOutMessage = "";
      setLocalStorageJSON("fts-hm-trv", searchParamsTravellers);
    },
    setTravellersAndClasses: (state, action) => {
      let { adults, childs, infants, classs } = action.payload;
      const { searchParamsFare, searchParamsTravellers } = state;

      if (adults + childs > 9) {
        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          SearchBarErrorMessages.TravellersPopup[0];
        setLocalStorageJSON("fts-hm-trv", searchParamsTravellers);
        return;
      } else if (adults < infants) {
        infants = adults;
        state.searchParamsTravellers.Adults = adults;
        state.searchParamsTravellers.Infrants = infants;

        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          SearchBarErrorMessages.TravellersPopup[1];
        setLocalStorageJSON("fts-hm-trv", searchParamsTravellers);
        return;
      }

      if (
        (searchParamsFare.type === "Student" ||
          searchParamsFare.type === "Senior Citizen") &&
        (childs > 0 || infants > 0)
      ) {
        state.searchParamsTravellers.TravellersAndClassesErrorIn = true;
        state.searchParamsTravellers.TravellersAndClassesErrorInMessage =
          TravellersFareMessage(searchParamsFare.type);
        state.searchParamsTravellers.TravellersAndClassesErrorOut = true;
        state.searchParamsTravellers.TravellersAndClassesErrorOutMessage =
          TravellersFareMessage(searchParamsFare.type);
        setLocalStorageJSON("fts-hm-trv", searchParamsTravellers);
        return;
      }

      state.searchParamsTravellers.Adults = adults;
      state.searchParamsTravellers.Childs = childs;
      state.searchParamsTravellers.Infrants = infants;
      state.searchParamsFare.class = classs;

      state.searchParamsTravellers.TravellersAndClassesErrorIn = false;
      state.searchParamsTravellers.TravellersAndClassesErrorInMessage = "";
      state.searchParamsTravellers.TravellersAndClassesErrorOut = false;
      state.searchParamsTravellers.TravellersAndClassesErrorOutMessage = "";

      setLocalStorageJSON("fts-hm-trv", searchParamsTravellers);
      setLocalStorageJSON("fts-hm-fr", searchParamsFare);
    },
    setTravellersErrors: (state, action) => {
      state.searchParamsTravellers.TravellersAndClassesErrorIn = false;
      state.searchParamsTravellers.TravellersAndClassesErrorInMessage = "";

      setLocalStorageJSON("fts-hm-trv", searchParamsTravellers);
    },
    setSearchClickedError: (state, action) => {
      state.searchClicked = false;
    },
    searchTheFlightUrlCreator: (state, action) => {
      // Step 1 : Check if There any Error

      const { navigate } = action.payload;

      // For Oneway and Round
      if (state.searchBarMode === 1 || state.searchBarMode === 2) {
        let sholdReturn = 0;
        if (state.searchParams.Segments[0].OriginCode === "") {
          state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
          sholdReturn++;
        }
        if (state.searchParams.Segments[0].DestinyCode === "") {
          state.AirportsToError1 = [true, SearchBarErrorMessages.To];
          sholdReturn++;
        }

        state.searchClicked = true;
        if (sholdReturn > 0) {
          return;
        }
      }

      // For Multiway
      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 2) {
          let sholdReturn = 0;
          if (state.searchParams.Segments[0].OriginCode === "") {
            state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[0].DestinyCode === "") {
            state.AirportsToError1 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams.Segments[1].OriginCode === "") {
            state.AirportsFromError2 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[1].DestinyCode === "") {
            state.AirportsToError2 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 3) {
          let sholdReturn = 0;
          if (state.searchParams.Segments[0].OriginCode === "") {
            state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[0].DestinyCode === "") {
            state.AirportsToError1 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams.Segments[1].OriginCode === "") {
            state.AirportsFromError2 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[1].DestinyCode === "") {
            state.AirportsToError2 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams.Segments[2].OriginCode === "") {
            state.AirportsFromError3 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[2].DestinyCode === "") {
            state.AirportsToError3 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        } else if (state.multiRowNumber === 4) {
          let sholdReturn = 0;
          if (state.searchParams.Segments[0].OriginCode === "") {
            state.AirportsFromError1 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[0].DestinyCode === "") {
            state.AirportsToError1 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams.Segments[1].OriginCode === "") {
            state.AirportsFromError2 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[1].DestinyCode === "") {
            state.AirportsToError2 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams.Segments[2].OriginCode === "") {
            state.AirportsFromError3 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[2].DestinyCode === "") {
            state.AirportsToError3 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }
          if (state.searchParams.Segments[3].OriginCode === "") {
            state.AirportsFromError4 = [true, SearchBarErrorMessages.From];
            sholdReturn++;
          }
          if (state.searchParams.Segments[3].DestinyCode === "") {
            state.AirportsToError4 = [true, SearchBarErrorMessages.To];
            sholdReturn++;
          }

          state.searchClicked = true;
          if (sholdReturn > 0) {
            return;
          }
        }
      }

      // For Oneway and Round
      if (state.searchBarMode === 1 || state.searchBarMode === 2) {
        if (
          state.AirportsToError1[0] === true ||
          state.searchParamsTravellers.TravellersAndClassesErrorOut === true
        ) {
          state.searchClicked = true;
          return;
        }
      }

      // For Multiway
      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 2) {
          if (
            state.AirportsToError1[0] === true ||
            state.AirportsToError2[0] === true ||
            state.searchParamsTravellers.TravellersAndClassesErrorOut === true
          ) {
            state.searchClicked = true;
            return;
          }
        } else if (state.multiRowNumber === 3) {
          if (
            state.AirportsToError1[0] === true ||
            state.AirportsToError2[0] === true ||
            state.AirportsToError3[0] === true ||
            state.searchParamsTravellers.TravellersAndClassesErrorOut === true
          ) {
            state.searchClicked = true;
            return;
          }
        } else if (state.multiRowNumber === 4) {
          if (
            state.AirportsToError1[0] === true ||
            state.AirportsToError2[0] === true ||
            state.AirportsToError3[0] === true ||
            state.AirportsToError4[0] === true ||
            state.searchParamsTravellers.TravellersAndClassesErrorOut === true
          ) {
            state.searchClicked = true;
            return;
          }
        }
      }

      // Step 2 : Create The Url accoridng to flight

      // For Oneway
      if (state.searchBarMode === 1) {
        let url = `tp=ow&a=${state.searchParamsTravellers.Adults}&c=${
          state.searchParamsTravellers.Childs
        }&i=${state.searchParamsTravellers.Infrants}&frc=${
          state.searchParamsFare.class
        }&frt=${state.searchParamsFare.type}&itr=${
          state.searchParams.Segments[0].OriginCode
        }-${
          state.searchParams.Segments[0].DestinyCode
        }-${formatDateToSeatchParams(
          state.searchParams.Segments[0].OriginDate
        )}-${state.searchParams.Segments[0].OriginCountryCode}-${
          state.searchParams.Segments[0].DestinationCountryCode
        }`;
        // Save It to Recent Search

        let recentSearches = state.recentSearches;

        // Create the recent search object
        const recentSearch = {
          url: url,
          type: "ow",
          city: [
            state.searchParams.Segments[0].OriginCity,
            state.searchParams.Segments[0].DestinationCity,
          ],
          formattedDate: state.searchParams.Segments[0].OriginDate,
          date: formatDateRecentSearch(
            state.searchParams.Segments[0].OriginDate
          ),
        };

        // Check if the recent search already exists
        const existingIndex = recentSearches.findIndex(
          (search) => search.url === recentSearch.url
        );

        if (existingIndex !== -1) {
          // If it already exists, remove it
          recentSearches.splice(existingIndex, 1);
        }

        // Add the new recent search to the top
        recentSearches.unshift(recentSearch);

        // Limit the size of recent searches to 5
        if (recentSearches?.length > 5) {
          recentSearches.pop();
        }

        state.recentSearches = recentSearches;

        setLocalStorageJSON("fts-hm-rs", state.recentSearches);

        // it should not add dblicate searches + the size of recentsearches should be 5 only ,,  and the recent added serahc should be at the top

        // Step 3: Redirect to the URL with react-router-dom
        navigate(`/flights/results?${url}`);
        return;
      }

      // For Roundway
      if (state.searchBarMode === 2) {
        let url = `tp=rw&a=${state.searchParamsTravellers.Adults}&c=${
          state.searchParamsTravellers.Childs
        }&i=${state.searchParamsTravellers.Infrants}&frc=${
          state.searchParamsFare.class
        }&frt=${state.searchParamsFare.type}&itr=${
          state.searchParams.Segments[0].OriginCode
        }-${
          state.searchParams.Segments[0].DestinyCode
        }-${formatDateToSeatchParams(
          state.searchParams.Segments[0].OriginDate
        )}-${state.searchParams.Segments[0].OriginCountryCode}-${
          state.searchParams.Segments[0].DestinationCountryCode
        }|${state.searchParams.Segments[0].DestinyCode}-${
          state.searchParams.Segments[0].OriginCode
        }-${formatDateToSeatchParams(
          state.searchParams.Segments[0].ReturnDate
        )}-${state.searchParams.Segments[0].DestinationCountryCode}-${
          state.searchParams.Segments[0].OriginCountryCode
        }`;

        // Save It to Recent Search

        let recentSearches = state.recentSearches;

        // Create the recent search object
        const recentSearch = {
          url: url,
          type: "rw",
          city: [
            state.searchParams.Segments[0].OriginCity,
            state.searchParams.Segments[0].DestinationCity,
          ],
          formattedDate: [
            state.searchParams.Segments[0].OriginDate,
            state.searchParams.Segments[0].ReturnDate,
          ],
          date: [
            formatDateRecentSearch(state.searchParams.Segments[0].OriginDate),
            formatDateRecentSearch(state.searchParams.Segments[0].ReturnDate),
          ],
        };

        // Check if the recent search already exists
        const existingIndex = recentSearches.findIndex(
          (search) => search.url === recentSearch.url
        );

        if (existingIndex !== -1) {
          // If it already exists, remove it
          recentSearches.splice(existingIndex, 1);
        }

        // Add the new recent search to the top
        recentSearches.unshift(recentSearch);

        // Limit the size of recent searches to 5
        if (recentSearches?.length > 5) {
          recentSearches.pop();
        }

        state.recentSearches = recentSearches;

        setLocalStorageJSON("fts-hm-rs", state.recentSearches);

        // it should not add dblicate searches + the size of recentsearches should be 5 only ,,  and the recent added serahc should be at the top

        // Step 3: Redirect to the URL with react-router-dom

        navigate(`/flights/results?${url}`);
        return;
      }

      // For Multiway
      if (state.searchBarMode === 3) {
        if (state.multiRowNumber === 2) {
          let url = `tp=mw&a=${state.searchParamsTravellers.Adults}&c=${
            state.searchParamsTravellers.Childs
          }&i=${state.searchParamsTravellers.Infrants}&frc=${
            state.searchParamsFare.class
          }&frt=${state.searchParamsFare.type}&itr=${
            state.searchParams.Segments[0].OriginCode
          }-${
            state.searchParams.Segments[0].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[0].OriginDate
          )}-${state.searchParams.Segments[0].OriginCountryCode}-${
            state.searchParams.Segments[0].DestinationCountryCode
          }|${state.searchParams.Segments[1].OriginCode}-${
            state.searchParams.Segments[1].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[1].OriginDate
          )}-${state.searchParams.Segments[1].OriginCountryCode}-${
            state.searchParams.Segments[1].DestinationCountryCode
          }`;

          // Save It to Recent Search

          // Step 3: Redirect to the URL with react-router-dom
          navigate(`/flights/results?${url}`);
          return;
        } else if (state.multiRowNumber === 3) {
          let url = `tp=mw&a=${state.searchParamsTravellers.Adults}&c=${
            state.searchParamsTravellers.Childs
          }&i=${state.searchParamsTravellers.Infrants}&frc=${
            state.searchParamsFare.class
          }&frt=${state.searchParamsFare.type}&itr=${
            state.searchParams.Segments[0].OriginCode
          }-${
            state.searchParams.Segments[0].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[0].OriginDate
          )}-${state.searchParams.Segments[0].OriginCountryCode}-${
            state.searchParams.Segments[0].DestinationCountryCode
          }|${state.searchParams.Segments[1].OriginCode}-${
            state.searchParams.Segments[1].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[1].OriginDate
          )}-${state.searchParams.Segments[1].OriginCountryCode}-${
            state.searchParams.Segments[1].DestinationCountryCode
          }|${state.searchParams.Segments[2].OriginCode}-${
            state.searchParams.Segments[2].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[2].OriginDate
          )}-${state.searchParams.Segments[2].OriginCountryCode}-${
            state.searchParams.Segments[2].DestinationCountryCode
          }`;

          // Save It to Recent Search

          // Step 3: Redirect to the URL with react-router-dom
          navigate(`/flights/results?${url}`);
          return;
        } else if (state.multiRowNumber === 4) {
          let url = `tp=mw&a=${state.searchParamsTravellers.Adults}&c=${
            state.searchParamsTravellers.Childs
          }&i=${state.searchParamsTravellers.Infrants}&frc=${
            state.searchParamsFare.class
          }&frt=${state.searchParamsFare.type}&itr=${
            state.searchParams.Segments[0].OriginCode
          }-${
            state.searchParams.Segments[0].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[0].OriginDate
          )}-${state.searchParams.Segments[0].OriginCountryCode}-${
            state.searchParams.Segments[0].DestinationCountryCode
          }|${state.searchParams.Segments[1].OriginCode}-${
            state.searchParams.Segments[1].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[1].OriginDate
          )}-${state.searchParams.Segments[1].OriginCountryCode}-${
            state.searchParams.Segments[1].DestinationCountryCode
          }|${state.searchParams.Segments[2].OriginCode}-${
            state.searchParams.Segments[2].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[2].OriginDate
          )}-${state.searchParams.Segments[2].OriginCountryCode}-${
            state.searchParams.Segments[2].DestinationCountryCode
          }|${state.searchParams.Segments[3].OriginCode}-${
            state.searchParams.Segments[3].DestinyCode
          }-${formatDateToSeatchParams(
            state.searchParams.Segments[3].OriginDate
          )}-${state.searchParams.Segments[3].OriginCountryCode}-${
            state.searchParams.Segments[3].DestinationCountryCode
          }`;

          // Save It to Recent Search

          // Step 3: Redirect to the URL with react-router-dom
          navigate(`/flights/results?${url}`);
          return;
        }
      }

    },
    setRecentSearchOnClick: (state, action) => {
      const clickedSearch = action.payload;

      // Find the index of the clicked search in recentSearches
      const existingIndex = state.recentSearches.findIndex(
        (search) => search.url === clickedSearch.url
      );

      if (existingIndex !== -1) {
        // If it already exists, remove it
        state.recentSearches.splice(existingIndex, 1);
      }

      // Add the clicked search to the top
      state.recentSearches.unshift(clickedSearch);

      // Limit the size of recent searches to 5
      if (state.recentSearches?.length > 5) {
        state.recentSearches.pop();
      }

      setLocalStorageJSON("fts-hm-rs", state.recentSearches);

      // in the end navigate with the url
    },
  },

});

export const {
  setSearchBarMode,
  setSearchClicked,
  setMultiRowNumber,
  setSearchSegmentsParams,
  setExchangeSearchParas,
  setTheDates,
  setTheFareType,
  setTravellersAndClasses,
  setTravellersErrors,
  searchTheFlightUrlCreator,
  setRecentSearchOnClick,
} = HomePageSearchBarFlightSlice.actions;

export default HomePageSearchBarFlightSlice.reducer;

import React from "react";
import { Countrieslist } from "../../../../../Constants/CountriesAndCodes";
import {
  Autocomplete,
  Box,
  TextField,
  createFilterOptions,
} from "@mui/material";
import "./DialCodeSelector.css";
import { useSelector } from "react-redux";

function DialCodeSelector({ Theme, IconComponent, value, onChange }) {
  // Custom filter function to prioritize exact matches and filter out irrelevant results
  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.phone} ${option.name}`,
    trim: true,
    limit: 100,
  });

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{
        width: "15%",
        "& .MuiOutlinedInput-root": {
          borderColor: Theme == "averta" ? "#C2A07C" : "inherit",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
          },
        },
        "& .MuiInputLabel-root": {
          color: Theme == "averta" ? "#C2A07C" : "inherit",
          fontSize: "16px",
          fontFamily: `var(--font-family-${
            Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
          })`,
          fontStyle: "normal",
          fontWeight: "400",
          "&.Mui-focused": {
            color: Theme == "averta" ? "#C2A07C" : "#0057B7",
          },
        },
      }}
      options={Countrieslist}
      autoHighlight
      disableClearable
      value={value} // Set the selected value
      onChange={(event, newValue) => onChange(newValue)} // Call the onChange handler from props
      popupIcon={IconComponent}
      filterOptions={filterOptions} // Apply the custom filter function
      getOptionLabel={(option) => `${option.phone}`} // Display phone code and country name
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme === "proxima"
                  ? `${Theme}_nova-regular`
                  : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "& > img": { mr: 2, flexShrink: 0 },
            }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {`${option.phone}`}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme === "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
          }}
          {...params}
          label="Dial Code"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default DialCodeSelector;

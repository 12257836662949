import React, { useEffect, useRef } from "react";
import "./FlightsOptionsPopup.css";

export default function FlightsOptionsPopup({
  options,
  open,
  onClose,
  position,
  onClickClose,
  Theme,
}) {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) {
    return null;
  }

  return (
    <div
      ref={popupRef}
      style={{
        position: "absolute",
        top: "25px",
        left: "0px",
        cursor: "pointer",
        zIndex: 10000,
        backgroundColor: "rgb(255, 255, 255)",
        border: "1.2px solid",
        borderColor: "#e6e6e6",
      }}
    >
      <div className="Flights-Options-Popup-trip-type-proxima-2">
        <div className="Flights-Options-Popup-frame-41 Flights-Options-Popup-frame">
          {options.map((item, index) => {
            return (
              <div
                className={`Flights-Options-Popup-frame-10 Flights-Options-Popup-frame ${Theme}`}
                key={index}
                onClick={() => onClickClose(item)}
              >
                <div className="Flights-Options-Popup-frame-57 Flights-Options-Popup-frame">
                  <div className={`${Theme}_regular font16 color_eerie-black`}>
                    {item} Way
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

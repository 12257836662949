import React, { useEffect, useState } from 'react';
import DialCodeSelector from './DialCodeSelector';
import { SvgIcon, TextField } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import "./ContactDetails.css";
import { useDispatch, useSelector } from 'react-redux';
import { Countrieslist } from '../../../../../Constants/CountriesAndCodes';
import { setmainDialCode, setmainMail, setmainPhone } from '../../../../../Redux/Slices/Flight_Slices/ReviewPage-Flights-SLICE';

function ContactDetails({ Theme , userDC , DialCode , Email ,  Phone , hasError}) {

  let dispatch = useDispatch();

    // e.g., "+91"
   // Find the default country option based on the user's dial code
   const defaultCountry = Countrieslist?.find(country => DialCode == "" ? country.phone === userDC : country.phone === DialCode);

   let [dialCode, setDialCode] = useState(defaultCountry || null);
   const handleDialCodeChange = (newValue) => {
     setDialCode(newValue);
     dispatch(setmainDialCode(newValue.phone))
   };

   let [countryCode  ,  setCountryCode] = useState(defaultCountry.code);

   useEffect(() => {

    let cc = Countrieslist?.filter(country => country.phone === dialCode);
    if(cc.length > 0){
      setCountryCode(cc[0].code)
    }

    } , [dialCode])

   const [phoneNumber, setPhoneNumber] = useState(Phone);
   const [email, setEmail] = useState(Email);
   const [phoneError, setPhoneError] = useState('');
   const [emailError, setEmailError] = useState('');

   const validatePhoneNumber = () => {
     if (!phoneNumber) {
       setPhoneError('Phone number is required');
     } else if (!isValidPhoneNumber(phoneNumber, countryCode)) {
       setPhoneError('Invalid phone number');
     } else {
       setPhoneError('');
     }
   };

   const validateEmail = () => {
     if (!email) {
       setEmailError('Email is required');
     } else {
       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       if (!emailRegex.test(email)) {
         setEmailError('Invalid email address');
       } else {
         setEmailError('');
       }
     }
   };

   const handlePhoneChange = (e) => {
     const value = e.target.value;
     setPhoneNumber(value);
     dispatch(setmainPhone(value))
     setPhoneError(''); // Reset error while typing
   };

   const handleEmailChange = (e) => {
     const value = e.target.value;
     setEmail(value);
     dispatch(setmainMail(value))
     setEmailError(''); // Reset error while typing
   };

   function ExpandIcon(props) {
     return (
       <SvgIcon
         {...props}
         style={{ color: props.color, fontSize: props.fontSize }}
       >
         <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
       </SvgIcon>
     );
   }

   useEffect(() => {
    if(hasError == true){
      validatePhoneNumber()
      validateEmail()
    }
   } , [hasError])

   return (
     <div className={`contact-details ${hasError &&  "shake"}`}>
       <div className={`header ${Theme}_semibold color_deep-ocean font16`}>Contact Details</div>

       <div className="contact">
         <DialCodeSelector
           value={dialCode} // Pass the selected dial code
           onChange={handleDialCodeChange} // Handle changes
           Theme={Theme}
           IconComponent={<ExpandIcon color={Theme == "proxima" ? "#0057B7" : "#976c41"} fontSize="medium" />}
         />

         <TextField
           required
           id="outlined-phone"
           label="Mobile Number"
           variant="outlined"
           value={phoneNumber}
           onChange={handlePhoneChange}
           onBlur={validatePhoneNumber} // Validate on blur
           error={!!phoneError}
           helperText={phoneError}
           sx={{
            width: "40%",
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "&.Mui-focused": {
                color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
            },
          }}
         />

         <TextField
           required
           id="outlined-email"
           label="Email Address"
           variant="outlined"
           value={email}
           onChange={handleEmailChange}
           onBlur={validateEmail} // Validate on blur
           error={!!emailError}
           helperText={emailError}
           sx={{
            width: "40%",
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "&.Mui-focused": {
                color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
            },
          }}
         />
       </div>
     </div>
   );
}

export default ContactDetails;

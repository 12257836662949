import React, { useMemo, useState } from "react";
import images from "../../../../../Utils/importImages";
import "./index.css";
import FareSummary from "../../../../Components/Popups/Hoverers/FareSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  setbarModeSelected,
  setdataMainPopup,
  setResultTypeMainPopup,
} from "../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import AirlinesLogContainer from "../Components/AirlinesLogContainer";
import { compareFlightTimes } from "../../../../../Utils/Flight_results_func";
import {
  formatDateResultBar,
  formatTimeRangeAMPM,
} from "../../../../../Utils/Date_Time_Calendar_Functions";
import { FlightsResultPageErrorMainDescription } from "../../../../../Constants/ConstantsFlights";
import Section from "./Components/Section";
import MainPopup from "../MainPopup/MainPopup";
export default function ResultBarSection({
  mode,
  isSticky,
  startValue,
  setPopupBarMode,
  setMainOpenPopup,
  Theme
}) {
  let ResultPageData = useSelector((state) => state?.ResultPageFlightsFlight);

  const SelectedDatas = useMemo(
    () => ResultPageData?.barModeData,
    [ResultPageData]
  );

  let dispatch = useDispatch();

  let [showError, setshowError] = useState(false);

  let setOpenPopup = (barModeVal, mainpopupVal) => {
    setPopupBarMode(barModeVal);
    dispatch(setdataMainPopup(SelectedDatas));
    dispatch(setResultTypeMainPopup(1));
    setMainOpenPopup(mainpopupVal);
  };

  return (
    <>

      {mode === 4 && (
        <div className="result-bar-section">
          <div className="frame-20-3">
            <Section
            Theme={Theme}
      
              startValue={startValue}
              ResultPageData={ResultPageData}
              SelectedDatas={SelectedDatas[0]}
              popupMode={0}
              setOpenPopup={setOpenPopup}
            />
            <Section
            Theme={Theme}
              startValue={startValue + 1}
              ResultPageData={ResultPageData}
              SelectedDatas={SelectedDatas[1]}
              popupMode={1}
              setOpenPopup={setOpenPopup}
            />
            <Section
            Theme={Theme}
              startValue={startValue + 2}
              ResultPageData={ResultPageData}
              SelectedDatas={SelectedDatas[2]}
              popupMode={2}
              setOpenPopup={setOpenPopup}
            />
            <Section
            Theme={Theme}
              startValue={startValue + 3}
              ResultPageData={ResultPageData}
              SelectedDatas={SelectedDatas[3]}
              popupMode={3}
              setOpenPopup={setOpenPopup}
            />
          </div>

          <div className="frame-20-4">
            {SelectedDatas[0].Segments &&
              SelectedDatas[1].Segments &&
              SelectedDatas[2].Segments &&
              SelectedDatas[3].Segments && (
                <>
                  <div className="frame-233-6">
                    <div className={`total-cost ${Theme}_regular color_storm-dust font13`}>
                      Total cost
                    </div>
                    <div className={`fare-summary fare ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font15`}>
                      Fare Summary
                      <div className="fare-summary-tooltip">
                        <FareSummary
                          base={
                            Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[3].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare)
                          }
                          tax={
                            Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[3].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="frame">
                    <div className="frame-232-8">
                      <div className="frame-2-2 frame-2-4">
                        <img
                          className="frame-169-23"
                          src={images["frame-169-19.svg"]}
                          alt="Frame 169"
                        />
                        <div className={`text-218 text-6 ${Theme}_semibold color_magic-potion font23`}>
                          {Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                            .Adults[0].Fare +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[3].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[0].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[3].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax)}
                        </div>
                      </div>
                      <div className={`per-adult-15 per-adult-16 ${Theme}_regular color_gravel font13`}>
                        per adult
                      </div>
                    </div>
                    <div
                      className={
                        compareFlightTimes(
                          SelectedDatas[0].Segments[
                            SelectedDatas[0].Segments.length - 1
                          ].Destination.ArrTime,
                          SelectedDatas[1].Segments[0].Origin.DepTime,
                          1
                        ) ||
                        compareFlightTimes(
                          SelectedDatas[1].Segments[
                            SelectedDatas[1].Segments.length - 1
                          ].Destination.ArrTime,
                          SelectedDatas[2].Segments[0].Origin.DepTime,
                          2
                        ) ||
                        compareFlightTimes(
                          SelectedDatas[2].Segments[
                            SelectedDatas[2].Segments.length - 1
                          ].Destination.ArrTime,
                          SelectedDatas[3].Segments[0].Origin.DepTime,
                          3
                        )
                          ? `frame-103-77 frame-103-8 disabled ${Theme}`
                          : `frame-103-7 frame-103-8 ${Theme}`
                      }
                      onMouseOver={() => setshowError(true)}
                      onMouseLeave={() => setshowError(false)}
                      onClick={() => {
                        const condition =
                          compareFlightTimes(
                            SelectedDatas[0].Segments[
                              SelectedDatas[0].Segments.length - 1
                            ].Destination.ArrTime,
                            SelectedDatas[1].Segments[0].Origin.DepTime
                          ) ||
                          compareFlightTimes(
                            SelectedDatas[1].Segments[
                              SelectedDatas[1].Segments.length - 1
                            ].Destination.ArrTime,
                            SelectedDatas[2].Segments[0].Origin.DepTime
                          ) ||
                          compareFlightTimes(
                            SelectedDatas[2].Segments[
                              SelectedDatas[2].Segments.length - 1
                            ].Destination.ArrTime,
                            SelectedDatas[3].Segments[0].Origin.DepTime
                          );

                        if (!condition) {
                          setOpenPopup(3, true);
                        }
                      }}
                    >
                      <div className={`continue valign-text-middle ${Theme}_semibold font14`}>
                        {(compareFlightTimes(
                          SelectedDatas[0].Segments[
                            SelectedDatas[0].Segments.length - 1
                          ].Destination.ArrTime,
                          SelectedDatas[1].Segments[0].Origin.DepTime
                        ) ||
                          compareFlightTimes(
                            SelectedDatas[1].Segments[
                              SelectedDatas[1].Segments.length - 1
                            ].Destination.ArrTime,
                            SelectedDatas[2].Segments[0].Origin.DepTime
                          ) ||
                          compareFlightTimes(
                            SelectedDatas[2].Segments[
                              SelectedDatas[2].Segments.length - 1
                            ].Destination.ArrTime,
                            SelectedDatas[3].Segments[0].Origin.DepTime
                          )) &&
                          "! "}{" "}
                        CONTINUE
                      </div>
                    </div>
                  </div>

                  {showError === true &&
                    (compareFlightTimes(
                      SelectedDatas[0].Segments[
                        SelectedDatas[0].Segments.length - 1
                      ].Destination.ArrTime,
                      SelectedDatas[1].Segments[0].Origin.DepTime
                    ) ||
                      compareFlightTimes(
                        SelectedDatas[1].Segments[
                          SelectedDatas[1].Segments.length - 1
                        ].Destination.ArrTime,
                        SelectedDatas[2].Segments[0].Origin.DepTime
                      ) ||
                      compareFlightTimes(
                        SelectedDatas[2].Segments[
                          SelectedDatas[2].Segments.length - 1
                        ].Destination.ArrTime,
                        SelectedDatas[3].Segments[0].Origin.DepTime
                      )) && (
                      <div
                        style={{
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "108%",
                          left: "-10%",
                          padding: "4px 8px",
                        }}
                        className="flight-error-message-special"
                      >
                        <img
                          className="alert-error-msg"
                          src={images["alert.svg"]}
                          alt="alert"
                        />
                        {/* <span className="flightsSprite fltErrorIcon appendRight10"></span> */}
                        <span
                          data-cy="sameCityError"
                          className={`redText fltErrorMsgText ${Theme}_regular color_magic-potion font14`}
                        >
                          {FlightsResultPageErrorMainDescription.IVCD}
                        </span>
                      </div>
                    )}
                </>
              )}
          </div>
        </div>
      )}
      {mode === 3 && (
        <div className="result-bar-section">
          <div className="frame-20-3">
            <Section
            Theme={Theme}
              startValue={startValue}
              ResultPageData={ResultPageData}
              SelectedDatas={SelectedDatas[0]}
              popupMode={0}
              setOpenPopup={setOpenPopup}
            />
            <Section
            Theme={Theme}
              startValue={startValue + 1}
              ResultPageData={ResultPageData}
              SelectedDatas={SelectedDatas[1]}
              popupMode={1}
              setOpenPopup={setOpenPopup}
            />
            <Section
            Theme={Theme}
              startValue={startValue + 2}
              ResultPageData={ResultPageData}
              SelectedDatas={SelectedDatas[2]}
              popupMode={2}
              setOpenPopup={setOpenPopup}
            />
          </div>

          <div className="frame-20-4">
            {SelectedDatas[0].Segments &&
              SelectedDatas[1].Segments &&
              SelectedDatas[2].Segments && (
                <>
                  <div className="frame-233-6">
                    <div className={`total-cost ${Theme}_regular color_storm-dust font13`}>
                      Total cost
                    </div>
                    <div className={`fare-summary fare ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font15`}>
                      Fare Summary
                      <div className="fare-summary-tooltip">
                        <FareSummary
                          base={
                            Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare)
                          }
                          tax={
                            Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="frame">
                    <div className="frame-232-8">
                      <div className="frame-2-2 frame-2-4">
                        <img
                          className="frame-169-23"
                          src={images["frame-169-19.svg"]}
                          alt="Frame 169"
                        />
                        <div className={`text-218 text-6 ${Theme}_semibold color_magic-potion font23`}>
                          {Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                            .Adults[0].Fare +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                            +SelectedDatas[0].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax +
                            +SelectedDatas[2].FareFamilies.FFList[0].Travellers
                              .Adults[0].Tax)}
                        </div>
                      </div>
                      <div className={`per-adult-15 per-adult-16 ${Theme}_regular color_gravel font13`}>
                        per adult
                      </div>
                    </div>
                    <div
                      className={
                        compareFlightTimes(
                          SelectedDatas[0].Segments[
                            SelectedDatas[0].Segments.length - 1
                          ].Destination.ArrTime,
                          SelectedDatas[1].Segments[0].Origin.DepTime
                        ) ||
                        compareFlightTimes(
                          SelectedDatas[1].Segments[
                            SelectedDatas[1].Segments.length - 1
                          ].Destination.ArrTime,
                          SelectedDatas[2].Segments[0].Origin.DepTime
                        )
                          ? `frame-103-77 frame-103-8 disabled ${Theme}`
                          : `frame-103-7 frame-103-8 ${Theme}`
                      }
                      onMouseOver={() => setshowError(true)}
                      onMouseLeave={() => setshowError(false)}
                      onClick={() => {
                        setOpenPopup(2, true);
                      }}
                    >
                      <div className={`continue valign-text-middle ${Theme}_semibold font14`}>
                        {(compareFlightTimes(
                          SelectedDatas[0].Segments[
                            SelectedDatas[0].Segments.length - 1
                          ].Destination.ArrTime,
                          SelectedDatas[1].Segments[0].Origin.DepTime
                        ) ||
                          compareFlightTimes(
                            SelectedDatas[1].Segments[
                              SelectedDatas[1].Segments.length - 1
                            ].Destination.ArrTime,
                            SelectedDatas[2].Segments[0].Origin.DepTime
                          )) &&
                          "! "}{" "}
                        CONTINUE
                      </div>
                    </div>
                  </div>

                  {showError === true &&
                    (compareFlightTimes(
                      SelectedDatas[0].Segments[
                        SelectedDatas[0].Segments.length - 1
                      ].Destination.ArrTime,
                      SelectedDatas[1].Segments[0].Origin.DepTime
                    ) ||
                      compareFlightTimes(
                        SelectedDatas[1].Segments[
                          SelectedDatas[1].Segments.length - 1
                        ].Destination.ArrTime,
                        SelectedDatas[2].Segments[0].Origin.DepTime
                      )) && (
                      <div
                        style={{
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "108%",
                          left: "-10%",
                          padding: "4px 8px",
                        }}
                        className="flight-error-message-special"
                      >
                        <img
                          className="alert-error-msg"
                          src={images["alert.svg"]}
                          alt="alert"
                        />
                        {/* <span className="flightsSprite fltErrorIcon appendRight10"></span> */}
                        <span
                          data-cy="sameCityError"
                          className={`redText fltErrorMsgText ${Theme}_regular color_magic-potion font14`}
                        >
                          {FlightsResultPageErrorMainDescription.IVCD}
                        </span>
                      </div>
                    )}
                </>
              )}
          </div>
        </div>
      )}
      {mode === 2 && (
        <div className="result-bar-section">
          <div className="frame-20-3">
            <div
              className={`frame-237 ${Theme}`}
              onClick={() => dispatch(setbarModeSelected(startValue))}
            >
              {SelectedDatas[0].Segments && (
                <div
                  className={
                    ResultPageData?.barModeSelected === startValue
                      ? `frame-175-4-4 ${Theme}`
                      : `frame-175-4 ${Theme}`
                  }
                >
                  <div className="frame-6">
                    <div className="frame-232">
                      <div className="frame-232-1">
                        <div className="frame-232-2">
                          <div className="frame-232-3">
                            <AirlinesLogContainer
                              allAirlines={SelectedDatas[0].AirlinesDetails}
                            />
                            <div className="frame-203-3">
                              <div
                                className={
                                  ResultPageData?.barModeSelected === startValue
                                    ? `new-delhi-bangalore ${Theme}_semibold color_white font16`
                                    : `new-delhi-bangalore ${Theme}_semibold color_eerie-black font16`
                                }
                              >
                                {
                                  SelectedDatas[0].Segments[0].Origin.Airport
                                    .CityName
                                }{" "}
                                -{" "}
                                {
                                  SelectedDatas[0].Segments[
                                    SelectedDatas[0].Segments.length - 1
                                  ].Destination.Airport.CityName
                                }
                              </div>
                              <div className="frame-5">
                                <div
                                  className={
                                    ResultPageData?.barModeSelected ===
                                    startValue
                                      ? `tue-30-jan-24 ${Theme}_regular color_white font13`
                                      : `tue-30-jan-24 ${Theme}_regular color_gravel font13`
                                  }
                                >
                                  {formatDateResultBar(
                                    SelectedDatas[0].Segments[0].Origin.DepTime
                                  )}
                                </div>
                                <div className="frame-242">
                                  <div
                                    onClick={() => {
                                      setOpenPopup(0, true);
                                    }}
                                    className={
                                      ResultPageData?.barModeSelected ===
                                      startValue
                                        ? `flight-details-19 ${Theme}_semibold color_${Theme == "proxima" ? "robins-egg-blue" : "medium-pale-border"} font15`
                                        : `flight-details-19 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "medium-pale-border"} font15`
                                    }
                                  >
                                    Flight Details
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-18">
                        <div className="frame-2-3 frame-2-4">
                          <div
                            className={
                              ResultPageData?.barModeSelected === startValue
                                ? `x0500-am-1200-pm ${Theme}_regular color_white font13`
                                : `x0500-am-1200-pm ${Theme}_regular color_gravel font13`
                            }
                          >
                            {formatTimeRangeAMPM(
                              SelectedDatas[0].Segments[0].Origin.DepTime,
                              SelectedDatas[0].Segments[
                                SelectedDatas[0].Segments.length - 1
                              ].Destination.ArrTime
                            )}
                          </div>
                        </div>
                        <div className="frame-15">
                          <img
                            className="frame-169-23"
                            src={images["white-rupee-icon.svg"]}
                            alt="Frame 169"
                          />
                          <div
                            className={
                              ResultPageData?.barModeSelected === startValue
                                ? `text-214 text-6 ${Theme}_semibold color_white font16`
                                : `text-214 text-6 ${Theme}_semibold color_gravel font16`
                            }
                          >
                            {Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                              +SelectedDatas[0].FareFamilies.FFList[0]
                                .Travellers.Adults[0].Tax)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
             className={`frame-237 ${Theme}`}
              onClick={() => dispatch(setbarModeSelected(startValue + 1))}
            >
              {SelectedDatas[1].Segments && (
                <div
                  className={
                    ResultPageData?.barModeSelected === startValue + 1
                 ? `frame-175-4-4 ${Theme}`
                      : `frame-175-4 ${Theme}`
                  }
                >
                  <div className="frame-6">
                    <div className="frame-232">
                      <div className="frame-232-1">
                        <div className="frame-232-2">
                          <div className="frame-232-3">
                            <AirlinesLogContainer
                              allAirlines={SelectedDatas[1].AirlinesDetails}
                            />
                            <div className="frame-203-3">
                              <div
                                className={
                                  ResultPageData?.barModeSelected ===
                                  startValue + 1
                                    ? `new-delhi-bangalore ${Theme}_semibold color_white font16`
                                    : `new-delhi-bangalore ${Theme}_semibold color_eerie-black font16`
                                }
                              >
                                {
                                  SelectedDatas?.[1]?.Segments[0].Origin.Airport
                                    .CityName
                                }{" "}
                                -{" "}
                                {
                                  SelectedDatas?.[1]?.Segments[
                                    SelectedDatas?.[1]?.Segments.length - 1
                                  ].Destination.Airport.CityName
                                }
                              </div>
                              <div className="frame-5">
                                <div
                                  className={
                                    ResultPageData?.barModeSelected ===
                                    startValue + 1
                                      ? `tue-30-jan-24 ${Theme}_regular color_white font13`
                                      : `tue-30-jan-24 ${Theme}_regular color_gravel font13`
                                  }
                                >
                                  {formatDateResultBar(
                                    SelectedDatas?.[1]?.Segments[0].Origin
                                      .DepTime
                                  )}
                                </div>
                                <div className="frame-242">
                                  <div
                                    onClick={() => {
                                      setOpenPopup(1, true);
                                    }}
                                    className={
                                      ResultPageData?.barModeSelected ===
                                      startValue + 1
                                        ? `flight-details-19 ${Theme}_semibold color_${Theme == "proxima" ? "robins-egg-blue" : "medium-pale-border"} font15`
                                        : `flight-details-19 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "medium-pale-border"} font15`
                                    }
                                  >
                                    Flight Details
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-18">
                        <div className="frame-2-3 frame-2-4">
                          <div
                            className={
                              ResultPageData?.barModeSelected === startValue + 1
                                ? `x0500-am-1200-pm ${Theme}_regular color_white font13`
                                : `x0500-am-1200-pm ${Theme}_regular color_gravel font13`
                            }
                          >
                            {formatTimeRangeAMPM(
                              SelectedDatas[1].Segments[0].Origin.DepTime,
                              SelectedDatas[1].Segments[
                                SelectedDatas[1].Segments.length - 1
                              ].Destination.ArrTime
                            )}
                          </div>
                        </div>
                        <div className="frame-15">
                          <img
                            className="frame-169-23"
                            src={images["frame-169-15.svg"]}
                            alt="Frame 169"
                          />
                          <div
                            className={
                              ResultPageData?.barModeSelected === startValue + 1
                                ? `text-214 text-6 ${Theme}_semibold color_white font16`
                                : `text-214 text-6 ${Theme}_semibold color_gravel font16`
                            }
                          >
                            {Math.ceil(+SelectedDatas[1].FareFamilies.FFList[0].Travellers
                              .Adults[0].Fare +
                              +SelectedDatas[1].FareFamilies.FFList[0]
                                .Travellers.Adults[0].Tax)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="frame-20-4" style={{ width: "450px" }}>
            {SelectedDatas[0].Segments && SelectedDatas[1].Segments && (
              <>
                <div className="frame-233-6">
                  <div className={`total-cost ${Theme}_regular color_storm-dust font13`}>
                    Total cost
                  </div>
                  <div className={`fare-summary fare ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font15`}>
                    Fare Summary
                    <div className="fare-summary-tooltip">
                      <FareSummary
                        base={
                          Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                            .Adults[0].Fare +
                          +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                            .Adults[0].Fare)
                        }
                        tax={
                          Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                            .Adults[0].Tax +
                          +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                            .Adults[0].Tax)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="frame">
                  <div className="frame-232-8">
                    <div className="frame-2-2 frame-2-4">
                      <img
                        className="frame-169-23"
                        src={images["frame-169-19.svg"]}
                        alt="Frame 169"
                      />
                      <div className={`text-218 text-6 ${Theme}_semibold color_magic-potion font23`}>
                        {Math.ceil(+SelectedDatas[0].FareFamilies.FFList[0].Travellers
                          .Adults[0].Fare +
                          +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                            .Adults[0].Fare +
                          +SelectedDatas[0].FareFamilies.FFList[0].Travellers
                            .Adults[0].Tax +
                          +SelectedDatas[1].FareFamilies.FFList[0].Travellers
                            .Adults[0].Tax)}
                      </div>
                    </div>
                    <div className={`per-adult-15 per-adult-16 ${Theme}_regular color_gravel font13`}>
                      per adult
                    </div>
                  </div>

                  <div
                    className={
                      compareFlightTimes(
                        SelectedDatas[0].Segments[
                          SelectedDatas[0].Segments.length - 1
                        ].Destination.ArrTime,
                        SelectedDatas[1].Segments[0].Origin.DepTime
                      )
                        ? `frame-103-77 frame-103-8 disabled ${Theme}`
                        : `frame-103-7 frame-103-8 ${Theme}`
                    }
                    onMouseOver={() => setshowError(true)}
                    onMouseLeave={() => setshowError(false)}
                    onClick={() => {
                      setOpenPopup(1, true);
                    }}
                  >
                    <div className={`continue valign-text-middle ${Theme}_semibold font14`}>
                      {compareFlightTimes(
                        SelectedDatas[0].Segments[
                          SelectedDatas[0].Segments.length - 1
                        ].Destination.ArrTime,
                        SelectedDatas[1].Segments[0].Origin.DepTime
                      ) && "! "}{" "}
                      CONTINUE
                    </div>
                  </div>
                </div>

                {showError === true &&
                  compareFlightTimes(
                    SelectedDatas[0].Segments[
                      SelectedDatas[0].Segments.length - 1
                    ].Destination.ArrTime,
                    SelectedDatas[1].Segments[0].Origin.DepTime
                  ) && (
                    <div
                      style={{
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "-10%",
                        padding: "4px 8px",
                      }}
                      className="flight-error-message-special"
                    >
                      <img
                        className="alert-error-msg"
                        src={images["alert.svg"]}
                        alt="alert"
                      />
                      {/* <span className="flightsSprite fltErrorIcon appendRight10"></span> */}
                      <span
                        data-cy="sameCityError"
                        className={`redText fltErrorMsgText ${Theme}_regular color_magic-potion font14`}
                      >
                        {FlightsResultPageErrorMainDescription.IVCD}
                      </span>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
